export const subdomainPattern: RegExp = new RegExp(/^[a-z]([a-z]|[0-9]|-)*$/);

export enum $tenant {
  _id = '_id',
  academyConfig = 'academyConfig',
  cloudsmithToken = 'cloudsmithToken',
  createdAt = 'createdAt',
  description = 'description',
  digitalPropertyAutomatedFlowLimit = 'digitalPropertyAutomatedFlowLimit',
  digitalPropertyLimit = 'digitalPropertyLimit',
  domain = 'domain',
  maxPdfTests = 'maxPdfTests',
  monitoringPagesPerDigitalPropertyLimit = 'monitoringPagesPerDigitalPropertyLimit',
  name = 'name',
  pdfPagesTestLimit = 'pdfPagesTestLimit',
  salesforceAccount = 'salesforceAccount',
  salesforceAccountId = 'salesforceAccountId',
  salesforceAccountName = 'salesforceAccountName',
  scimEnabled = 'scimEnabled',
  oldMonitoringPagesPerDigitalPropertyLimit = 'oldMonitoringPagesPerDigitalPropertyLimit',
  userWayWebsiteLimit = 'userWayWebsiteLimit',
  ssoConfig = 'ssoConfig',
  status = 'status',
  usersCount = 'usersCount',
}

export enum $tenantMetadata {
  packageId = 'packageId',
  packageData = 'packageData',
  packageAddonIds = 'packageAddonIds',
}

export enum $tenants {
  domains = 'domains',
  tenants = 'tenants',
  packages = 'packages',
  _total = '_total',
}

export enum TenantStatus {
  active = 'active',
  archived = 'archived',
}

export enum $tenantUser {
  userId = 'userId',
  tenantId = 'tenantId',
  integrations = 'integrations',
  user = 'user',
}

export enum $tenantUserIntegrations {
  academy = 'academy',
}

export enum $tenantSsoHistory {
  _id = '_id',
  tenantId = 'tenantId',
  userId = 'userId',
  action = 'action',
  performedAt = 'performedAt',
  reason = 'reason',
}

export enum TenantSsoAction {
  SET = 'SET',
  REMOVE = 'REMOVE',
}

export const CHAR_LIMITS: Readonly<Record<string, number>> = {
  [$tenant.name]: 100,
  [$tenant.description]: 100,
  [$tenant.domain]: 40,
};
export const SSO_REMOVAL_REASON_CHAR_LIMIT: number = 200;
export const MONITORING_PAGES_PER_DP_LIMIT_MIN: number = 1;
