import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, combineLatest, EMPTY, BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Icons, DsButtonVariants } from '@levelaccess/design-system';

import { Api, ApiQueryOption } from '../../../../../../shared/constants/api';
import { $workspace } from '../../../../../../shared/constants/workspace';
import { IWorkspaceClient } from '../../../../../../shared/interfaces/workspace.interface';
import { WorkspaceService } from '../../../../services/workspace.service';
import { UserService } from '../../../../services/user.service';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../../../shared/utils/linked-property.utility';
import { LoadErrorHandler } from '../../../../services/load-error-handler';
import { NavService } from '../../../../services/navigation/nav.service';
import { NavigationItem, INavMenuItem } from '../../../../services/navigation/nav-structure';
import { BusMessageChannels, BusMessageService } from '../../../../services/bus-message.service';
import { SharedSortUtility } from '../../../../../../shared/utils/sort.utility';
import { $sortingOrder } from '../../../../../../shared/constants/sort';

@Component({
  selector: 'top-navbar-workspaces-dropdown',
  templateUrl: './top-navbar-workspaces-dropdown.component.html',
})
export class TopNavbarWorkspacesDropdownComponent implements OnInit, OnDestroy {
  public workspaces$: BehaviorSubject<IWorkspaceClient[]>;
  public workspacesMenuItem$: Observable<INavMenuItem>;
  public selectedWorkspaceId$: Observable<string | undefined>;

  public readonly Api: typeof Api = Api;
  public readonly Icons: typeof Icons = Icons;
  public readonly $workspace: typeof $workspace = $workspace;
  public readonly ApiQueryOption: typeof ApiQueryOption = ApiQueryOption;
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;

  private subscriptions: Subscription;

  constructor(
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private busMessageService: BusMessageService,
    private loadErrorHandler: LoadErrorHandler,
    private navService: NavService,
  ) {
    this.subscriptions = new Subscription();
    this.workspaces$ = new BehaviorSubject<IWorkspaceClient[]>([]);
  }

  public ngOnInit(): void {
    this.getActiveWorkspaces();
    this.subscriptions.add(
      this.busMessageService.from(BusMessageChannels.workspaceUpdated).subscribe({
        next: this.getActiveWorkspaces.bind(this),
      }),
    );

    const urlSelectedWorkspace$: Observable<ILinkedPropertyData> = this.activatedRoute.queryParams.pipe(
      map(LinkedPropertyUtility.fromLinkedPropertyQueryParam),
    );

    this.workspacesMenuItem$ = this.navService
      .getTopLevelMenuItems$([NavigationItem.workspace])
      .pipe(map((menuItems: INavMenuItem[]): INavMenuItem => menuItems?.[0]));

    this.selectedWorkspaceId$ = combineLatest([urlSelectedWorkspace$, this.navService.activeTopLevelMenuItem$()]).pipe(
      map(([linkedPropertyData, activeMenuItem]: [ILinkedPropertyData, INavMenuItem]): string | undefined => {
        if (activeMenuItem?.id !== NavigationItem.workspace) {
          return undefined;
        }

        return linkedPropertyData?.[Api.workspaceId];
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getLinkedPropertyDataQueryParam(workspace: IWorkspaceClient): Params {
    return LinkedPropertyUtility.getLinkedPropertyQueryParam(
      workspace[$workspace.digitalProperties]?.[0]?._id,
      workspace[$workspace._id],
    );
  }

  private getActiveWorkspaces(): void {
    const onGetWorkspacesError = (error: HttpErrorResponse): Observable<IWorkspaceClient[]> => {
      this.loadErrorHandler.handleError('error_while_getting_workspaces', error, TopNavbarWorkspacesDropdownComponent.name);
      return EMPTY;
    };
    const onGetActiveWorkspacesSuccess = (activeWorkspaces: IWorkspaceClient[]): void => {
      activeWorkspaces.sort(
        SharedSortUtility.functionSortObjectsByStringProperty($sortingOrder.asc, $workspace.name, {
          numeric: true,
          sensitivity: 'base',
        }),
      );

      this.workspaces$.next(activeWorkspaces);
    };

    const onAuthenticationChange = (status: boolean): void => {
      if (status) {
        const getActiveWorkspacesSubscription: Subscription = this.workspaceService.getActiveWorkspacesV2().subscribe({
          next: onGetActiveWorkspacesSuccess.bind(this),
          error: onGetWorkspacesError.bind(this),
        });
        this.subscriptions.add(getActiveWorkspacesSubscription);
      }
    };

    const getAuthUserSubscription: Subscription = this.userService.isAuthenticated$.subscribe(onAuthenticationChange.bind(this));
    this.subscriptions.add(getAuthUserSubscription);
  }
}
