import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestBuilder } from '../../services/helpers/rest.builder';
import { ISendChatMessageResponse } from '../../../../shared/interfaces/ai-chat.interface';

@Injectable({
  providedIn: 'root',
})
export class AiChatService {
  private readonly restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  private chat(id?: string): RestBuilder {
    return this.restBuilder.create().ai().chat(id);
  }

  public sendMessage(message: string): Observable<ISendChatMessageResponse> {
    return this.httpClient.post<ISendChatMessageResponse>(this.chat().getApiUrl(), { message });
  }

  public setRating(id: string, rating: number): Observable<ISendChatMessageResponse> {
    const url: string = this.chat(id).rating().getApiUrl();

    return this.httpClient.put<ISendChatMessageResponse>(url, { rating });
  }

  public unsetRating(id: string): Observable<ISendChatMessageResponse> {
    const url: string = this.chat(id).rating().getApiUrl();

    return this.httpClient.delete<ISendChatMessageResponse>(url);
  }
}
