import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, interval, Subject, Observable } from 'rxjs';
import { buffer } from 'rxjs/operators';
import { delay, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

import { A11yService } from '../../services/a11y.service';
import { A11Y_MESSAGE_TIMEOUT_IN_MILLIS, DEFAULT_A11Y_ALERT_MESSAGE_BOUNCE_TIME } from '../../shared/constants';

@Component({
  selector: 'app-a11y',
  templateUrl: './a11y.component.html',
})
export class A11yComponent implements OnInit, OnDestroy {
  private message: Subject<string>;
  private subscriptions: Subscription;
  private messageBufferSubscription: Subscription;
  private isMessageBufferSubscribed: boolean;
  private messageTimeoutInMillis: number;
  private alertAddMessageDelayInMillis: number;

  public messages: string[];
  public renderMessages: string[];

  private _alertMessagesSet: Set<string>;
  public get alertMessages(): string[] {
    return Array.from(this._alertMessagesSet.values());
  }

  constructor(private a11yService: A11yService) {
    this.message = new Subject<string>();

    this.subscriptions = new Subscription();

    this.messageBufferSubscription = new Subscription();
    this.isMessageBufferSubscribed = false;

    this.messageTimeoutInMillis = A11Y_MESSAGE_TIMEOUT_IN_MILLIS;
    this.alertAddMessageDelayInMillis = DEFAULT_A11Y_ALERT_MESSAGE_BOUNCE_TIME;

    this.messages = [];
    this.renderMessages = [];
    this._alertMessagesSet = new Set<string>();
  }

  private subscribeBufferedMessage(): void {
    if (this.isMessageBufferSubscribed === false) {
      const buffered: Observable<string[]> = this.message.pipe(buffer(interval(this.messageTimeoutInMillis)));
      this.messageBufferSubscription = buffered.subscribe((messages: string[]) => {
        if (messages.length === 0) {
          this.unSubscribeBufferMessage();
        }
        this.renderMessages = messages;
      });
      this.isMessageBufferSubscribed = true;
    }
  }

  private unSubscribeBufferMessage(): void {
    this.messageBufferSubscription.unsubscribe();
    this.isMessageBufferSubscribed = false;
  }

  private remove(message: string): void {
    this.messages = this.messages.filter((value: string): boolean => value !== message);
  }

  private setMessage(message: string): void {
    this.subscribeBufferedMessage();
    this.messages.push(message);
    this.message.next(message);
  }

  private removeAlert(message: string): void {
    this._alertMessagesSet.delete(message);
  }

  private setAlertMessage(message: string): void {
    this._alertMessagesSet.add(message);
  }

  public ngOnInit(): void {
    const messageSubscription: Subscription = this.a11yService.message$
      .pipe(
        distinctUntilChanged(),
        filter((value: string): boolean => this.messages.includes(value) === false),
        tap((value: string) => this.setMessage(value)),
        delay(this.messageTimeoutInMillis),
        tap((value: string) => this.remove(value)),
      )
      .subscribe();

    const alertMessageSubscription: Subscription = this.a11yService.alertMessage$
      .pipe(
        filter(
          (value: string): boolean => SharedCommonUtility.notNullishOrEmpty(value) && this._alertMessagesSet.has(value) === false,
        ),
        delay(this.alertAddMessageDelayInMillis),
        tap((value: string) => this.setAlertMessage(value)),
        delay(this.messageTimeoutInMillis),
        tap((value: string) => this.removeAlert(value)),
      )
      .subscribe();

    this.subscriptions.add(messageSubscription);
    this.subscriptions.add(alertMessageSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unSubscribeBufferMessage();
  }
}
