import { HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { ApiVersion } from '../../shared/constants';
import { Api, ApiQueryOption } from '../../../../shared/constants/api';
import { AccessibilityAuditToolNames } from '../../../../shared/constants/audit-tool';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

export class RestBuilder {
  private url: string = '';
  private version: string = ApiVersion.current;

  public create(): this {
    this.url = '';
    return this;
  }

  public paginationParams(skip: number, limit: number): HttpParams {
    const query: { [key: string]: string } = {};

    if (typeof limit === 'number') {
      query[ApiQueryOption.limit] = String(limit);
    }

    if (typeof skip === 'number') {
      query[ApiQueryOption.skip] = String(skip);
    }

    return new HttpParams({ fromObject: query });
  }

  public query(params: HttpParams): this {
    this.url += `${this.url.includes('?') ? '&' : '?'}${params.toString()}`;
    return this;
  }

  public _noPrefix(id: string): this {
    this.url += `/${id}`;
    return this;
  }

  public available(id?: string): this {
    this.url += `/${Api.available}`;

    if (id) {
      this.url += `/${id}`;
    }

    return this;
  }

  public login(): this {
    this.url += `/${Api.login}`;
    return this;
  }

  public logout(): this {
    this.url += `/${Api.logout}`;
    return this;
  }

  public status(): this {
    this.url += `/${Api.status}`;
    return this;
  }

  public possibleTargetStatuses(): this {
    this.url += `/${Api.possible_target_statuses}`;
    return this;
  }

  public auth(): this {
    this.url += `/${Api.auth}`;
    return this;
  }

  public refreshAccessToken(): this {
    this.url += `/${Api.refreshAccessToken}`;
    return this;
  }

  public projects(id?: string): this {
    this.url += `/${Api.projects}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public workspaces(id?: string): this {
    this.url += `/${Api.workspaces}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public migrate(): this {
    this.url += `/${Api.migrate}`;
    return this;
  }

  public resync(): this {
    this.url += `/${Api.resync}`;
    return this;
  }

  public key(key: string): this {
    this.url += `/${Api.keys}/${key}`;
    return this;
  }

  public tasks(id?: string): this {
    this.url += `/${Api.tasks}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public myTasks(): this {
    this.url += `/${Api.myTasks}`;
    return this;
  }

  public config(): this {
    this.url += `/${Api.config}`;
    return this;
  }

  public issueTracking(): this {
    this.url += `/${Api.issue_tracking}`;
    return this;
  }

  public issueTrackingConnectionConfig(): this {
    this.url += `/${Api.issue_integration_connection_config}`;
    return this;
  }

  public issueTrackingConnection(instanceId?: string): this {
    this.url += `/${Api.issue_integration_connection}`;
    if (typeof instanceId === 'string') {
      this.url += `/${instanceId}`;
    }
    return this;
  }

  public issueTrackingProjects(key?: string): this {
    this.url += `/${Api.issue_integration_projects}`;
    if (typeof key === 'string') {
      this.url += `/${key}`;
    }
    return this;
  }

  public consumerKey(): this {
    this.url += `/${Api.consumer_key}`;
    return this;
  }

  public issueTypes(instanceId?: string): this {
    this.url += `/${Api.issueTypes}`;
    if (typeof instanceId === 'string') {
      this.url += `/${instanceId}`;
    }
    return this;
  }

  public configurableFields(): this {
    this.url += `/${Api.configurableFields}`;
    return this;
  }

  public requiredFields(): this {
    this.url += `/${Api.requiredFields}`;
    return this;
  }

  public issueTrackingStatusMapping(): this {
    this.url += `/${Api.issue_integration_status_mapping}`;
    return this;
  }

  public issueTrackingIssueStatuses(): this {
    this.url += `/${Api.issueIntegrationStatuses}`;
    return this;
  }

  public jira(): this {
    this.url += `/${Api.issueIntegration}`;
    return this;
  }

  public regenerateAuthUrl(): this {
    this.url += `/${Api.regenerate_auth_url}`;
    return this;
  }

  public verifyJiraToken(): this {
    this.url += `/${Api.verifyToken}`;
    return this;
  }

  public uiTools(): this {
    this.url += `/${Api.ui_tools}`;
    return this;
  }

  public integrations(): this {
    this.url += `/${Api.integrations}`;
    return this;
  }

  public generateApiKey(): this {
    this.url += `/${Api.generate_api_key}`;
    return this;
  }

  public deleteApiKey(name: string): this {
    this.url += `/${name}`;
    return this;
  }

  public generateBrowserExtensionKey(): this {
    this.url += `/${Api.generate_browser_extension_key}`;
    return this;
  }

  public getPreviousTokensInfo(): this {
    this.url += `/${Api.previous_tokens_info}`;
    return this;
  }

  public preferences(scope: string, workspaceId?: string): this {
    this.url += `/${Api.preferences}`;
    this.url += `/${scope}`;

    if (typeof workspaceId === 'string' && workspaceId.length) {
      this.url += `/${workspaceId}`;
    }

    return this;
  }

  public roles(id?: string): this {
    this.url += `/${Api.roles}`;

    if (typeof id === 'string') {
      this.url += '/' + id + `/${Api.roles}`;
    }

    return this;
  }

  public role(): this {
    this.url += `/${Api.role}`;
    return this;
  }

  public admin(): this {
    this.url += `/${Api.admin}`;
    return this;
  }

  public testGuides(): this {
    this.url += `/${Api.testGuides}`;
    return this;
  }

  public packages(packageId?: string): this {
    this.url += `/${Api.packages}`;

    if (SharedCommonUtility.notNullish(packageId)) {
      this.url += `/${packageId}`;
    }

    return this;
  }

  public packageAddons(): this {
    this.url += `/${Api.packageAddons}`;
    return this;
  }

  public user(id?: string): this {
    this.url += `/${Api.user}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public workspaceUser(id?: string): this {
    this.url += `/${Api.workspaceUser}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public dashboard(): this {
    this.url += `/${Api.dashboard}`;
    return this;
  }

  public latestScans(): this {
    this.url += `/${Api.latest_scans}`;
    return this;
  }

  public latestScan(): this {
    this.url += `/${Api.latest_scan}`;
    return this;
  }

  public authenticated(): this {
    this.url += `/${Api.authenticated}`;
    return this;
  }

  public deactivate(): this {
    this.url += `/${Api.deactivate}`;
    return this;
  }

  public activate(): this {
    this.url += `/${Api.activate}`;
    return this;
  }

  public verifyEmail(): this {
    this.url += `/${Api.verifyEmail}`;
    return this;
  }

  public verifyResetPasswordToken(): this {
    this.url += `/${Api.verifyResetPasswordToken}`;
    return this;
  }

  public translations(locale?: string): this {
    if (typeof locale === 'string') {
      this.url += `/${Api.translations}/${locale}`;
    } else {
      this.url += `/${Api.translations}`;
    }
    return this;
  }

  public translationsData(locale: string): this {
    this.url += `/${Api.translations}/${Api.data}/${locale}`;
    return this;
  }

  public locales(locale?: string): this {
    this.url += `/${Api.locales}${locale ? '/' + locale : ''}`;
    return this;
  }

  public supportedLocales(): this {
    this.url += `/${Api.supported_locales}`;
    return this;
  }

  public resetPassword(): this {
    this.url += `/${Api.resetPassword}`;
    return this;
  }

  public resendActivationLink(): this {
    this.url += `/${Api.resendActivationLink}`;
    return this;
  }

  public resendVisitorActivationLink(): this {
    this.url += `/${Api.resendVisitorActivationLink}`;
    return this;
  }

  public createNewPassword(): this {
    this.url += `/${Api.createNewPassword}`;
    return this;
  }

  public allIds(): this {
    this.url += `/${Api.all_ids}`;
    return this;
  }

  public all(): this {
    this.url += `/${Api.all}`;
    return this;
  }

  public list(): this {
    this.url += `/${Api.list}`;
    return this;
  }

  public countries(): this {
    this.url += `/${Api.countries}`;
    return this;
  }

  public scan(id?: string): this {
    this.url += `/${Api.scan}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public quickScan(): this {
    this.url += `/${Api.quick_scan}`;

    return this;
  }

  public exists(): this {
    this.url += `/${Api.exists}`;

    return this;
  }

  public documents(id?: string): this {
    this.url += `/${Api.documents}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public scannableDocuments(id?: string): this {
    this.url += `/${Api.scannableDocuments}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public scannableDocumentsRule(id?: string): this {
    this.url += `/${Api.scannableDocumentRules}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }
    return this;
  }

  public startDocumentScan(): this {
    this.url += `/${Api.startDocumentScan}`;
    return this;
  }

  public supplementalScan(): this {
    this.url += `/${Api.supplementalScan}`;
    return this;
  }

  public requestRemediation(): this {
    this.url += `/${Api.requestRemediation}`;
    return this;
  }

  public setRemediation(): this {
    this.url += `/${Api.setRemediation}`;
    return this;
  }

  public downloadReport(): this {
    this.url += `/${Api.downloadReport}`;
    return this;
  }

  public removeRemediation(id?: string): this {
    this.url += `/${Api.removeRemediation}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }
    return this;
  }

  public revokeRemediationRequest(): this {
    this.url += `/${Api.revokeRemediationRequest}`;
    return this;
  }

  public downloadTestReport(): this {
    this.url += `/${Api.downloadTestReport}`;
    return this;
  }

  public upload(): this {
    this.url += `/${Api.upload}`;
    return this;
  }

  public file(id?: string): this {
    this.url += `/${Api.file}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public options(id?: string): this {
    this.url += `/${Api.options}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public default(): this {
    this.url += `/${Api.default}`;

    return this;
  }

  public rerun(): this {
    this.url += `/${Api.rerun}`;

    return this;
  }

  public active(): this {
    this.url += `/${Api.active}`;

    return this;
  }

  public reports(): this {
    this.url += `/${Api.reports}`;
    return this;
  }

  public details(): this {
    this.url += `/${Api.details}`;
    return this;
  }

  public analytics(): this {
    this.url += `/${Api.analytics}`;
    return this;
  }

  public rule(id?: string): this {
    this.url += `/${Api.rule}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public rules(): this {
    this.url += `/${Api.rules}`;
    return this;
  }

  public cancel(): this {
    this.url += `/${Api.cancel}`;
    return this;
  }

  public resume(): this {
    this.url += `/${Api.resume}`;
    return this;
  }

  public serverApiPerformance(): this {
    this.url += `/${Api.server_api_performance}`;
    return this;
  }

  public download(resourceId?: string): this {
    this.url += `/${Api.download}`;

    if (SharedCommonUtility.notNullish(resourceId)) {
      this.url += `/${resourceId}`;
    }
    return this;
  }

  public resources(resourceId: string, downloadToken: string): this {
    this.url += `/${Api.resources}/${downloadToken}/${resourceId}`;
    return this;
  }

  public secureResources(resourceId: string): this {
    this.url += `/${Api.secure_resources}/${resourceId}`;
    return this;
  }

  public sendEmail(): this {
    this.url += `/${Api.send_email}`;
    return this;
  }

  public avatar(userId: string): this {
    this.url += `/${Api.avatar}/${encodeURIComponent(userId)}`;
    return this;
  }

  public avatarId(id?: string): this {
    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public report(id?: string): this {
    this.url += `/${Api.report}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public logs(): this {
    this.url += `/${Api.logs}`;
    return this;
  }

  public activityLogs(): this {
    this.url += `/${Api.activityLogs}`;
    return this;
  }

  public users(userId?: string): this {
    this.url += `/${Api.users}`;

    if (SharedCommonUtility.notNullish(userId)) {
      this.url += `/${userId}`;
    }

    return this;
  }

  public workspaceUsers(userId?: string): this {
    this.url += `/${Api.workspace_users}`;

    if (SharedCommonUtility.notNullish(userId)) {
      this.url += `/${userId}`;
    }

    return this;
  }

  public getApiUrl(): string {
    return environment.restApiUrl + '/' + this.version + this.url;
  }

  public support(): this {
    this.url += `/${Api.support}`;
    return this;
  }

  public sendSupportEmail(): this {
    this.url += `/${Api.send_email}`;
    return this;
  }

  public markAuditErrorAsFalsePositive(): this {
    this.url += `/${Api.dismiss}`;
    return this;
  }

  public toggleRule(): this {
    this.url += `/${Api.toggle_rule}`;
    return this;
  }

  public digitalProperty(id?: string): this {
    this.url += `/${Api.digital_property}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public validate(): this {
    this.url += `/${Api.validate}`;
    return this;
  }

  public createInPath(): this {
    this.url += `/${Api.create}`;
    return this;
  }

  public manualResults(id?: string): this {
    this.url += `/${Api.manual_results}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }
    return this;
  }

  public attachments(id?: string): this {
    this.url += `/${Api.attachments}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }
    return this;
  }

  public manualAuditTopWcag(): this {
    this.url += `/${Api.manual_audit_top_wcag}`;

    return this;
  }

  public manualAuditIssuesBySeverity(): this {
    this.url += `/${Api.manual_audit_issues_by_severity}`;

    return this;
  }

  public manualEvaluations(id?: string): this {
    this.url += `/${Api.manual_evaluations}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }
    return this;
  }

  public categories(): this {
    this.url += `/${Api.categories}`;
    return this;
  }

  public digitalProperties(id?: string): this {
    this.url += `/${Api.digital_properties}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public digitalPropertyType(id?: string): this {
    this.url += `/${Api.digital_property_type}`;

    if (typeof id === 'string') {
      this.url += '/' + id;
    }

    return this;
  }

  public digitalPropertyMetrics(): this {
    this.url += `/${Api.digitalPropertyMetrics}`;
    return this;
  }

  public tracking(): this {
    this.url += `/${Api.tracking}`;
    return this;
  }

  public console(): this {
    this.url += `/${Api.console}`;
    return this;
  }

  public errors(): this {
    this.url += `/${Api.errors}`;
    return this;
  }

  public auditRules(digitalPropertyId?: string): this {
    this.url += `/${Api.audit_rules}`;

    if (typeof digitalPropertyId === 'string') {
      this.url += '/' + digitalPropertyId;
    }

    return this;
  }

  public customAuditRules(tenantId?: string): this {
    this.url += `/${Api.custom_audit_rules}`;

    if (typeof tenantId === 'string') {
      this.url += `/${tenantId}`;
    }

    return this;
  }

  public libraries(): this {
    this.url += `/${Api.libraries}`;
    return this;
  }

  public ruleIds(): this {
    this.url += `/${Api.rule_ids}`;
    return this;
  }

  public jobIndustries(): this {
    this.url += `/${Api.jobIndustries}`;
    return this;
  }

  public jobTitles(): this {
    this.url += `/${Api.jobTitles}`;
    return this;
  }

  public export(): this {
    this.url += `/${Api.export}`;
    return this;
  }

  public id(id: string): this {
    this.url += `/${id}`;
    return this;
  }

  public scansCount(): this {
    this.url += `/${Api.scans_count}`;
    return this;
  }

  public nextScheduledScan(): this {
    this.url += `/${Api.next_scheduled_scan}`;
    return this;
  }

  public training(): this {
    this.url += `/${Api.training}`;
    return this;
  }

  public videos(id?: string): this {
    this.url += `/${Api.videos}`;

    if (typeof id === 'string') {
      this.url += `/${id}`;
    }

    return this;
  }

  public pathParam(path: string): this {
    this.url += `/${path}`;

    return this;
  }

  public move(): this {
    this.url += `/${Api.move}`;

    return this;
  }

  public manualAudit(id?: string): this {
    this.url += `/${Api.manual_audit}`;

    if (typeof id === 'string') {
      this.url += `/${id}`;
    }

    return this;
  }

  public manualRequest(requestId?: string): this {
    this.url += `/${Api.manual_requests}`;

    if (typeof requestId === 'string') {
      this.url += `/${requestId}`;
    }

    return this;
  }

  public manualUploadFile(): this {
    this.url += `/${Api.manual_upload_file}`;

    return this;
  }

  public manualAuditIssue(id?: string): this {
    this.url += `/${Api.manualAuditIssue}`;

    if (typeof id === 'string') {
      this.url += `${id}`;
    }

    return this;
  }

  public comments(id?: string): this {
    this.url += `/${Api.comments}`;

    if (typeof id === 'string') {
      this.url += `/${id}`;
    }
    return this;
  }

  public monitoring(): this {
    this.url += `/${Api.monitoring}`;

    return this;
  }

  public quickMonitoring(): this {
    this.url += `/${Api.quick_monitoring}`;

    return this;
  }

  public scanTags(id?: string): this {
    this.url += `/${Api.scan_tags}`;

    if (typeof id === 'string') {
      this.url += `/${id}`;
    }

    return this;
  }

  public archive(): this {
    this.url += `/${Api.archive}`;

    return this;
  }

  public restore(): this {
    this.url += `/${Api.restore}`;

    return this;
  }

  public resendInvite(): this {
    this.url += `/${Api.resend_invite}`;
    return this;
  }

  public scanSummary(): this {
    this.url += `/${Api.scanSummary}`;
    return this;
  }

  public previous(): this {
    this.url += `/${Api.previous}`;
    return this;
  }

  public tool(): this {
    this.url += `/${Api.tool}`;
    return this;
  }

  public toolName(toolName: AccessibilityAuditToolNames): this {
    this.url += `/${toolName}`;
    return this;
  }

  public ruleHistory(): this {
    this.url += `/${Api.ruleHistory}`;
    return this;
  }

  public ruleHistorySearch(): this {
    this.url += `/${Api.ruleHistorySearch}`;
    return this;
  }

  public scanPointSummary(): this {
    this.url += `/${Api.scan_point_summary}`;
    return this;
  }

  public pageSummary(): this {
    this.url += `/${Api.page_summary}`;
    return this;
  }

  public hasHistory(): this {
    this.url += `/${Api.hasHistoryUpdates}`;
    return this;
  }

  public total(): this {
    this.url += `/${Api.total}`;
    return this;
  }

  public issues(id?: string): this {
    this.url += `/${Api.issues}`;

    if (typeof id === 'string') {
      this.url += `/${id}`;
    }

    return this;
  }

  public dismiss(): this {
    this.url += `/${Api.dismiss}`;
    return this;
  }

  public shortLink(): this {
    this.url += `/${Api.short_link}`;

    return this;
  }

  public shortLinks(id?: string): this {
    this.url += `/${Api.shortLinks}`;

    if (typeof id === 'string') {
      this.url += `/${id}`;
    }
    return this;
  }

  public isDuplicated(): this {
    this.url += `/${Api.isDuplicated}`;
    return this;
  }

  public existingKeyValues(): this {
    this.url += `/${Api.existingKeyValues}`;
    return this;
  }

  public flaws(): this {
    this.url += `/${Api.flaws}`;
    return this;
  }

  public markAsViewed(): this {
    this.url += `/${Api.markAsViewed}`;
    return this;
  }

  public userChangeHistory(): this {
    this.url += `/${Api.userChangeHistory}`;
    return this;
  }

  public bulk(): this {
    this.url += `/${Api.bulk}`;
    return this;
  }

  public pages(id?: string): this {
    this.url += `/${Api.pages}`;

    if (typeof id === 'string') {
      this.url += `/${id}`;
    }

    return this;
  }

  public monitoringPages(id?: string): this {
    this.url += `/${Api.monitoring_pages}`;

    if (typeof id === 'string') {
      this.url += `/${id}`;
    }

    return this;
  }

  public configureFields(): this {
    this.url += `/${Api.configureFields}`;
    return this;
  }

  public archivable(): this {
    this.url += `/${Api.archivable}`;
    return this;
  }

  public deletable(): this {
    this.url += `/${Api.deletable}`;
    return this;
  }

  public count(): this {
    this.url += `/${Api.count}`;
    return this;
  }

  public assign(): this {
    this.url += `/${Api.assign}`;
    return this;
  }

  public assignable(): this {
    this.url += `/${Api.assignable}`;
    return this;
  }

  public withdrawAssignee(): this {
    this.url += `/${Api.withdraw_assignee}`;
    return this;
  }

  public tenant(tenantId?: string): this {
    this.url += `/${Api.tenants}`;
    if (typeof tenantId === 'string') {
      this.url += `/${tenantId}`;
    }
    return this;
  }

  public current(): this {
    this.url += `/${Api.current}`;
    return this;
  }

  public academy(): this {
    this.url += `/${Api.academy}`;
    return this;
  }

  public ssoConfig(): this {
    this.url += `/${Api.ssoConfig}`;
    return this;
  }

  public ssoLink(): this {
    this.url += `/${Api.ssoLink}`;
    return this;
  }

  public summary(): this {
    this.url += `/${Api.summary}`;
    return this;
  }

  public reauthorizeConnection(): this {
    this.url += `/${Api.reauthorize_connection}`;
    return this;
  }

  public important(): this {
    this.url += `/${Api.important}`;
    return this;
  }

  public changePassword(): this {
    this.url += `/${Api.changePassword}`;
    return this;
  }

  public verifyPreviousPassword(): this {
    this.url += `/${Api.verifyPreviousPassword}`;
    return this;
  }

  public verifyPreviousPasswordVisitor(): this {
    this.url += `/${Api.verifyPreviousPasswordVisitor}`;
    return this;
  }

  public userFlowTemplates(templateId?: string): this {
    this.url += `/${Api.user_flow_templates}`;
    if (typeof templateId === 'string') {
      this.url += `/${templateId}`;
    }
    return this;
  }

  public automatedUserFlows(userFlowId?: string): this {
    this.url += `/${Api.automated_user_flows}`;
    if (typeof userFlowId === 'string') {
      this.url += `/${userFlowId}`;
    }
    return this;
  }

  public automatedUserFlowRecordings(recordingId?: string): this {
    this.url += `/${Api.user_flow_recordings}`;
    if (typeof recordingId === 'string') {
      this.url += `/${recordingId}`;
    }
    return this;
  }

  public names(name?: string): this {
    this.url += `/${Api.names}`;
    if (typeof name === 'string') {
      this.url += `/${name}`;
    }
    return this;
  }

  public screenshots(): this {
    this.url += `/${Api.screenshots}`;

    return this;
  }

  public matchComponents(matchComponentId?: string): this {
    this.url += `/${Api.matchComponent}`;

    if (!SharedCommonUtility.isNullishOrEmpty(matchComponentId)) {
      this.url += `/${matchComponentId}`;
    }

    return this;
  }

  public mfa(): this {
    this.url += `/${Api.mfa}`;

    return this;
  }

  public labels(): this {
    this.url += `/${Api.labels}`;

    return this;
  }

  public failedTasks(): this {
    this.url += `/${Api.failedTasks}`;
    return this;
  }

  public designReviewRules(ruleId?: string): this {
    this.url += `/${Api.designReviewRules}`;

    if (!SharedCommonUtility.isNullishOrEmpty(ruleId)) {
      this.url += `/${ruleId}`;
    }

    return this;
  }

  public createDesignRule(): this {
    this.url += `/${Api.createDesignRule}`;

    return this;
  }

  public designReviewPages(designReviewPageId?: string): this {
    this.url += `/${Api.designReviewPages}`;

    if (!SharedCommonUtility.isNullishOrEmpty(designReviewPageId)) {
      this.url += `/${designReviewPageId}`;
    }

    return this;
  }

  public designReviewFindings(designReviewFindingId?: string): this {
    this.url += `/${Api.designReviewFindings}`;

    if (!SharedCommonUtility.isNullishOrEmpty(designReviewFindingId)) {
      this.url += `/${designReviewFindingId}`;
    }

    return this;
  }

  public designReviewHighlights(designReviewHighlightId?: string): this {
    this.url += `/${Api.designReviewHighlights}`;

    if (!SharedCommonUtility.isNullishOrEmpty(designReviewHighlightId)) {
      this.url += `/${designReviewHighlightId}`;
    }

    return this;
  }

  public designReviewProjects(designReviewProjectId?: string): this {
    this.url += `/${Api.designReviewProjects}`;

    if (!SharedCommonUtility.isNullishOrEmpty(designReviewProjectId)) {
      this.url += `/${designReviewProjectId}`;
    }

    return this;
  }

  public add(): this {
    this.url += `/${Api.add}`;
    return this;
  }

  public pdfPagesTestState(): this {
    this.url += `/${Api.pdfPagesTestState}`;
    return this;
  }

  public security(): this {
    this.url += `/${Api.security}`;
    return this;
  }

  public newSecurity(): this {
    this.url += `/${Api.newSecurity}`;
    return this;
  }

  public notifications(): this {
    this.url += `/${Api.notifications}`;
    return this;
  }
  public groups(groupId?: string): this {
    this.url += `/${Api.groups}`;

    if (SharedCommonUtility.notNullishOrEmpty(groupId)) {
      this.url += `/${groupId}`;
    }

    return this;
  }

  public staffGroups(groupId?: string): this {
    this.url += `/${Api.staffGroups}`;

    if (SharedCommonUtility.notNullishOrEmpty(groupId)) {
      this.url += `/${groupId}`;
    }

    return this;
  }

  public tenantsMembership(): this {
    this.url += `/${Api.tenantsMembership}`;

    return this;
  }

  public userManagement(): this {
    this.url += `/${Api.userManagement}`;

    return this;
  }

  public accessibilityPolicies(): this {
    this.url += `/${Api.accessibilityPolicies}`;
    return this;
  }

  public overviewData(): this {
    this.url += `/${Api.overviewData}`;
    return this;
  }

  public copy(): this {
    this.url += `/${Api.copy}`;
    return this;
  }

  public dataSnapshots(): this {
    this.url += `/${Api.data_snapshots}`;
    return this;
  }

  public contentTags(): this {
    this.url += `/${Api.contentTags}`;
    return this;
  }

  public availableContentTags(): this {
    this.url += `/${Api.availableContentTags}`;
    return this;
  }

  public activity(): this {
    this.url += `/${Api.activity}`;
    return this;
  }

  public salesforce(): this {
    this.url += `/${Api.salesforce}`;
    return this;
  }

  public accounts(): this {
    this.url += `/${Api.accounts}`;
    return this;
  }

  public metrics(): this {
    this.url += `/${Api.metrics}`;
    return this;
  }

  public digitalPropertyLimitState(): this {
    this.url += `/${Api.digital_property_limit_state}`;
    return this;
  }

  public digitalPropertyAutomatedFlowLimit(): this {
    this.url += `/${Api.digital_property_automated_flow_limit}`;
    return this;
  }

  public webhooks(webhookId?: string): this {
    this.url += `/${Api.webhooks}`;
    if (SharedCommonUtility.notNullishOrEmpty(webhookId)) {
      this.url += `/${webhookId}`;
    }

    return this;
  }

  public entities(): this {
    this.url += `/${Api.entities}`;
    return this;
  }

  public ampApiKey(tenantId?: string): this {
    this.url += `/${Api.amp_api_key}`;
    if (SharedCommonUtility.notNullishOrEmpty(tenantId)) {
      this.url += `/${tenantId}`;
    }
    return this;
  }

  public validateTasks(): this {
    this.url += `/${Api.validateTasks}`;
    return this;
  }

  public taskProjectId(): this {
    this.url += `/${Api.taskProjectId}`;
    return this;
  }

  public environments(): this {
    this.url += `/${Api.environments}`;
    return this;
  }

  public continuum(): this {
    this.url += `/${Api.continuum}`;
    return this;
  }

  public changeEmails(): this {
    this.url += `/${Api.changeEmails}`;
    return this;
  }

  public updateEmails(): this {
    this.url += `/${Api.updateEmails}`;
    return this;
  }

  public ai(): this {
    this.url += `/${Api.ai}`;
    return this;
  }

  public chat(id?: string): this {
    this.url += `/${Api.chat}`;
    if (id) {
      this.url += `/${id}`;
    }

    return this;
  }

  public rating(): this {
    this.url += `/${Api.rating}`;

    return this;
  }

  public my(): this {
    this.url += `/${Api.my}`;

    return this;
  }

  public snapshots(): this {
    this.url += `/${Api.snapshots}`;
    return this;
  }

  public cloudsmithToken(): this {
    this.url += `/${Api.cloudsmithToken}`;
    return this;
  }

  public email(): this {
    this.url += `/${Api.email}`;
    return this;
  }

  public dataSummary(summaryCode?: string): this {
    this.url += `/${Api.summary}`;
    if (typeof summaryCode === 'string') {
      this.url += `/${summaryCode}`;
    }
    return this;
  }

  public userRoles(): this {
    this.url += `/${Api.user_roles}`;
    return this;
  }

  public signup(): this {
    this.url += `/${Api.signup}`;
    return this;
  }

  public library(): this {
    this.url += `/${Api.library}`;
    return this;
  }

  public scimApiKey(): this {
    this.url += `/${Api.scim_api_key}`;
    return this;
  }

  public scimConfig(): this {
    this.url += `/${Api.scim_config}`;
    return this;
  }

  public unassignedMembers(): this {
    this.url += `/${Api.unassigned_members}`;
    return this;
  }

  public assignUsers(): this {
    this.url += `/${Api.assign_users}`;
    return this;
  }

  public push(): this {
    this.url += `/${Api.push}`;
    return this;
  }

  public recentWorkspaces(): this {
    this.url += `/${Api.recentWorkspaces}`;
    return this;
  }

  public conformanceScoreReport(): this {
    this.url += `/${Api.conformanceScoreReport}`;
    return this;
  }
}
