import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';
import { ITenant, IUpdateTenantAcademyRequest } from '../../../../shared/interfaces/tenant.interface';
import { IAcademySsoLink, IThoughtIndustriesTag } from '../../../../shared/interfaces/academy-integration.interface';

@Injectable({ providedIn: 'root' })
export class AcademyApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /tenants/academy/sso-link
  public generateAcademySSOLink(): Observable<IAcademySsoLink> {
    const url: string = this.restBuilder.create().tenant().academy().ssoLink().getApiUrl();
    return this.httpClient.get<IAcademySsoLink>(url);
  }

  // PUT /tenants/academy
  public updateAcademy(tenantId: string): Observable<ITenant> {
    const url: string = this.restBuilder.create().tenant().academy().getApiUrl();
    return this.httpClient.put<ITenant>(url, { tenantId } as IUpdateTenantAcademyRequest);
  }

  // GET /tenants/academy/content-tags?tenantId=abcef134342
  public getTenantContentTags(tenantId: string): Observable<IThoughtIndustriesTag[]> {
    const url: string = this.restBuilder.create().tenant().academy().contentTags().getApiUrl();
    return this.httpClient.get<IThoughtIndustriesTag[]>(url, { params: { tenantId } });
  }

  // GET /tenants/academy/available-content-tags
  public getAvailableContentTags(): Observable<IThoughtIndustriesTag[]> {
    const url: string = this.restBuilder.create().tenant().academy().availableContentTags().getApiUrl();
    return this.httpClient.get<IThoughtIndustriesTag[]>(url);
  }
}
