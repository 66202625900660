import { IAcl } from '../interfaces/acl.interface';
import { AclScope, AclPermissions } from '../../../shared/constants/acl';

export const AclModel: IAcl = {
  [AclScope.adminPanel]: [
    AclPermissions.Admin_ActiveScans_All,
    AclPermissions.Admin_DigitalProperties_All,
    AclPermissions.Admin_Logs_All,
    AclPermissions.Admin_Translations_All,
    AclPermissions.Admin_UsersGroup_All,
    AclPermissions.Admin_Users_All,
    AclPermissions.Admin_Workspaces_All,
  ],
  [AclScope.publicSite]: [],
};
