import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ICreateTenantRequest,
  IEditTenantRequest,
  ITenantUser,
  ITenant,
  ITenantDigitalPropertyLimitState,
  ITenantInfoWithPackageInfo,
  ITenantScimConfig,
  ITenantsResponse,
  ITenantSsoConfig,
  ITenantWithPackageId,
  ITenantDigitalPropertyAutomatedFlowLimit,
} from '../../../shared/interfaces/tenant.interface';
import { TenantApi } from './rest/tenant.api';
import { IWorkspaceWithDpCount } from '../../../shared/interfaces/workspace.interface';
import { IScannableDocumentPdfPagesTestStateResponse } from '../../../shared/interfaces/scannable-document.interface';
import { AcademyApi } from './rest/academy.api';
import { IAcademySsoLink, IThoughtIndustriesTag } from '../../../shared/interfaces/academy-integration.interface';
import { AcademyUserRole } from '../../../shared/constants/academy-user';
import { ISalesforceAccount } from '../../../shared/interfaces/salesforce.interface';
import { SalesforceApi } from './rest/salesforce.api';
import { ITenantMetrics } from '../../../shared/interfaces/tenant-metrics.interface';
import { AccessibilityAuditToolNames } from '../../../shared/constants/audit-tool';
import {
  IGetDigitalPropertyMetricsSnapshotsQuery,
  IPaginatedDigitalPropertyMetricsList,
} from '../../../shared/interfaces/digital-property-metrics.interface';
import { $tenant } from '../../../shared/constants/tenant';
import { IOrganizationReportResponse } from '../../../shared/interfaces/async-report.interface';
import { FileExtension } from '../../../shared/constants/file-extension';
import { DashboardDataFrequency } from '../../../shared/interfaces/ws-dashboard.interface';

@Injectable({ providedIn: 'root' })
export class TenantService {
  public constructor(
    private tenantApi: TenantApi,
    private academyApi: AcademyApi,
    private salesforceApi: SalesforceApi,
  ) {}

  public getTenant(tenantId: string): Observable<ITenantWithPackageId> {
    return this.tenantApi.getTenant(tenantId);
  }

  public getTenantFromTenantedScope(tenantId: string): Observable<ITenantInfoWithPackageInfo> {
    return this.tenantApi.getTenantFromTenantedScope(tenantId);
  }

  public getUserTenantsFromTenantedScope(): Observable<ITenant[]> {
    return this.tenantApi.getUserTenantsFromTenantedScope();
  }

  public updateTenant(tenantId: string, updateRequest: IEditTenantRequest): Observable<ITenant> {
    return this.tenantApi.updateTenant(tenantId, updateRequest);
  }

  public getWorkspaces(tenantId: string, searchString?: string): Observable<IWorkspaceWithDpCount[]> {
    return this.tenantApi.getWorkspaces(tenantId, searchString);
  }

  public getTenants(
    skip: number = 0,
    limit: number = 0,
    term: string = '',
    sortBy?: string,
    direction?: string,
  ): Observable<ITenantsResponse> {
    return this.tenantApi.getTenants(skip, limit, term, sortBy, direction);
  }

  public getMyTenants(
    skip: number = 0,
    limit: number = 0,
    term: string = '',
    sortBy?: string,
    direction?: string,
  ): Observable<ITenantsResponse> {
    return this.tenantApi.getMyTenants(skip, limit, term, sortBy, direction);
  }

  public createTenant(createRequest: ICreateTenantRequest): Observable<ITenant> {
    return this.tenantApi.createTenant(createRequest);
  }

  public setSsoConfig(tenantId: string, ssoConfig: ITenantSsoConfig): Observable<void> {
    return this.tenantApi.setSsoConfig(tenantId, ssoConfig);
  }

  public removeSsoConfig(tenantId: string, reason: string): Observable<void> {
    return this.tenantApi.removeSsoConfig(tenantId, reason);
  }

  public getPdfPagesTestState(tenantId: string): Observable<IScannableDocumentPdfPagesTestStateResponse> {
    return this.tenantApi.getPdfPagesTestState(tenantId);
  }

  public getTenantUsers(tenantId: string): Observable<ITenantUser[]> {
    return this.tenantApi.getTenantUsers(tenantId);
  }

  public activateUserInAcademy(tenantId: string, userId: string): Observable<void> {
    return this.tenantApi.activateUserInAcademy(tenantId, userId);
  }

  public deactivateUserInAcademy(tenantId: string, userId: string): Observable<void> {
    return this.tenantApi.deactivateUserInAcademy(tenantId, userId);
  }

  public setUserRolesInAcademy(tenantId: string, userId: string, roles: AcademyUserRole[]): Observable<void> {
    return this.tenantApi.setUserRolesInAcademy(tenantId, userId, roles);
  }

  public generateAcademySSOLink(): Observable<IAcademySsoLink> {
    return this.academyApi.generateAcademySSOLink();
  }

  public updateAcademy(tenantId: string): Observable<ITenant> {
    return this.academyApi.updateAcademy(tenantId);
  }

  public getTenantContentTags(tenantId: string): Observable<IThoughtIndustriesTag[]> {
    return this.academyApi.getTenantContentTags(tenantId);
  }

  public getAvailableContentTags(): Observable<IThoughtIndustriesTag[]> {
    return this.academyApi.getAvailableContentTags();
  }

  public getSalesforceAccounts(): Observable<ISalesforceAccount[]> {
    return this.salesforceApi.getSalesforceAccounts();
  }

  public getDigitalPropertyLimitState(tenantId: string): Observable<ITenantDigitalPropertyLimitState | null> {
    return this.tenantApi.getDigitalPropertyLimitState(tenantId);
  }

  public getDigitalPropertyAutomatedFlowLimit(): Observable<ITenantDigitalPropertyAutomatedFlowLimit> {
    return this.tenantApi.getDigitalPropertyAutomatedFlowLimit();
  }

  public getTenantMetrics(
    tenantId: string,
    toolName: AccessibilityAuditToolNames,
    frequency: DashboardDataFrequency,
  ): Observable<ITenantMetrics[]> {
    return this.tenantApi.getTenantMetrics(tenantId, toolName, frequency);
  }

  public getDigitalPropertyMetricsSnapshots(
    tenantId: string,
    params: IGetDigitalPropertyMetricsSnapshotsQuery,
  ): Observable<IPaginatedDigitalPropertyMetricsList> {
    return this.tenantApi.getDigitalPropertyMetricsSnapshots(tenantId, params);
  }

  /**
   * Returns the entitlement token for the current tenant. If the tenant does not have an entitlement token
   * then one will be created. Only works in tenant environments.
   */
  public getOrCreateTenantEntitlementToken(): Observable<Pick<ITenant, $tenant.cloudsmithToken>> {
    return this.tenantApi.getOrCreateTenantEntitlementToken();
  }

  /**
   * Returns the entitlement token for the current tenant. Only works in tenant environments.
   * Entitlement token is possibly undefined.
   */
  public getTenantEntitlementToken(): Observable<Pick<ITenant, $tenant.cloudsmithToken>> {
    return this.tenantApi.getTenantEntitlementToken();
  }

  public downloadOrganizationReport(
    tenantId: string,
    toolName: AccessibilityAuditToolNames,
    fileExtension: FileExtension,
  ): Observable<IOrganizationReportResponse> {
    return this.tenantApi.downloadOrganizationReport(tenantId, toolName, fileExtension);
  }

  public generateOrganizationReport(
    tenantId: string,
    toolName: AccessibilityAuditToolNames,
    fileExtension: FileExtension,
  ): Observable<IOrganizationReportResponse> {
    return this.tenantApi.generateOrganizationReport(tenantId, toolName, fileExtension);
  }

  // GET /tenants/current/scim_config
  public getScimConfig(): Observable<ITenantScimConfig> {
    return this.tenantApi.getScimConfig();
  }

  // POST /tenants/current/scim_config
  public setScimConfig(config: ITenantScimConfig): Observable<ITenantScimConfig> {
    return this.tenantApi.setScimConfig(config);
  }
}
