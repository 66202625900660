import { MarkdownUtility } from '../../../../../shared/utils/markdown.utility';
import { TranslateService } from '../../../translate/translate.service';

export interface ICreateTaskDescription {
  header: string;
  content: string;
}

export class JiraMarkdownUtils {
  public static jiraDescriptionBlock(translateService: TranslateService, description: ICreateTaskDescription): string {
    return description.content && description.content.length > 0
      ? `**${translateService.instant(description.header)}**${MarkdownUtility.br}${description.content}\n\n`
      : '';
  }
}
