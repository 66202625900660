import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { IAttachmentCellConfig, AttachmentsCellComponent } from '../cells/attachments-cell/attachments-cell.component';
import { ICellConfig } from '../cells/base-cell/base-cell.component';
import { IButtonCellConfig, ButtonCellComponent } from '../cells/button-cell/button-cell.component';
import { ICheckboxCellConfig, CheckboxCellComponent } from '../cells/checkbox-cell/checkbox-cell.component';
import { IDataPointCellConfig, DataPointCellComponent } from '../cells/data-point-cell/data-point-cell.component';
import { IDocumentFilesCellConfig, DocumentFilesCellComponent } from '../cells/document-files-cell/document-files-cell.component';
import { IDropdownCellConfig, DropdownCellComponent } from '../cells/dropdown-cell/dropdown-cell.component';
import { EmptyCellComponent } from '../cells/empty-cell/empty-cell.component';
import { ExternalLinkButtonCellComponent } from '../cells/external-link-button-cell/external-link-button-cell.component';
import { ExternalLinkCellComponent, IExternalLinkCellConfig } from '../cells/external-link-cell/external-link-cell.component';
import { HtmlCellComponent } from '../cells/html-cell/html-cell.component';
import { HtmlHighlightCellComponent } from '../cells/html-highlight-cell/html-highlight-cell.component';
import { IImageCellConfig, ImageCellComponent } from '../cells/image-cell/image-cell.component';
import {
  IIssueStateLabelCellConfig,
  IssueStateLabelCellComponent,
} from '../cells/issue-state-label-cell/issue-state-label-cell.component';
import {
  IIssuesViewDetailsCellConfig,
  IssuesViewDetailsCellComponent,
} from '../cells/issues-view-details-cell/issue-view-details-cell.component';
import { ILinkCellConfig, LinkCellComponent } from '../cells/link-cell/link-cell.component';
import {
  ILinksOrderedListCellConfig,
  LinksOrderedListCellComponent,
} from '../cells/links-ordered-list-cell/links-ordered-list-cell.component';
import { IOrderedListCellConfig, OrderedListCellComponent } from '../cells/ordered-list-cell/ordered-list-cell.component';
import { PendingCellComponent } from '../cells/pending-cell/pending-cell.component';
import {
  IRuleStatusToggleConfig,
  RuleStatusToggleCellComponent,
} from '../cells/rule-status-toggle-cell/rule-status-toggle-cell.component';
import { IScoreCellConfig, ScoreCellComponent } from '../cells/score-cell/score-cell.component';
import { SecureImageCellComponent } from '../cells/secure-image-cell/secure-image-cell.component';
import { ISelectCellConfig, SelectCellComponent } from '../cells/select-cell/select-cell.component';
import { ISeverityCellConfig, SeverityCellComponent } from '../cells/severity-cell/severity-cell.component';
import { ITemplateCellConfig, TemplateCellComponent } from '../cells/template-cell/template-cell.component';
import { ITextCellConfig, TextCellComponent } from '../cells/text-cell/text-cell.component';
import { ITextWithIconCellConfig, TextWithIconCellComponent } from '../cells/text-with-icon-cell/text-with-icon-cell.component';
import { IThumbnailCellConfig, ThumbnailCellComponent } from '../cells/thumbnail-cell/thumbnail-cell.component';
import { ITitleCellConfig, TitleCellComponent } from '../cells/title-cell/title-cell.component';
import { ITypeAheadCellConfig, TypeAheadCellComponent } from '../cells/type-ahead-cell/type-ahead-cell.component';
import {
  IUserCellConfig,
  UserAccountStatusCellComponent,
} from '../cells/user-account-status-cell/user-account-status-cell.component';
import { IUserAvatarCellConfig, UserAvatarCellComponent } from '../cells/user-avatar-cell/user-avatar-cell-component';
import { IDclConfig } from './ngb-table.interface';
import { DSButtonCellComponent, IDSButtonCellConfig } from '../cells/ds-button-cell/ds-button-cell.component';

export class NgbTableUtilities {
  public static textCell(config: ITextCellConfig): IDclConfig<ITextCellConfig> {
    return {
      type: TextCellComponent,
      config: config,
    };
  }

  public static linkCell(config: ILinkCellConfig): IDclConfig<ILinkCellConfig> {
    return {
      type: LinkCellComponent,
      config: config,
    };
  }

  public static externalLinkCell(config: IExternalLinkCellConfig): IDclConfig<ICellConfig> {
    return {
      type: ExternalLinkCellComponent,
      config: config,
    };
  }

  public static externalLinkButtonCell(config: ICellConfig): IDclConfig<ICellConfig> {
    return {
      type: ExternalLinkButtonCellComponent,
      config: config,
    };
  }

  public static buttonCell(config: IButtonCellConfig): IDclConfig<IButtonCellConfig> {
    return {
      type: ButtonCellComponent,
      config: config,
    };
  }

  public static DSbuttonCell(config: IDSButtonCellConfig): IDclConfig<IDSButtonCellConfig> {
    return {
      type: DSButtonCellComponent,
      config: config,
    };
  }

  public static dropdownCell(config: IDropdownCellConfig): IDclConfig<IDropdownCellConfig> {
    if (config.ellipsisIcon && !SharedCommonUtility.isNullishOrEmpty(config.text)) {
      throw new Error(
        `ngb-table.dropdownCell - The properties 'ellipsisIcon' and 'text' are incompatible! Please pick only one and remove the other.`,
      );
    }
    return {
      type: DropdownCellComponent,
      config: config,
    };
  }

  public static severityCell(config: ISeverityCellConfig): IDclConfig<ISeverityCellConfig> {
    return {
      type: SeverityCellComponent,
      config: config,
    };
  }

  public static scoreCell(config: IScoreCellConfig): IDclConfig<IScoreCellConfig> {
    return {
      type: ScoreCellComponent,
      config: config,
    };
  }

  public static checkboxCell(config: ICheckboxCellConfig): IDclConfig<ICheckboxCellConfig> {
    return {
      type: CheckboxCellComponent,
      config: config,
    };
  }

  public static issueStateLabelCell(config: IIssueStateLabelCellConfig): IDclConfig<IIssueStateLabelCellConfig> {
    return {
      type: IssueStateLabelCellComponent,
      config: config,
    };
  }

  public static titleCell(config: ITitleCellConfig): IDclConfig<ITitleCellConfig> {
    return {
      type: TitleCellComponent,
      config: config,
    };
  }

  public static htmlCell(config: ICellConfig): IDclConfig<ICellConfig> {
    return {
      type: HtmlCellComponent,
      config: config,
    };
  }

  public static htmlHighlightCell(config: ICellConfig): IDclConfig<ICellConfig> {
    return {
      type: HtmlHighlightCellComponent,
      config: config,
    };
  }

  public static issuesViewDetailsCell(config: IIssuesViewDetailsCellConfig): IDclConfig<IIssuesViewDetailsCellConfig> {
    return {
      type: IssuesViewDetailsCellComponent,
      config: config,
    };
  }

  public static pendingCell(config: ICellConfig): IDclConfig<ICellConfig> {
    return {
      type: PendingCellComponent,
      config,
    };
  }

  public static attachmentsCell(config: IAttachmentCellConfig): IDclConfig<IAttachmentCellConfig> {
    return {
      type: AttachmentsCellComponent,
      config,
    };
  }

  public static thumbnailCell(config: IThumbnailCellConfig): IDclConfig<IThumbnailCellConfig> {
    return {
      type: ThumbnailCellComponent,
      config,
    };
  }

  public static orderedListCell(config: IOrderedListCellConfig): IDclConfig<IOrderedListCellConfig> {
    return {
      type: OrderedListCellComponent,
      config,
    };
  }

  public static linksOrderedListCell(config: ILinksOrderedListCellConfig): IDclConfig<ILinksOrderedListCellConfig> {
    return {
      type: LinksOrderedListCellComponent,
      config,
    };
  }

  public static userAccountStatusCell(config: IUserCellConfig): IDclConfig<IUserCellConfig> {
    return {
      type: UserAccountStatusCellComponent,
      config,
    };
  }

  public static userAvatarCell(config: IUserAvatarCellConfig): IDclConfig<IUserAvatarCellConfig> {
    return {
      type: UserAvatarCellComponent,
      config,
    };
  }

  public static templateCell(config: ITemplateCellConfig): IDclConfig<ITemplateCellConfig> {
    return {
      type: TemplateCellComponent,
      config: config,
    };
  }

  public static imageCell(config: IImageCellConfig): IDclConfig<IImageCellConfig> {
    return {
      type: ImageCellComponent,
      config: config,
    };
  }

  public static secureImageCell(config: IImageCellConfig): IDclConfig<IImageCellConfig> {
    return {
      type: SecureImageCellComponent,
      config: config,
    };
  }

  public static documentFilesCell(config: IDocumentFilesCellConfig): IDclConfig<IDocumentFilesCellConfig> {
    return {
      type: DocumentFilesCellComponent,
      config: config,
    };
  }

  public static textWithIconCell(config: ITextWithIconCellConfig): IDclConfig<ITextWithIconCellConfig> {
    return {
      type: TextWithIconCellComponent,
      config: config,
    };
  }

  public static emptyCell(): IDclConfig<null> {
    return {
      type: EmptyCellComponent,
      config: null,
    };
  }

  public static selectCell(config: ISelectCellConfig): IDclConfig<ISelectCellConfig> {
    return {
      type: SelectCellComponent,
      config,
    };
  }

  public static typeAheadCell(config: ITypeAheadCellConfig): IDclConfig<ITypeAheadCellConfig> {
    return {
      type: TypeAheadCellComponent,
      config,
    };
  }

  public static dataPointCell(config: IDataPointCellConfig): IDclConfig<IDataPointCellConfig> {
    return {
      type: DataPointCellComponent,
      config,
    };
  }

  static ruleStatusToggleCell(config: IRuleStatusToggleConfig): IDclConfig<IRuleStatusToggleConfig> {
    return {
      type: RuleStatusToggleCellComponent,
      config: config,
    };
  }
}
