import { Injectable, NgZone } from '@angular/core';

import { $user } from '../../../shared/constants/user';
import { IUserServerResponse } from '../../../shared/interfaces/user.interface';
import { $workspaceRole } from '../../../shared/constants/workspace-role.constants';
import { IWorkspaceRole } from '../../../shared/interfaces/workspace-role.interface';
import { WorkspaceAclRoles } from '../../../shared/constants/workspace';
import { AclRoles } from '../../../shared/constants/acl';
import { IApplicationRole } from '../../../shared/interfaces/application-role.interface';
import { $applicationRole } from '../../../shared/constants/application-role.constants';
import { AppConfigService } from './app-config.service';

enum WalkmeSystemRoles {
  superAdmin = 'Super Admin',
  admin = 'Admin',
  user = 'User',
}

enum WalkmeWorkspaceRoles {
  workspaceAdmin = 'Workspace Admin',
  workspaceUser = 'Workspace User',
  workspaceStaff = 'Workspace Staff',
}

@Injectable({
  providedIn: 'root',
})
export class WalkMeService {
  private readonly scriptSrc: string;

  constructor(
    private ngZone: NgZone,
    private appConfigService: AppConfigService,
  ) {
    this.scriptSrc = this.appConfigService.getWalkMeScriptUrl();
  }

  private isWalkMeConfigured(): boolean {
    return typeof this.scriptSrc === 'string' && this.scriptSrc.length > 0;
  }

  private onLoadWalkMeError(error: any): void {
    console.error('[WalkMeService].onLoadWalkMeError', error);
  }

  private async loadWalkMeWidget(): Promise<void> {
    const action = (resolve: Function, reject: Function): void => {
      const walkMeScript: HTMLScriptElement = document.createElement('script');

      walkMeScript.id = 'walkMeScript';
      walkMeScript.type = 'text/javascript';
      walkMeScript.async = true;
      walkMeScript.src = this.scriptSrc;

      const onWalkMeLoaded = (): void => {
        resolve();
      };

      const onWalkMeLoadError = (error: ErrorEvent): void => {
        reject(error);
      };

      walkMeScript.addEventListener('load', onWalkMeLoaded);
      walkMeScript.addEventListener('error', onWalkMeLoadError);

      document.head.insertBefore(walkMeScript, document.head.firstChild);

      window['_walkmeConfig'] = {
        smartLoad: true,
      };
    };

    return new Promise(action);
  }

  public static getWorkspaceRole(userData: IUserServerResponse): string {
    if (Array.isArray(userData[$user.workspaceRoles]) === false || userData[$user.workspaceRoles].length === 0) {
      return '';
    }

    const wsRole: IWorkspaceRole = userData[$user.workspaceRoles][0];

    switch (wsRole[$workspaceRole.name]) {
      case WorkspaceAclRoles.workspaceAdmin:
        return WalkmeWorkspaceRoles.workspaceAdmin;
      case WorkspaceAclRoles.workspaceStaff:
        return WalkmeWorkspaceRoles.workspaceStaff;
      case WorkspaceAclRoles.workspaceUser:
        return WalkmeWorkspaceRoles.workspaceUser;
      default:
        return wsRole[$workspaceRole.description];
    }
  }

  public static getHigherApplicationRole(userData: IUserServerResponse): string {
    if (Array.isArray(userData[$user.applicationRoles]) === false || userData[$user.applicationRoles].length === 0) {
      return '';
    }

    const isOfRole = (role: AclRoles): boolean => {
      return userData[$user.applicationRoles].some((appRole: IApplicationRole): boolean => {
        return appRole[$applicationRole.name] === role;
      });
    };

    if (isOfRole(AclRoles.superAdmin)) {
      return WalkmeSystemRoles.superAdmin;
    } else if (isOfRole(AclRoles.admin)) {
      return WalkmeSystemRoles.admin;
    } else if (isOfRole(AclRoles.user)) {
      return WalkmeSystemRoles.user;
    }

    return userData[$user.applicationRoles][0][$applicationRole.description];
  }

  public setGlobalVariable(currentSessionData: Record<string, any>): void {
    window['walkme_variable'] = currentSessionData;
  }

  public initialiseWalkMe(): void {
    if (this.isWalkMeConfigured() === false) {
      return;
    }

    this.ngZone.runOutsideAngular((): void => {
      this.loadWalkMeWidget().catch(this.onLoadWalkMeError.bind(this));
    });
  }
}
