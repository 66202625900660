import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';
import { Params } from 'express-serve-static-core';
import { BaseCellComponent, ICellConfig, IIconConfig, ITagConfig, setupCell } from '../base-cell/base-cell.component';

export interface ILinkCellConfig extends ICellConfig, ITagConfig {
  icon?: IIconConfig;
  tagLabel?: string;
  tagColor?: string;
  noDecoration?: boolean;
}

@Component({
  selector: 'table-cell-link',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <ng-template #icon>
      <svg
        [class.me-2]="config.icon.iconBeforeText"
        [class.ms-2]="!config.icon.iconBeforeText"
        [attr.aria-hidden]="!config.icon.iconAriaLabelText"
        [attr.aria-label]="config.icon.iconAriaLabelText"
        [attr.width]="config.icon.iconWidth"
        [attr.height]="config.icon.iconHeight"
        [ngStyle]="config.icon.iconStyles"
        #svgTemplate
      >
        <use [attr.xlink:href]="'#' + config.icon.iconName"></use>
      </svg>
    </ng-template>
    <div [ngStyle]="config.wrapperStyles">
      <a
        [routerLink]="routerLink"
        [queryParams]="queryParams"
        [fragment]="fragment"
        [ngStyle]="config?.styles"
        [queryParamsHandling]="queryParamsHandling"
        [class.margin-right]="config.tagLabel"
        (click)="onClick($event)"
        #ref
      >
        <ng-container *ngIf="config.icon && config.icon.iconBeforeText">
          <ng-template [ngTemplateOutlet]="icon"></ng-template>
        </ng-container>
        <span [ngClass]="{ 'text-underline': !config.noDecoration }">{{ config.text }}</span>
        <ng-container *ngIf="config.icon && !config.icon.iconBeforeText">
          <ng-template [ngTemplateOutlet]="icon"></ng-template>
        </ng-container>
      </a>
      <ng-container *ngIf="config.tagLabel">
        <span
          class="tag-label d-inline-block"
          data-test-id="table-cell-tag-label"
          [style.background-color]="config.tagColor"
          [appTooltip]="config.tagTooltip"
          [attr.aria-label]="config.tagTooltip"
        >
          {{ config.tagLabel }}
        </span>
      </ng-container>
      <div class="sub-text" *ngIf="config.subText" data-test-id="table-cell-sub-text">
        {{ config.subText }}
      </div>
    </div>
  `,
})
export class LinkCellComponent extends BaseCellComponent implements OnInit, AfterViewInit {
  @ViewChild('svgTemplate')
  public svgElement: ElementRef;

  @Input() public config: ILinkCellConfig;
  public routerLink: string;
  public queryParams: Params;
  public fragment: string;
  public queryParamsHandling: QueryParamsHandling;

  public onClick(event: MouseEvent): void {
    event?.stopPropagation();
  }

  public ngOnInit(): void {
    this.routerLink = this.config.attributes['href'] || this.config.attributes['routerLink'];
    this.queryParams = this.config.attributes['queryParams'] || {};
    this.fragment = this.config.attributes['fragment'] || null;
    this.queryParamsHandling = this.config.attributes['queryParamsHandling'] || '';
  }

  public ngAfterViewInit(): void {
    if (this.config.icon !== undefined) {
      setupCell(this.renderer, this.config.icon, this.svgElement.nativeElement);
    }

    super.ngAfterViewInit();
  }
}
