<div class="row users-list" *ngIf="usersToDisplay">
  <div class="col-12 col-md-auto" *ngIf="displayTitle">
    <span class="title text-black">{{ 'users' | translate }}</span>
  </div>

  <div class="col-12 col-md-auto d-flex flex-wrap">
    <div *ngFor="let user of usersToDisplay; let i = index" class="position-relative">
      <img
        tabindex="0"
        [src]="getUserAvatarUrl(user)"
        class="user-profile-picture"
        alt="{{ getDisplayName(user) + ' ' + ('user_profile_avatar' | translate) }}"
        [ngbTooltip]="getDisplayName(user)"
        [container]="'body'"
      />
    </div>
    <div *ngIf="hiddenUsers && usersCount > maxDisplayedUsers" class="position-relative">
      <button
        class="btn btn-link extra-users-count"
        #buttonElement
        [triggers]="'click'"
        [container]="'body'"
        popoverClass="popover-height-100"
        (hidden)="buttonElement.focus()"
        (shown)="focusUsersListPopover()"
        [autoClose]="'outside'"
        [ngbPopover]="hiddenUsersContainer"
        [attr.aria-label]="'+' + hiddenUsers.length + ' ' + ('users' | translate | lowercase)"
      >
        <span>+{{ hiddenUsers.length }}</span>
      </button>
    </div>
  </div>
</div>

<ng-template #hiddenUsersContainer>
  <div class="d-flex" *ngFor="let user of hiddenUsers; let index = $index" [tabIndex]="index">
    <img
      class="user-profile-picture"
      [src]="getUserAvatarUrl(user)"
      title="{{ getDisplayName(user) }}"
      aria-hidden="true"
      alt="{{ getDisplayName(user) + ' ' + ('user_profile_avatar' | translate) }}"
    />
    <span class="user-name">{{ getDisplayName(user) }}</span>
  </div>
</ng-template>
