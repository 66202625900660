<div>
  <div class="margin-bottom">
    <ng-container [ngSwitch]="toolName">
      <ng-container *ngSwitchCase="AccessibilityAuditToolNames.ibm">
        <label class="label margin-bottom-xs">{{ 'label_what_the_problem' | translate }}</label>
        <div
          [appHighlight]="issue.data.actualResult"
          [type]="'html'"
          class="margin-bottom-sm"
          
        ></div>
      </ng-container>
      <ng-container *ngSwitchCase="AccessibilityAuditToolNames.wave"></ng-container>
      <ng-container *ngSwitchDefault>
        <label class="label margin-bottom-xs">{{ 'label_what_the_problem' | translate }}</label>
        <div [innerHTML]="issue.data.actualResult" class="margin-bottom-sm" ></div>
      </ng-container>
    </ng-container>

    <button *ngIf="isColorContrastRule(issue)" (click)="openColorContrastModal()" type="button" class="btn btn-link p-0 fw-bold">
      {{ 'contrast_finder' | translate }}
    </button>
  </div>
  <div class="margin-bottom">
    <label class="label margin-bottom-xs">{{ 'label_where_is_it_located' | translate }}</label>
    <div >
      {{ issue.title }} - <a [href]="issue.url" target="_blank">{{ issue.url }}</a>
    </div>
  </div>
  <div class="margin-bottom">
    <label class="label margin-bottom-xs">{{ 'component_name' | translate }}</label>
    <div >
      {{ getFlawComponentName() }}
    </div>
  </div>
  <div
    *ngIf="issue.data.elementReference.includes(ACCESS_ENGINE_SHADOW_DOM_SEPARATOR)"
    class="info-banner alert alert-primary w-100 d-flex align-items-center margin-bottom"
  >
    <ds-icon class="margin-right-sm icon-info" [icon]="Icons.CircleInfo" [iconStyle]="IconStyles.Regular"></ds-icon>
    {{ 'finding_in_shadow_dom' | translate }}
  </div>
  <div class="margin-bottom" *ngIf="issue.data.elementReference?.trim()?.length > 0">
    <div class="margin-bottom-xs d-flex align-items-center justify-content-between">
      <span class="label">{{ 'label_xpath_or_css' | translate }}:</span>
      <ds-copy [htmlIdToCopy]="selectorId"></ds-copy>
    </div>
    <pre><code  [id]="selectorId" class="d-block p-2" [appHighlight]="issue.data.elementReference" type="xquery"></code></pre>
  </div>
  <div class="margin-bottom" *ngIf="issue.data.element">
    <div class="margin-bottom-xs d-flex align-items-center justify-content-between">
      <span class="label">{{ 'audit_rule_property_nodesHTML' | translate }}:</span>
      <ds-copy [htmlIdToCopy]="nodesHTMLId"></ds-copy>
    </div>
    <pre><code  [id]="nodesHTMLId" class="d-block p-2" [appHighlight]="issue.data.element" type="html"></code></pre>
  </div>
  <div class="row margin-bottom" *ngIf="ruleDetails[$scanIssues.description]">
    <div class="col-12">
      <label class="label margin-bottom-xs">{{ 'audit_rule_property_description' | translate }}</label>
      <div
        class="rule-description pre-line"
        [appHighlight]="ruleDetails[$scanIssues.description]"
        [type]="'html'"
        
      ></div>
    </div>
  </div>
  <ng-container [ngSwitch]="toolName">
    <div class="row margin-bottom" *ngIf="ruleDetails[$scanIssues.whyItMatters]">
      <div class="col-12">
        <label class="label margin-bottom-xs">{{ 'audit_rule_property_why_it_matters' | translate }}</label>
        <ng-container *ngSwitchCase="AccessibilityAuditToolNames.access_engine">
          <div
            class="rule-description pre-line"
            [innerHTML]="ruleDetails[$scanIssues.whyItMatters] | safeContent"
            
          ></div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div
            class="rule-description pre-line"
            [appHighlight]="ruleDetails[$scanIssues.whyItMatters]"
            [type]="'html'"
            
          ></div>
        </ng-container>
      </div>
    </div>
    <div class="row margin-bottom" *ngIf="ruleDetails[$scanIssues.whatToDo]">
      <div class="col-12">
        <label class="label margin-bottom-xs">{{ 'audit_rule_property_what_to_do' | translate }}</label>
        <ng-container *ngSwitchCase="AccessibilityAuditToolNames.access_engine">
          <div
            class="rule-description pre-line"
            [innerHTML]="ruleDetails[$scanIssues.whatToDo] | safeContent"
            
          ></div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div
            class="rule-description pre-line"
            [appHighlight]="ruleDetails[$scanIssues.whatToDo]"
            type="html"
            
          ></div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="row margin-bottom" *ngIf="issue._id">
    <div class="col-12">
      <label class="label margin-bottom-xs">{{ 'findings_history_label' | translate }}</label>
      <div class="rule-description pre-line" >
        {{ 'finding_first_seen_label' | translate }} {{ issue[$flaw.firstFound].date | prettifyDate }}
      </div>
      <div class="rule-description pre-line" >
        {{ 'finding_last_seen_label' | translate }} {{ issue[$flaw.lastFound].date | prettifyDate }}
      </div>
      <div class="rule-description pre-line" >
        {{ 'finding_previous_status_label' | translate }} "{{ 'label_' + getFlawPreviousStatus() | translate }}"
      </div>
      <div class="rule-description pre-line" >
        {{ 'finding_times_seen_label' | translate }} {{ issue.flawOccurrence }}
      </div>
    </div>
  </div>
  <div class="margin-bottom">
    <app-share-issue-report-url [url]="url"></app-share-issue-report-url>
  </div>
</div>
