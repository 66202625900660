import { Injectable } from '@angular/core';
import { intersection } from 'lodash';

import { AclPermissions, applicationPermissions } from '../../../shared/constants/acl';
import { IUserServerResponse } from '../../../shared/interfaces/user.interface';
import { $user } from '../../../shared/constants/user';
import { $workspace } from '../../../shared/constants/workspace';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { IWorkspaceClient } from '../../../shared/interfaces/workspace.interface';
import { AppConfigService } from './app-config.service';
import { ISecuritySet } from '../../../shared/interfaces/security-set.interface';
import { FunctionalActions } from '../../../shared/interfaces/security-role.interface';
import { SecurityUtility } from '../../../shared/utils/security.utility';
import { ISecurityEntity } from '../../../shared/interfaces/security-group.interface';
import { IDigitalPropertyListItem } from '../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../shared/constants/digital-properties';
import { AclSecurityAdapterBase } from '../../../shared/services/acl-security-adapter';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { FeatureFlagCollection } from '../../../shared/interfaces/feature-flag.interface';
import { SecurityEntityLevel } from '../../../shared/constants/security-group';
import { IRequiredSecurity } from '../../../shared/interfaces/security.interface';

export class AclSecurityAdapter extends AclSecurityAdapterBase {
  constructor(
    private readonly user: IUserServerResponse,
    private aclService: AclService,
    private tenantId?: string,
  ) {
    super();
  }

  public useRequiredSecurity(requiredSecurity: IRequiredSecurity, isAdmin: boolean): this {
    const allowAdminAccess: boolean =
      requiredSecurity.entityLevel !== SecurityEntityLevel.admin && requiredSecurity.allowAdminAccess;

    if (isAdmin && allowAdminAccess) {
      return this.useAdmin();
    } else if (SharedCommonUtility.notNullish(requiredSecurity.entityLevel)) {
      switch (requiredSecurity.entityLevel) {
        case SecurityEntityLevel.admin:
        case SecurityEntityLevel.tenant:
          return this.useEntityLevel();
        case SecurityEntityLevel.digitalProperty:
          return this.useWorkspaceFromUser().useDigitalPropertyFromUser();
        default:
          return this.useWorkspaceFromUser();
      }
    }

    return this;
  }

  private getDigitalPropertyFromUser(): IDigitalPropertyListItem {
    const lastUsedDigitalPropertyId: string = this.user[$user.lastUsedDigitalProperty];
    if (SharedCommonUtility.isNullishOrEmpty(lastUsedDigitalPropertyId)) {
      return undefined;
    }

    const findFn = (property: IDigitalPropertyListItem): boolean => property[$digitalProperty._id] === lastUsedDigitalPropertyId;
    return this.user[$user.digitalProperties].find(findFn);
  }

  private runNewSecurityCheck(): boolean {
    if (this.skipNewSecurity) {
      return true;
    }

    if (SharedCommonUtility.isNullish(this.functionalActions) && this.options.throwIfFunctionalActionsUndefined) {
      throw new Error('[AclSecurityAdapter.check] Functional actions are not defined');
    }

    if (SharedCommonUtility.isNullish(this.functionalActions) && this.options.requireFunctionalActions) {
      return false;
    }

    return this.aclService.canUserAccessResourceWithNewSecurity(
      this.user,
      this.functionalActions,
      this.checkEntityLevel,
      this.workspaceId,
      this.digitalPropertyId,
    );
  }

  private runLegacySecurityCheck(): boolean {
    if (this.newSecurityOnly) {
      return false;
    }

    return this.aclService.canUserAccessResource(this.user, this.permissions, this.workspaceId, this.tenantId);
  }

  public useWorkspaceFromUser(): this {
    const digitalProperty: IDigitalPropertyListItem = this.getDigitalPropertyFromUser();
    if (SharedCommonUtility.isNullish(digitalProperty)) {
      return this;
    }

    this.checkEntityLevel = true;
    this.workspaceId = digitalProperty[$digitalProperty.workspace]._id;
    return this;
  }

  public useTenantFromUser(): this {
    this.checkEntityLevel = true;
    this.tenantId = this.user[$user.currentTenantId];
    return this;
  }

  public useDigitalPropertyFromUser(): this {
    const digitalProperty: IDigitalPropertyListItem = this.getDigitalPropertyFromUser();
    if (SharedCommonUtility.isNullish(digitalProperty)) {
      return this;
    }

    this.checkEntityLevel = true;
    this.digitalPropertyId = digitalProperty[$digitalProperty._id];
    return this;
  }

  public check(): boolean {
    if (this.aclService.isNewSecurityEnabled(this.user)) {
      return this.runNewSecurityCheck();
    }

    return this.runLegacySecurityCheck();
  }

  public reset(): void {
    this.checkEntityLevel = undefined;
    this.workspaceId = undefined;
    this.digitalPropertyId = undefined;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AclService {
  constructor(
    private appConfigService: AppConfigService,
    private featureFlagService: FeatureFlagService,
  ) {}

  private isActionValid(userRole: string): boolean {
    const processAction = (key: string): boolean => {
      return AclPermissions[key] === userRole;
    };

    const index: number = Object.keys(AclPermissions).findIndex(processAction);
    return index !== -1;
  }

  private areActionsValid(userPermissions: string[]): boolean {
    return userPermissions.every(this.isActionValid.bind(this));
  }

  public areRequestedActionsValid(currentUserRoles: string[], requiredUserRoles: string[]): boolean {
    const roleCriteriaMet: boolean = intersection(currentUserRoles, requiredUserRoles).length > 0;
    return this.areActionsValid(currentUserRoles) && roleCriteriaMet;
  }

  // Note: the requiredPermissions array must contain list of permissions that give access to the resouce.
  // Only one of them is necessary to get access to the resource.
  public canUserAccessResource(
    user: IUserServerResponse,
    requiredPermissions: readonly AclPermissions[],
    targetWorkspace?: string,
    targetTenant?: string,
  ): boolean {
    let workspacePermissions: AclPermissions[];
    const hasPermission = (requiredPermission: AclPermissions): boolean => {
      const doApplicationPermissionsCheck: boolean =
        (applicationPermissions.includes(requiredPermission) &&
          SharedCommonUtility.isNullish(targetWorkspace) &&
          SharedCommonUtility.isNullish(targetTenant)) ||
        !workspacePermissions.includes(requiredPermission);
      if (doApplicationPermissionsCheck) {
        return user.applicationPermissions.includes(requiredPermission);
      }
      return workspacePermissions.includes(requiredPermission);
    };

    if (
      SharedCommonUtility.getTypeOf(user) !== 'object' ||
      Array.isArray(user.workspacePermissions) === false ||
      Array.isArray(user.applicationPermissions) === false
    ) {
      return false;
    }

    if (typeof targetWorkspace === 'string') {
      const isTargetWorkspace = (wk: IWorkspaceClient): boolean => wk._id === targetWorkspace;
      const workspace: IWorkspaceClient | undefined = user[$user.workspaces].find(isTargetWorkspace);
      workspacePermissions = typeof workspace !== 'undefined' ? workspace[$workspace.userPermissions] : [];
    } else {
      workspacePermissions = user[$user.workspacePermissions];
    }

    if (Array.isArray(requiredPermissions) === false || requiredPermissions.length === 0) {
      return true;
    }

    return requiredPermissions.some(hasPermission);
  }

  public canUserAccessResourceWithNewSecurity(
    user: IUserServerResponse,
    requiredFunctionalActions?: FunctionalActions,
    checkEntityLevel?: boolean,
    workspaceId?: string,
    digitalPropertyId?: string,
  ): boolean {
    const userSecuritySet: ISecuritySet = user?.[$user.securitySet];
    if (SharedCommonUtility.isNullish(userSecuritySet)) {
      return false;
    }

    const securityEntity: ISecurityEntity = checkEntityLevel
      ? SecurityUtility.createSecurityEntity(user[$user.currentTenantId], workspaceId, digitalPropertyId)
      : undefined;

    const hasAccess: boolean = SecurityUtility.hasAccess(userSecuritySet, requiredFunctionalActions, securityEntity);
    if (!hasAccess && this.featureFlagService.variation(FeatureFlagCollection.permissionLogging, false)) {
      console.trace();
      console.error('[Permission logging] required functional actions', requiredFunctionalActions);
      console.error('user security', userSecuritySet);
      console.error('security entity', securityEntity);
    }
    return hasAccess;
  }

  public createAccessCheck(user: IUserServerResponse): AclSecurityAdapter {
    return new AclSecurityAdapter(user, this);
  }

  public isNewSecurityEnabled(user: Pick<IUserServerResponse, $user.securitySet>): boolean {
    if (SharedCommonUtility.isNullish(user)) {
      return this.appConfigService.isNewSecurityEnabled();
    }

    return SharedCommonUtility.notNullish(user.securitySet);
  }
}
