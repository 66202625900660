import { char } from '../constants/string';

export class SharedTextUtility {
  private static escapeEntityMap: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;',
  };

  private static unescapeEntityMap: { [key: string]: string } = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': `'`,
    '&#039;': `'`,
    '&#x2F;': '/',
    '&#x60;': '`',
    '&#x3D;': '=',
  };

  private static STR_REPLACE_PLACEHOLDER: string = '%';

  public static convertUnderscoresToDashes(text: string): string {
    return text.replace(/_/g, '-');
  }

  // Harness only accepts the special characters: periods, at signs, dashes, and underscores
  // Replace other special characters with underscores
  public static convertToHarnessAcceptableFormat(text: string): string {
    return text.replace(/[^0-9a-zA-Z@_.-\s]/g, '_');
  }

  public static escape(str: string): string {
    const fromEntityMap: any = (s: any): string => {
      return this.escapeEntityMap[s];
    };

    return str.replace(/[&<>"'`=\/]/g, fromEntityMap);
  }

  public static escapeHtmlTags(str: string): string {
    const fromEntityMap: any = (s: any): string => {
      return this.escapeEntityMap[s];
    };

    return str.replace(/[<>]/g, fromEntityMap);
  }

  public static unescapeHtmlTags(str: string): string {
    const fromEntityMap: any = (s: any): string => {
      return this.unescapeEntityMap[s];
    };

    return str.replace(/&(lt|gt);/g, fromEntityMap);
  }

  public static unescape(str: string): string {
    let newStr: string = str;
    for (const key in this.unescapeEntityMap) {
      const replaceWith: string = this.unescapeEntityMap[key];
      newStr = newStr.replace(new RegExp(key, 'g'), replaceWith);
    }

    return newStr;
  }

  public static escapeRegExpString(str: string, decodeURI: boolean = true): string {
    let tmp: string = str;

    if (decodeURI) {
      try {
        tmp = decodeURIComponent(str);
      } catch (error: any) {
        console.error(`[TextUtility.escapeRegExpString] Error decoding RegExp string: "${str}"`, error);
      }
    }

    return tmp.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  public static removeHTML(str: string): any {
    return str.replace(/(<([^>]+)>)/gi, '').replace(/(&.*?&gt;)/g, '');
  }

  public static truncateInTheMiddle(
    text: string,
    startChars: number = 100,
    endChars: number = 100,
    maxLength: number = 300,
  ): string {
    if (text.length > maxLength) {
      return text.substring(0, startChars) + ' [...] ' + text.substring(text.length - endChars, text.length);
    }

    return text;
  }

  public static replacePlaceholder(
    text: string,
    words: string[] | string,
    placeholder: string = SharedTextUtility.STR_REPLACE_PLACEHOLDER,
  ): string {
    const values: string[] = Array.isArray(words) ? words : [words];
    const replaceValue = (result: string, value: string, i: number): string => {
      const searchValue: string = SharedTextUtility.escapeRegExpString(`${placeholder}${i}`, false);
      return result.replace(new RegExp(searchValue, 'g'), String(value));
    };

    return values.reduce(replaceValue, text);
  }

  public static isValidDomain(domain: string): boolean {
    return /^(\*\.)?(?:[a-zA-Z0-9-]{1,61}\.)+(?:[a-zA-Z0-9]{2,}\/?)[^\s]*$/.test(domain);
  }

  public static trimExtraWhitespaces(text: string): string {
    if (typeof text !== 'string') {
      return text;
    }
    return text.replace(/\s+/g, ' ').trim();
  }

  public static unescapeAmpersands(str: string): string {
    return str.replace(/&amp;/g, (s: string): string => this.unescapeEntityMap[s]);
  }

  public static capitalize(stringToCapitalize: string, lowercaseRestOfString: boolean = true): string {
    let restOfString = stringToCapitalize.slice(1);
    if (lowercaseRestOfString) {
      restOfString = restOfString.toLowerCase();
    }

    return stringToCapitalize.charAt(0).toUpperCase() + restOfString;
  }

  public static getLinesFromText(text: string): string[] {
    if (typeof text !== 'string') {
      return [];
    }

    const splitText: RegExp = new RegExp('(?:' + char.new_line + ')');

    const noEmptyString = (path: string): boolean => {
      return path.trim().length > 0;
    };

    return text
      .split(splitText)
      .filter(noEmptyString)
      .map((path: string) => path.trim());
  }

  public static escapeCSVField(s: string): string {
    const escapedDoubleQuotes: string = s.replace(/"/g, '""');
    if (s.includes(',') || s.includes('\n')) {
      return `"${escapedDoubleQuotes}"`;
    }
    return escapedDoubleQuotes;
  }
}
