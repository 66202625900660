import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';
import { IHttpQueryOptions } from '../../../../shared/interfaces/http.query.interface';
import { ApiQueryOption } from '../../../../shared/constants/api';
import { $sortingOrder } from '../../../../shared/constants/sort';
import {
  IManualAudit,
  IManualEvalCreateRequest,
  IManualAuditServerResponse,
  IPopulatedManualAudit,
  IManualAuditUpdateRequest,
} from '../../../../shared/interfaces/manual-audit.interface';
import {
  IDigitalPropertiesPopulatedResponse,
  IValidateBulkUpload,
} from '../../../../shared/interfaces/digital-property.interface';
import { IGetAvailableWorkspacesQuery } from '../../../../shared/interfaces/admin.interface';
import { IGetWorkspacesResponse, ICreateBulkWorkspacesResponse } from '../../../../shared/interfaces/workspace.interface';
import { IWorkspaceRolesResponse } from '../../../../shared/interfaces/acl.interface';
import { $migrateRequest } from '../../../../shared/constants/workspace';
import { IMigrateWorkspaceToTenantRequest } from '../../../../shared/interfaces/workspace.request.interface';
import { IManualScanEdit } from '../../../../shared/interfaces/scan.interface';

@Injectable({
  providedIn: 'root',
})
export class AdminRestAPI {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /admin/roles
  public getApplicationRoles(): Observable<IWorkspaceRolesResponse> {
    const url: string = this.restBuilder.create().admin().roles().getApiUrl();

    return this.httpClient.get<IWorkspaceRolesResponse>(url);
  }

  // POST /admin/workspaces/bulk/validate
  public validateWorkspaceBulkUpload(uploadForm: FormData): Observable<IValidateBulkUpload> {
    const url: string = this.restBuilder.create().admin().workspaces().bulk().validate().getApiUrl();

    return this.httpClient.post<IValidateBulkUpload>(url, uploadForm);
  }

  // POST /admin/workspaces/bulk/create
  public workspaceBulkUpload(uploadForm: FormData): Observable<ICreateBulkWorkspacesResponse> {
    const url: string = this.restBuilder.create().admin().workspaces().bulk().createInPath().getApiUrl();

    return this.httpClient.post<ICreateBulkWorkspacesResponse>(url, uploadForm);
  }

  // GET /admin/workspaces
  public getAllWorkspaces(queryParams: IHttpQueryOptions): Observable<IGetWorkspacesResponse> {
    const url: string = this.restBuilder.create().admin().workspaces().getApiUrl();

    const query: any = {
      [ApiQueryOption.limit]: String(queryParams[ApiQueryOption.limit]),
      [ApiQueryOption.skip]: String(queryParams[ApiQueryOption.skip]),
    };

    if (typeof queryParams[ApiQueryOption.term] === 'string') {
      query[ApiQueryOption.term] = window.encodeURIComponent(String(queryParams[ApiQueryOption.term])).trim();
    }

    if (typeof queryParams[ApiQueryOption.tenantlessOnly] === 'boolean') {
      query[ApiQueryOption.tenantlessOnly] = String(queryParams[ApiQueryOption.tenantlessOnly]);
    }

    if (typeof queryParams[ApiQueryOption.sortByName] === 'string') {
      query[ApiQueryOption.sortByName] = window.encodeURIComponent(String(queryParams[ApiQueryOption.sortByName])).trim();
    }

    if (typeof queryParams[ApiQueryOption.status] === 'string' && queryParams[ApiQueryOption.status] !== $sortingOrder.all) {
      query[ApiQueryOption.status] = window.encodeURIComponent(String(queryParams[ApiQueryOption.status])).trim();
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: query,
    });

    return this.httpClient.get<IGetWorkspacesResponse>(url, { params: httpParams });
  }

  // PUT /admin/workspaces/migrate
  public migrateWorkspaceToTenant(workspacesIds: string[], tenantId: string): Observable<void> {
    const url: string = this.restBuilder.create().admin().workspaces().migrate().getApiUrl();

    const data: IMigrateWorkspaceToTenantRequest = {
      [$migrateRequest.workspacesIds]: workspacesIds,
      [$migrateRequest.tenantId]: tenantId,
    };

    return this.httpClient.put<void>(url, data);
  }

  // GET /admin/digital-properties
  public getAllDigitalProperties(limit: number, skip: number): Observable<IDigitalPropertiesPopulatedResponse> {
    const url: string = this.restBuilder.create().admin().digitalProperties().getApiUrl();

    const params: { [param: string]: string | readonly string[] } = {};

    if (typeof limit === 'number') {
      params.limit = String(limit);
    }

    if (typeof skip === 'number') {
      params.skip = String(skip);
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IDigitalPropertiesPopulatedResponse>(url, { params: httpParams });
  }

  // POST /admin/workspaces/:workspaceId/digital-property/:digitalPropertyId/manual-audit
  public createManualAuditWithFile(workspaceId: string, digitalPropertyId: string, body: FormData): Observable<IManualAudit> {
    const url: string = this.restBuilder
      .create()
      .admin()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualAudit()
      .getApiUrl();

    return this.httpClient.post<IManualAudit>(url, body);
  }

  // POST /admin/workspaces/:workspaceId/digital-property/:digitalPropertyId/manual-audit
  public createManualAudit(
    workspaceId: string,
    digitalPropertyId: string,
    request: IManualEvalCreateRequest,
  ): Observable<IManualAudit> {
    const url: string = this.restBuilder
      .create()
      .admin()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualAudit()
      .getApiUrl();

    return this.httpClient.post<IManualAudit>(url, request);
  }

  // PATCH /admin/workspaces/:workspaceId/digital-property/:digitalPropertyId/manual-audit/:manualEvaluationId
  public patchManualAudit(
    workspaceId: string,
    digitalPropertyId: string,
    manualEvaluationId: string,
    request: IManualEvalCreateRequest,
  ): Observable<IManualAudit> {
    const url: string = this.restBuilder
      .create()
      .admin()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualAudit(manualEvaluationId)
      .getApiUrl();

    return this.httpClient.patch<IManualAudit>(url, request);
  }

  // POST /admin/workspaces/:workspaceId/digital-property/:digitalPropertyId/manual-audit/:id/manual-upload-file
  public manualUploadFile(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditId: string,
    queryParams: FormData,
  ): Observable<IManualAudit> {
    const url: string = this.restBuilder
      .create()
      .admin()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualAudit(manualAuditId)
      .manualUploadFile()
      .getApiUrl();

    return this.httpClient.post<IManualAudit>(url, queryParams);
  }

  // GET /admin/manual-audit
  public getAllManualAudits(queryParams: IHttpQueryOptions): Observable<IManualAuditServerResponse> {
    const url: string = this.restBuilder.create().admin().manualAudit().getApiUrl();

    const query: Record<string, string> = {
      [ApiQueryOption.limit]: String(queryParams[ApiQueryOption.limit]),
      [ApiQueryOption.skip]: String(queryParams[ApiQueryOption.skip]),
    };
    if (typeof queryParams[ApiQueryOption.term] === 'string') {
      query[ApiQueryOption.term] = window.encodeURIComponent(String(queryParams[ApiQueryOption.term])).trim();
    }
    if (typeof queryParams[ApiQueryOption.sortByName] === 'string') {
      query[ApiQueryOption.sortByName] = window.encodeURIComponent(String(queryParams[ApiQueryOption.sortByName])).trim();
    }
    if (typeof queryParams[ApiQueryOption.sortByDigitalProperty] === 'string') {
      query[ApiQueryOption.sortByDigitalProperty] = window
        .encodeURIComponent(String(queryParams[ApiQueryOption.sortByDigitalProperty]))
        .trim();
    }
    if (typeof queryParams[ApiQueryOption.status] === 'string') {
      query[ApiQueryOption.status] = window.encodeURIComponent(String(queryParams[ApiQueryOption.status])).trim();
    }
    if (typeof queryParams[ApiQueryOption.sortCreatedBy] === 'string') {
      query[ApiQueryOption.sortCreatedBy] = window.encodeURIComponent(String(queryParams[ApiQueryOption.sortCreatedBy])).trim();
    }
    if (typeof queryParams[ApiQueryOption.sortCreatedAt] === 'string') {
      query[ApiQueryOption.sortCreatedAt] = window.encodeURIComponent(String(queryParams[ApiQueryOption.sortCreatedAt])).trim();
    }
    const httpParams: HttpParams = new HttpParams({
      fromObject: query,
    });
    return this.httpClient.get<IManualAuditServerResponse>(url, { params: httpParams });
  }

  // GET /admin/workspaces/:workspaceId/digital-property/:id/manual-audit
  public getAllManualAuditsForDigitalProperty(
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IPopulatedManualAudit[]> {
    const url: string = this.restBuilder
      .create()
      .admin()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualAudit()
      .getApiUrl();
    return this.httpClient.get<IPopulatedManualAudit[]>(url);
  }

  // GET /admin/workspaces/:workspaceId/digital-property/:id/manual-audit/:scanId
  public getManualAuditWithPages(
    manualAuditId: string,
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IManualScanEdit> {
    const url: string = this.restBuilder
      .create()
      .admin()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualAudit(manualAuditId)
      .getApiUrl();
    return this.httpClient.get<IManualScanEdit>(url);
  }

  // POST /admin/workspaces/:workspaceId/digital-property/:id/manual-audit/:scanId/archive
  public archiveManualAudit(workspaceId: string, digitalPropertyId: string, scanId: string): Observable<IPopulatedManualAudit> {
    const url: string = this.restBuilder
      .create()
      .admin()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualAudit(scanId)
      .archive()
      .getApiUrl();

    return this.httpClient.post<IPopulatedManualAudit>(url, {});
  }

  // GET /admin/workspaces/available/:userId
  public getUserAvailableWorkspaces(
    userId: string,
    queryOptions: IGetAvailableWorkspacesQuery,
  ): Observable<IGetWorkspacesResponse> {
    const url: string = this.restBuilder.create().admin().workspaces().available(userId).getApiUrl();

    const query: any = {
      [ApiQueryOption.limit]: queryOptions[ApiQueryOption.limit],
      [ApiQueryOption.skip]: queryOptions[ApiQueryOption.skip],
    };

    if (typeof queryOptions[ApiQueryOption.term] === 'string') {
      query[ApiQueryOption.term] = window.encodeURIComponent(queryOptions[ApiQueryOption.term]).trim();
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: query,
    });

    return this.httpClient.get<IGetWorkspacesResponse>(url, { params: httpParams });
  }

  // PUT /admin/workspaces/:workspaceId/digital-property/:id/manual-audit/:scanId
  public updateManualAudit(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    updateRequest: IManualAuditUpdateRequest,
  ): Observable<IPopulatedManualAudit> {
    const url: string = this.restBuilder
      .create()
      .admin()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualAudit(scanId)
      .getApiUrl();

    return this.httpClient.put<IPopulatedManualAudit>(url, updateRequest);
  }
}
