import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { AclService } from '../services/acl.service';
import { AclPermissions } from '../../../shared/constants/acl';
import { IUserServerResponse } from '../../../shared/interfaces/user.interface';

@Pipe({
  name: 'hasPermissions',
})
export class HasPermissionsPipe implements PipeTransform {
  constructor(
    private userService: UserService,
    private aclService: AclService,
  ) {}

  public transform(value: AclPermissions[] | AclPermissions): Observable<boolean> {
    const permissions: AclPermissions[] = Array.isArray(value) ? value : [value];
    return this.userService
      .getAuthenticatedUserProfile()
      .pipe(map((user: IUserServerResponse): boolean => this.aclService.canUserAccessResource(user, permissions)));
  }
}
