import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';
import { pickBy } from 'lodash';

import { Api, ApiHeaderOption, ApiQueryOption } from '../../../../shared/constants/api';
import { $sortingOrder } from '../../../../shared/constants/sort';
import { IAuditFinding } from '../../../../shared/interfaces/audit-finding.interface';
import { IComment } from '../../../../shared/interfaces/comment.interface';
import {
  IDashboardManualEvaluationsBySeverity,
  TopWcagKeyNumPair,
} from '../../../../shared/interfaces/dashboard-scan-result.interface';
import {
  ICreateDigitalPropertyRequest,
  IDigitalPropertiesMonitoringResponse,
  IDigitalProperty,
  IDigitalPropertyDetails,
  IDigitalPropertyLatestScanResults,
  IDigitalPropertyMonitoring,
  IDigitalPropertyMonitoringResponse,
  IDigitalPropertyOverview,
  IQuickMonitoringSetupRequest,
  IValidateBulkUpload,
} from '../../../../shared/interfaces/digital-property.interface';
import { IHttpQueryOptions } from '../../../../shared/interfaces/http.query.interface';
import {
  IGetManualEvaluationsResponse,
  IManualAudit,
  IManualAuditIssueServerResponse,
  IManualEvalCreateRequest,
  IManualEvaluation,
  IManualEvaluationAuditListItem,
  IPopulatedManualAuditIssueServerResponse,
} from '../../../../shared/interfaces/manual-audit.interface';
import { IScanTag, IScanTagRequest, IScanTagServerResponse } from '../../../../shared/interfaces/scan-tag.interface';
import { IScan } from '../../../../shared/interfaces/scan.interface';
import { IGetShortLinkResponse } from '../../../../shared/interfaces/short-link.interface';
import { ITrainingVideo, ITrainingVideosServerResponse } from '../../../../shared/interfaces/training-video.interface';
import { IUploadClient } from '../../../../shared/interfaces/uploads.interface';
import { IWorkspaceRoleClient } from '../../../../shared/interfaces/workspace-role.interface';
import { IGetWorkspaceUsersFilterRequest, IWorkspaceUserInfo } from '../../../../shared/interfaces/workspace-user.interface';
import {
  ICreateWorkspaceClientRequest,
  ICreateWorkspacePlusDpRequest,
  IGetWorkspacesResponse,
  IValidateWorkspaceNameResponse,
  IWorkspace,
  IWorkspaceClient,
  IWorkspaceSummary,
} from '../../../../shared/interfaces/workspace.interface';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { RestBuilder } from '../helpers/rest.builder';
import { IRuleAuditHistoryManualLineItem } from '../../../../shared/interfaces/rule-audit-history.interface';
import {
  IManualAuditFindingServerResponse,
  IManualAuditQuery,
  IManualFindingBulkStatusChangeRequest,
} from '../../../../shared/interfaces/manual-audit-finding.interface';
import { RestService } from '../rest.service';
import { $auditIssueFields } from '../../../../shared/constants/manual-audit-issues';
import { IManualAuditHistoryRequest } from '../../interfaces/manual-evaluation.interface';
import { IGetWorkspaceDashboardResponse } from '../../../../shared/interfaces/ws-dashboard.interface';
import { $digitalProperty } from '../../../../shared/constants/digital-properties';
import { EmptyObject } from '../../../../shared/interfaces/empty-object.interface';
import { IManualEvaluationDataSnapshot } from '../../../../shared/interfaces/manual-evaluation-data-snapshot.interface';
import { IUser } from '../../../../shared/interfaces/user.interface';
import { IAsyncReportResponse, IDashboardReportResponse } from '../../../../shared/interfaces/async-report.interface';
import {
  IDigitalPropertyMetrics,
  IGetDigitalPropertyMetricsListQuery,
  IGetDigitalPropertyMetricsSnapshotsQuery,
  IPaginatedDigitalPropertyMetricsList,
} from '../../../../shared/interfaces/digital-property-metrics.interface';
import { IWorkspaceMetrics } from '../../../../shared/interfaces/workspace-metrics.interface';
import { AiSummaryKey } from '../../../../shared/constants/ai-data-summary';
import { IDataSummaryClient } from '../../../../shared/interfaces/ai-data-summary.interface';
import { IConformanceScoreReport } from '../../../../shared/interfaces/conformance-score.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkspacesRestAPI {
  private restBuilder: RestBuilder;

  constructor(
    private httpClient: HttpClient,
    private restService: RestService,
  ) {
    this.restBuilder = new RestBuilder();
  }

  // GET /workspaces/:workspaceId
  public getWorkspace(id: string): Observable<IWorkspace> {
    const url: string = this.restBuilder.create().workspaces(id).getApiUrl();

    return this.httpClient.get<IWorkspace>(url);
  }

  // GET /admin/workspaces/bulk/download
  public downloadCreateBulkWorkspacesTemplate(): Observable<void> {
    const url: string = this.restBuilder.create().admin().workspaces().bulk().download().getApiUrl();

    return this.restService.download(url);
  }

  // GET /workspaces/:workspaceId/dashboard?toolName=
  public getDashboard(workspaceId: string, toolName: string): Observable<IGetWorkspaceDashboardResponse> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).dashboard().getApiUrl();

    const params = new HttpParams({ fromObject: { [ApiQueryOption.toolName]: toolName } });

    return this.httpClient.get<IGetWorkspaceDashboardResponse>(url, { params });
  }

  // GET /workspaces/:workspaceId/dashboard/metrics?toolName=
  public getWorkspaceMetrics(workspaceId: string, toolName: string): Observable<IWorkspaceMetrics[]> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).dashboard().metrics().getApiUrl();

    const params = new HttpParams({ fromObject: { [ApiQueryOption.toolName]: toolName } });

    return this.httpClient.get<IWorkspaceMetrics[]>(url, { params });
  }

  // GET /workspace/:workspaceId/digital-property/report/download
  public downloadPortfolioReport(workspaceId: string): Observable<IAsyncReportResponse> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty().report().download().getApiUrl();

    return this.httpClient.get<IAsyncReportResponse>(url);
  }

  // POST /workspace/:workspaceId/digital-property/report/download
  public generatePortfolioReport(workspaceId: string): Observable<IAsyncReportResponse> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty().report().download().getApiUrl();

    return this.httpClient.post<IAsyncReportResponse>(url, {});
  }

  // GET /workspace/names
  public validateNameUniqueness(name: string, tenantId: string): Observable<IValidateWorkspaceNameResponse> {
    const url: string = this.restBuilder.create().workspaces().names().getApiUrl();
    const params: HttpParams = new HttpParams({
      fromObject: {
        [ApiQueryOption.name]: name,
        [ApiQueryOption.tenantId]: tenantId,
      },
    });
    return this.httpClient.get<IValidateWorkspaceNameResponse>(url, { params });
  }

  // GET /workspaces
  public getAllUserWorkspaces(queryOptions: IHttpQueryOptions): Observable<IGetWorkspacesResponse> {
    const url: string = this.restBuilder.create().workspaces().getApiUrl();

    const query: { [key: string]: string } = {
      [ApiQueryOption.filterByUserId]: queryOptions[ApiQueryOption.filterByUserId],
      [ApiQueryOption.limit]: String(queryOptions[ApiQueryOption.limit]),
      [ApiQueryOption.skip]: String(queryOptions[ApiQueryOption.skip]),
    };

    if (typeof queryOptions[ApiQueryOption.term] === 'string') {
      query[ApiQueryOption.term] = window.encodeURIComponent(String(queryOptions[ApiQueryOption.term])).trim();
    }

    if (typeof queryOptions[ApiQueryOption.sortByName] === 'string') {
      query[ApiQueryOption.sortByName] = window.encodeURIComponent(String(queryOptions[ApiQueryOption.sortByName])).trim();
    }

    if (typeof queryOptions[ApiQueryOption.status] === 'string' && queryOptions[ApiQueryOption.status] !== $sortingOrder.all) {
      query[ApiQueryOption.status] = window.encodeURIComponent(String(queryOptions[ApiQueryOption.status])).trim();
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: pickBy(query, SharedCommonUtility.notNullish),
    });

    return this.httpClient.get<IGetWorkspacesResponse>(url, { params: httpParams });
  }

  // POST /admin/workspaces
  public createWorkspacePlusDp(data: ICreateWorkspacePlusDpRequest): Observable<IWorkspace> {
    const url: string = this.restBuilder.create().admin().workspaces().getApiUrl();

    return this.httpClient.post<IWorkspace>(url, data);
  }

  // POST /admin/workspaces
  public createWorkspace(data: ICreateWorkspaceClientRequest): Observable<IWorkspace> {
    const url: string = this.restBuilder.create().admin().workspaces().getApiUrl();

    return this.httpClient.post<IWorkspace>(url, data);
  }

  // PUT /workspaces/:workspaceId
  public updateWorkspace(workspaceId: string, formData: any): Observable<IWorkspace> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).getApiUrl();

    return this.httpClient.put<IWorkspace>(url, formData);
  }

  // PUT /admin/workspaces/:workspaceId
  public updateWorkspaceAsAdmin(workspaceId: string, formData: any): Observable<IWorkspace> {
    const url: string = this.restBuilder.create().admin().workspaces(workspaceId).getApiUrl();

    return this.httpClient.put<IWorkspace>(url, formData);
  }

  // DELETE /admin/workspaces/:workspaceId
  public removeWorkspace(id: string): Observable<void> {
    const url: string = this.restBuilder.create().admin().workspaces(id).getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // GET /workspaces/export
  public exportWorkspaces(queryParams: IHttpQueryOptions): Observable<void> {
    const url: string = this.restBuilder.create().workspaces().export().getApiUrl();

    return this.restService.download(url);
  }

  // GET /workspaces/[workspace id]/users
  public getUsers(workspaceId: string, filters?: IGetWorkspaceUsersFilterRequest): Observable<IUser[]> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).users().getApiUrl();

    const { status, includeStaffUsers } = filters || {};

    let params: HttpParams = new HttpParams();
    if (SharedCommonUtility.notNullishOrEmpty(status)) {
      params = params.set(ApiQueryOption.status, Array.isArray(status) ? status.join(',') : status);
    }

    if (SharedCommonUtility.notNullish(includeStaffUsers)) {
      params = params.set(ApiQueryOption.includeStaffUsers, includeStaffUsers);
    }
    return this.httpClient.get<IUser[]>(url, { params });
  }

  // GET /workspaces/[workspace id]/workspace-users
  public getWorkspaceUsers(workspaceId: string, filters: IGetWorkspaceUsersFilterRequest = {}): Observable<IWorkspaceUserInfo[]> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).workspaceUsers().getApiUrl();

    let params: HttpParams = new HttpParams();
    for (const [field, value] of Object.entries(filters)) {
      params = params.set(field, value);
    }

    return this.httpClient.get<IWorkspaceUserInfo[]>(url, { params });
  }

  // GET /workspaces/[workspace id]/user/[user id]/roles
  public getUserWorkspaceRoles(
    workspaceId: string,
    userId: string,
    isAdminRequest: boolean = false,
  ): Observable<IWorkspaceRoleClient[]> {
    const urlBuilder: RestBuilder = isAdminRequest ? this.restBuilder.create().admin() : this.restBuilder.create();
    const url: string = urlBuilder.workspaces(workspaceId).user(userId).roles().getApiUrl();

    return this.httpClient.get<IWorkspaceRoleClient[]>(url);
  }

  // POST /admin/workspaces/:workspaceId/training-videos
  public workspaceAddTrainingVideo(workspaceId: string, data: ITrainingVideo): Observable<ITrainingVideo> {
    const url: string = this.restBuilder.create().admin().workspaces(workspaceId).training().videos().getApiUrl();

    return this.httpClient.post<ITrainingVideo>(url, data);
  }

  // PUT /admin/workspaces/:workspaceId/training-videos/:videoId
  public workspaceUpdateTrainingVideo(workspaceId: string, videoId: string, data: ITrainingVideo): Observable<ITrainingVideo> {
    const url: string = this.restBuilder.create().admin().workspaces(workspaceId).training().videos(videoId).getApiUrl();

    return this.httpClient.put<ITrainingVideo>(url, data);
  }

  // DELETE /admin/workspaces/:workspaceId/training-videos/:videoId
  public workspaceDeleteTrainingVideo(workspaceId: string, videoId: string): Observable<EmptyObject> {
    const url: string = this.restBuilder.create().admin().workspaces(workspaceId).training().videos(videoId).getApiUrl();

    return this.httpClient.delete<EmptyObject>(url);
  }

  // Workspace Digital Properties Admin
  // GET /admin/workspaces/:workspaceId/digital-properties
  public getAllDigitalPropertiesByWorkspaceId(workspaceId: string): Observable<IDigitalProperty[]> {
    const digitalProperyBuilder: RestBuilder = this.restBuilder.create().admin().workspaces(workspaceId).digitalProperties();

    return this.httpClient.get<IDigitalProperty[]>(digitalProperyBuilder.getApiUrl());
  }

  // GET /workspaces/:workspaceId/training-videos/:videoId
  public workspaceGetTrainingVideo(workspaceId: string, videoId: string): Observable<ITrainingVideo> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).training().videos(videoId).getApiUrl();

    return this.httpClient.get<ITrainingVideo>(url);
  }

  // GET /workspaces/:workspaceId/training-videos
  public workspaceGetTrainingVideos(
    workspaceId: string,
    queryParams: IHttpQueryOptions,
    limit: number,
    skip: number = 0,
  ): Observable<ITrainingVideosServerResponse> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).training().videos().getApiUrl();

    const query: { [key: string]: string } = {};

    if (typeof limit === 'number') {
      query[ApiQueryOption.limit] = String(limit);
    }

    if (typeof skip === 'number') {
      query[ApiQueryOption.skip] = String(skip);
    }

    if (typeof queryParams[ApiQueryOption.sortCreatedAt] === 'string') {
      query[ApiQueryOption.sortCreatedAt] = String(queryParams[ApiQueryOption.sortCreatedAt]);
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: query,
    });

    return this.httpClient.get<ITrainingVideosServerResponse>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/digital-property/:id
  public getDigitalProperty(workspaceId: string, digitalPropertyId: string): Observable<IDigitalPropertyDetails> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty(digitalPropertyId).getApiUrl();

    return this.httpClient.get<IDigitalPropertyDetails>(url);
  }

  // GET /workspaces/:workspaceId/digital-property/:id/metrics
  public getMetricsForDigitalProperty(workspaceId: string, digitalPropertyId: string): Observable<IDigitalPropertyMetrics> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .metrics()
      .getApiUrl();

    return this.httpClient.get<IDigitalPropertyMetrics>(url);
  }

  // GET /workspaces/:workspaceId/digital-property-metrics
  public getDigitalPropertyMetricsList(
    workspaceId: string,
    params: IGetDigitalPropertyMetricsListQuery,
  ): Observable<IPaginatedDigitalPropertyMetricsList> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalPropertyMetrics().getApiUrl();

    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.httpClient.get<IPaginatedDigitalPropertyMetricsList>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/digital-property-metrics/snapshots
  public getDigitalPropertyMetricsSnapshots(
    workspaceId: string,
    params: IGetDigitalPropertyMetricsSnapshotsQuery,
  ): Observable<IPaginatedDigitalPropertyMetricsList> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalPropertyMetrics().snapshots().getApiUrl();
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });
    return this.httpClient.get<IPaginatedDigitalPropertyMetricsList>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations
  public getAllManualEvaluations(workspaceId: string, digitalPropertyId: string): Observable<IScan[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations()
      .getApiUrl();

    return this.httpClient.get<IScan[]>(url);
  }

  // GET /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:scanId/manual-results
  public getManualEvaluationIssues(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    params: IManualAuditQuery,
  ): Observable<IManualAuditFindingServerResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults()
      .getApiUrl();

    if (typeof params[$auditIssueFields.issueId] === 'string') {
      params[$auditIssueFields.issueId] = window.encodeURIComponent(params[$auditIssueFields.issueId]);
    }

    if (typeof params[$auditIssueFields.summary] === 'string') {
      params[$auditIssueFields.summary] = window.encodeURIComponent(params[$auditIssueFields.summary]);
    }

    if (typeof params[$auditIssueFields.screen] === 'string') {
      params[$auditIssueFields.screen] = window.encodeURIComponent(params[$auditIssueFields.screen]);
    }

    const fetch = (lastId?: string): Observable<IManualAuditFindingServerResponse> =>
      this.httpClient.get<IManualAuditFindingServerResponse>(url, {
        params: new HttpParams({ fromObject: lastId ? { ...params, lastId: lastId } : { ...params } }),
      });

    return fetch().pipe(
      expand((v: IManualAuditFindingServerResponse) => (v.lastId ? fetch(v.lastId) : EMPTY)),
      reduce(
        (prev: IManualAuditFindingServerResponse, data: IManualAuditFindingServerResponse): IManualAuditFindingServerResponse => {
          return { ...data, findings: [...(prev?.findings || []), ...data.findings] };
        },
      ),
    );
  }

  public getManualRuleCategories(workspaceId: string, digitalPropertyId: string, scanId: string): Observable<string[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .categories()
      .getApiUrl();

    return this.httpClient.get<string[]>(url);
  }

  // PUT /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:scanId
  public updateManualEvaluationTitleById(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    title: string,
  ): Observable<IScan> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .getApiUrl();

    const body = {
      _id: scanId,
      title: title,
    };

    return this.httpClient.put<IScan>(url, body);
  }

  // GET /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:scanId/manual-audit-top-wcag
  public getManualEvaluationTopWcag(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
  ): Observable<TopWcagKeyNumPair[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualAuditTopWcag()
      .getApiUrl();

    return this.httpClient.get<TopWcagKeyNumPair[]>(url);
  }

  // GET /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:scanId/manual-audit-issues-by-severity
  public getManualEvaluationIssuesBySeverity(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
  ): Observable<IDashboardManualEvaluationsBySeverity> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualAuditIssuesBySeverity()
      .getApiUrl();

    return this.httpClient.get<IDashboardManualEvaluationsBySeverity>(url);
  }

  // POST /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations
  public createEmptyManualEvaluation(
    workspaceId: string,
    digitalPropertyId: string,
    request: IManualEvalCreateRequest,
  ): Observable<IManualAudit> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations()
      .getApiUrl();

    return this.httpClient.post<IManualAudit>(url, request);
  }

  // GET /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/:manualAuditIssueId
  public getManualEvaluationIssue(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    manualAuditIssueId: string,
  ): Observable<IPopulatedManualAuditIssueServerResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults(manualAuditIssueId)
      .getApiUrl();

    return this.httpClient.get<IPopulatedManualAuditIssueServerResponse>(url);
  }

  // DELETE /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/:manualAuditIssueId
  public deleteManualEvaluationIssue(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    manualAuditIssueId: string,
  ): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults(manualAuditIssueId)
      .getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // GET /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/:manualAuditIssueId/rule-history
  public getManualEvaluationIssueRuleAuditHistory(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditHistoryRequest: IManualAuditHistoryRequest,
  ): Observable<IRuleAuditHistoryManualLineItem[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(manualAuditHistoryRequest.scanId)
      .manualResults(manualAuditHistoryRequest.issueId)
      .ruleHistorySearch()
      .getApiUrl();

    return this.httpClient.post<IRuleAuditHistoryManualLineItem[]>(url, { filter: manualAuditHistoryRequest.filter });
  }

  // GET /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/:manualAuditIssueId/has-history-updates
  public getManualEvaluationIssueHasHistory(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditHistoryRequest: IManualAuditHistoryRequest,
  ): Observable<boolean> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(manualAuditHistoryRequest.scanId)
      .manualResults(manualAuditHistoryRequest.issueId)
      .hasHistory()
      .getApiUrl();

    return this.httpClient.post<boolean>(url, { filter: manualAuditHistoryRequest.filter });
  }

  // GET /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/download
  public downloadManualEvaluationIssues(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    selectedFields: string[],
    formatColumns: string,
    params: IManualAuditQuery,
  ): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults()
      .download()
      .getApiUrl();

    return this.restService.download(url, {
      fields: selectedFields.join(','),
      formatColumns: formatColumns,
      ...(params as Record<string, string>),
    });
  }

  // PUT /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/:manualAuditIssueId/status
  public updateQAIssueStatus(
    body: FormData,
    workspaceId: string,
    scanId: string,
    digitalPropertyId: string,
    manualAuditIssueId: string,
  ): Observable<IManualAuditIssueServerResponse[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults(manualAuditIssueId)
      .status()
      .getApiUrl();
    return this.httpClient.put<IManualAuditIssueServerResponse[]>(url, body);
  }

  // PUT /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:scanId/status
  public bulkUpdateFindingStatuses(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    request: IManualFindingBulkStatusChangeRequest,
  ): Observable<IManualAuditIssueServerResponse[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .status()
      .getApiUrl();
    return this.httpClient.put<IManualAuditIssueServerResponse[]>(url, request);
  }

  // PUT /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/:manualAuditIssueId/dismiss
  public dismissQAIssue(
    body: FormData,
    workspaceId: string,
    scanId: string,
    digitalPropertyId: string,
    manualAuditIssueId: string,
  ): Observable<IAuditFinding> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults(manualAuditIssueId)
      .dismiss()
      .getApiUrl();
    return this.httpClient.put<IAuditFinding>(url, body);
  }

  // PUT /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/dismiss
  public dismissQAIssues(
    body: FormData,
    workspaceId: string,
    scanId: string,
    digitalPropertyId: string,
  ): Observable<IAuditFinding[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .dismiss()
      .getApiUrl();
    return this.httpClient.put<IAuditFinding[]>(url, body);
  }

  public restoreQAIssue(
    body: FormData,
    workspaceId: string,
    scanId: string,
    digitalPropertyId: string,
    manualAuditIssueId: string,
  ): Observable<IAuditFinding> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults(manualAuditIssueId)
      .restore()
      .getApiUrl();
    return this.httpClient.put<IAuditFinding>(url, body);
  }

  // PUT /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/:manualAuditIssueId
  public updateQAIssue(
    workspaceId: string,
    scanId: string,
    digitalPropertyId: string,
    manualAuditIssueId: string,
    issue: FormData,
  ): Observable<IAuditFinding> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults(manualAuditIssueId)
      .getApiUrl();
    return this.httpClient.put<IAuditFinding>(url, issue);
  }

  // POST /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/:manualAuditIssueId/attachments
  public uploadManualEvaluationIssueAttachment(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    manualAuditIssueId: string,
    attachment: FormData,
  ): Observable<IUploadClient[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults(manualAuditIssueId)
      .attachments()
      .getApiUrl();

    return this.httpClient.post<IUploadClient[]>(url, attachment);
  }

  // PUT /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/:manualAuditIssueId/attachments/:attachmentId
  public updateManualEvaluationIssueAttachment(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    manualAuditIssueId: string,
    attachmentId: string,
    update: FormData,
  ): Observable<IUploadClient> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults(manualAuditIssueId)
      .attachments(attachmentId)
      .getApiUrl();

    return this.httpClient.put<IUploadClient>(url, update);
  }

  // DELETE /workspaces/:workspaceId/digitalProperty/:digitalPropertyId/manual-evaluations/:manual-evaluations-id/manual-audit-issues/:manualAuditIssueId/attachments/:attachmentId
  public deleteManualEvaluationIssueAttachment(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    manualAuditIssueId: string,
    attachmentId: string,
  ): Observable<{ _id: string }> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .manualResults(manualAuditIssueId)
      .attachments(attachmentId)
      .getApiUrl();

    return this.httpClient.delete<{ _id: string }>(url);
  }

  // GET /workspaces/:workspaceId/digital-property
  public getWorkspaceDigitalProperties(
    workspaceId: string,
    limit: number,
    skip: number,
    populateRemediationProgress: boolean = true,
    populateMonitoring: boolean = true,
  ): Observable<IDigitalPropertiesMonitoringResponse> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty().getApiUrl();

    const params: { [param: string]: string | readonly string[] } = {};

    if (typeof limit === 'number') {
      params.limit = String(limit);
    }

    if (typeof skip === 'number') {
      params.skip = String(skip);
    }

    params[Api.populateRemediationProgress] = String(populateRemediationProgress);
    params[Api.populateMonitoring] = String(populateMonitoring);

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IDigitalPropertiesMonitoringResponse>(url, { params: httpParams });
  }

  // POST /workspaces/:workspacesId/digital-property
  public createWorkspaceDigitalProperty(
    workspaceId: string,
    formData: ICreateDigitalPropertyRequest,
  ): Observable<IDigitalProperty> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty().getApiUrl();

    return this.httpClient.post<IDigitalProperty>(url, formData);
  }

  // POST /workspaces/:workspacesId/digital-property/validate
  public validateDigitalPropertyBulkUpload(workspaceId: string, fileForm: FormData): Observable<IValidateBulkUpload> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty().validate().getApiUrl();

    return this.httpClient.post<IValidateBulkUpload>(url, fileForm);
  }

  // GET /workspaces/:workspacesId/digital-property/bulk
  public downloadDigitalPropertyTemplate(workspaceId: string): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty().bulk().download().getApiUrl();

    return this.restService.download(url);
  }

  // POST /workspaces/:workspacesId/digital-property/bulk
  public createBulkDigitalProperty(workspaceId: string, fileForm: FormData): Observable<number> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty().bulk().getApiUrl();

    return this.httpClient.post<number>(url, fileForm);
  }

  // PUT /workspaces/:workspaceId/digital-property/:id
  public updateDigitalProperty(formData: IDigitalProperty, id: string): Observable<IDigitalPropertyDetails> {
    const url: string = this.restBuilder
      .create()
      .workspaces(formData[$digitalProperty.workspaceId])
      .digitalProperty(id)
      .getApiUrl();

    return this.httpClient.put<IDigitalPropertyDetails>(url, formData);
  }

  // DELETE /workspaces/:workspaceId/digital-property/:id
  public removeDigitalProperty(id: string, workspaceId: string): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty(id).getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // POST /workspaces/:workspaceId/digital-property/:digitalPropertyId/manualEvaluations/:evaluationScanId/manualAuditIssue/:manualAuditIssueId/comments
  public postManualAuditIssueComment(
    formData: FormData,
    workspaceId: string,
    digitalPropertyId: string,
    evaluationScanId: string,
    manualIssueId: string,
  ): Observable<IComment> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(evaluationScanId)
      .manualResults(manualIssueId)
      .comments()
      .getApiUrl();
    return this.httpClient.post<IComment>(url, formData);
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/manual-evaluations/:scanId
  public getManualEvaluation(workspaceId: string, digitalPropertyId: string, scanId: string): Observable<IManualEvaluation> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .getApiUrl();

    return this.httpClient.get<IManualEvaluation>(url);
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/manual-evaluations
  public getManualEvaluations(
    workspaceId: string,
    digitalPropertyId: string,
    params: { searchTerm: string },
  ): Observable<IGetManualEvaluationsResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations()
      .getApiUrl();

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IGetManualEvaluationsResponse>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/manualEvaluations/:evaluationId/manualAuditIssue/:manualAuditIssueId/comments
  public getManualAuditIssueComments(
    evaluationId: string,
    issueId: string,
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IManualEvaluationAuditListItem[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(evaluationId)
      .manualResults(issueId)
      .comments()
      .getApiUrl();
    return this.httpClient.get<IManualEvaluationAuditListItem[]>(url);
  }

  // DELETE /workspaces/:workspaceId/digital-property/:digitalPropertyId/manualEvaluations/:evaluationId/manualAuditIssue/:manualAuditIssueId/comments/:commentId
  public deleteManualAuditIssueComment(
    workspaceId: string,
    digitalPropertyId: string,
    evaluationScanId: string,
    issueId: string,
    commentId: string,
  ): Observable<IComment> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(evaluationScanId)
      .manualResults(issueId)
      .comments(commentId)
      .getApiUrl();

    return this.httpClient.put<IComment>(url, null);
  }

  public getDigitalPropertyMonitoring(
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IDigitalPropertyMonitoringResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .monitoring()
      .getApiUrl();

    return this.httpClient.get<IDigitalPropertyMonitoringResponse>(url);
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/monitoring/latest-scan
  public getDigitalPropertyLatestAndPreviousMonitoringScanResults(
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IDigitalPropertyLatestScanResults> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .monitoring()
      .latestScan()
      .getApiUrl();

    return this.httpClient.get<IDigitalPropertyLatestScanResults>(url);
  }

  public setDigitalPropertyQuickMonitoring(
    workspaceId: string,
    digitalPropertyId: string,
    monitoring: IQuickMonitoringSetupRequest,
  ): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .quickMonitoring()
      .getApiUrl();

    return this.httpClient.put<void>(url, monitoring);
  }

  // PUT /workspaces/:workspaceId/digital-property/:digitalPropertyId/monitoring
  public updateDigitalPropertyMonitoring(
    workspaceId: string,
    digitalPropertyId: string,
    monitoring: IDigitalPropertyMonitoring,
  ): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .monitoring()
      .getApiUrl();

    return this.httpClient.put<void>(url, monitoring);
  }

  public removeDigitalPropertyMonitoring(workspaceId: string, digitalPropertyId: string): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .monitoring()
      .getApiUrl();

    return this.httpClient.put<void>(url, null);
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/scan-tags
  public getScanTagsFromDigitalProperty(workspaceId: string, digitalPropertyId: string): Observable<IScanTagServerResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .scanTags()
      .getApiUrl();
    return this.httpClient.get<IScanTagServerResponse>(url);
  }

  // POST /workspaces/:workspaceId/digital-property/:digitalPropertyId/scan-tags
  public createScanTag(workspaceId: string, digitalPropertyId: string, scanTag: IScanTagRequest): Observable<IScanTag> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .scanTags()
      .getApiUrl();

    return this.httpClient.post<IScanTag>(url, scanTag);
  }

  public deleteScanTag(workspaceId: string, digitalPropertyId: string, scanTagId: string): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .scanTags(scanTagId)
      .getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/scan-tags/:scanTagId
  public getScanTag(workspaceId: string, digitalPropertyId: string, scanTagId: string): Observable<IScanTag> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .scanTags(scanTagId)
      .getApiUrl();

    return this.httpClient.get<IScanTag>(url);
  }

  // PUT /workspaces/:workspaceId/digital-property/:digitalPropertyId/scan-tags/:scanTagId
  public updateScanTag(
    workspaceId: string,
    digitalPropertyId: string,
    scanTagId: string,
    scanTag: IScanTagRequest,
  ): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .scanTags(scanTagId)
      .getApiUrl();

    return this.httpClient.put<void>(url, scanTag);
  }

  // PUT /workspaces/:workspaceId/digital-property/:digitalPropertyId/archive
  public archiveProperty(workspaceId: string, digitalPropertyId: string): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .archive()
      .getApiUrl();

    return this.httpClient.put<void>(url, {});
  }

  public restoreProperty(workspaceId: string, digitalPropertyId: string): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .restore()
      .getApiUrl();

    return this.httpClient.put<void>(url, {});
  }

  // GET /workspaces/active
  public getActiveWorkspaces(): Observable<IWorkspaceClient[]> {
    const url: string = this.restBuilder.create().workspaces().active().getApiUrl();

    return this.httpClient.get<IWorkspaceClient[]>(url);
  }

  // GET /workspaces/active/new-security
  public getActiveWorkspacesV2(): Observable<IWorkspaceClient[]> {
    const url: string = this.restBuilder.create().workspaces().active().newSecurity().getApiUrl();

    return this.httpClient.get<IWorkspaceClient[]>(url);
  }

  // GET /workspaces/active/notifications
  public getMyActiveWorkspacesWithNotifications(): Observable<IWorkspaceClient[]> {
    const url: string = this.restBuilder.create().workspaces().active().notifications().getApiUrl();

    return this.httpClient.get<IWorkspaceClient[]>(url);
  }

  // POST /workspaces/:workspaceId/digital-property/is-duplicated
  public existingKeyValues(workspaceId: string, key: keyof IDigitalProperty, propertyId: string): Observable<string[]> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty().existingKeyValues().getApiUrl();

    const params: HttpParams = new HttpParams({
      fromObject: { [key]: '', ...(propertyId ? { _id: propertyId } : {}) },
    });
    return this.httpClient.get<string[]>(url, {
      params: params,
      headers: {
        [ApiHeaderOption.skipLoader]: 'true',
      },
    });
  }

  public getManualAuditIssueShortLink(
    wsId: string,
    dpId: string,
    scanId: string,
    issueId: string,
  ): Observable<IGetShortLinkResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(wsId)
      .digitalProperty(dpId)
      .manualEvaluations(scanId)
      .manualResults()
      .shortLink()
      .pathParam(issueId)
      .getApiUrl();

    return this.httpClient.get<IGetShortLinkResponse>(url);
  }

  // GET /workspaces/:workspaceId/summary
  public getSummary(workspaceId: string): Observable<IWorkspaceSummary> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).summary().getApiUrl();
    return this.httpClient.get<IWorkspaceSummary>(url);
  }

  // GET /workspaces/:workspaceId/dashboard/report?toolName=
  public getWorkspaceReport(workspaceId: string, toolName: string): Observable<IDashboardReportResponse> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).dashboard().report().getApiUrl();

    return this.httpClient.get<IDashboardReportResponse>(url, { params: { [ApiQueryOption.toolName]: toolName } });
  }

  // POST /workspaces/:workspaceId/dashboard/report?toolName=
  public generateWorkspaceReport(workspaceId: string, toolName: string): Observable<IDashboardReportResponse> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).dashboard().report().getApiUrl();
    return this.httpClient.post<IDashboardReportResponse>(url, {}, { params: { [ApiQueryOption.toolName]: toolName } });
  }

  public getDigitalPropertyOverview(workspaceId: string, dpId: string): Observable<IDigitalPropertyOverview> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).digitalProperty(dpId).overviewData().getApiUrl();

    return this.httpClient.get<IDigitalPropertyOverview>(url);
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/manual-evaluations/:scanId/data-snapshots
  public getManualEvaluationDataSnapshots(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    limit: number,
  ): Observable<IManualEvaluationDataSnapshot[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(scanId)
      .dataSnapshots()
      .getApiUrl();

    const httpParams: HttpParams = new HttpParams({
      fromObject: {
        limit,
      },
    });

    return this.httpClient.get<IManualEvaluationDataSnapshot[]>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/manual-evaluations/:scanId/ai/summary/:summaryCode
  public getManualEvaluationAuditSummary(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditId: string,
    summaryCode: AiSummaryKey,
  ): Observable<IDataSummaryClient> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(manualAuditId)
      .ai()
      .dataSummary(summaryCode)
      .getApiUrl();

    return this.httpClient.get<IDataSummaryClient>(url, {
      headers: {
        [ApiHeaderOption.skipLoader]: 'true',
      },
    });
  }

  // PUT /workspaces/:workspaceId/digital-properties/:digitalPropertyId/manual-evaluations/:scanId/ai/summary/rating
  public rateManualEvaluationAiSummary(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditId: string,
    summaryId: string,
    rating: number,
  ): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(manualAuditId)
      .ai()
      .dataSummary()
      .rating()
      .getApiUrl();

    return this.httpClient.put<void>(url, { summaryId, rating });
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/manual-evaluations/:scanId/conformance-score-report
  public getManualEvaluationConformanceScoreReport(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditId: string,
  ): Observable<IConformanceScoreReport> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .manualEvaluations(manualAuditId)
      .conformanceScoreReport()
      .getApiUrl();

    return this.httpClient.get<IConformanceScoreReport>(url);
  }
}
