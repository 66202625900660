import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';

import { $workspace } from '../../../../../../../shared/constants/workspace';
import { IWorkspaceClient } from '../../../../../../../shared/interfaces/workspace.interface';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../../../../shared/utils/linked-property.utility';
import { INavMenuItem, NavigationItem } from '../../../../../services/navigation/nav-structure';
import { Api } from '../../../../../../../shared/constants/api';
import { LoadErrorHandler } from '../../../../../services/load-error-handler';
import { WorkspaceService } from '../../../../../services/workspace.service';
import { NavService } from '../../../../../services/navigation/nav.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { UserService } from '../../../../../services/user.service';

@Component({
  selector: 'app-workspaces-select',
  templateUrl: './workspaces-select.component.html',
  styleUrls: ['./workspaces-select.component.scss'],
})
export class WorkspacesSelectComponent implements OnInit {
  protected readonly $workspace: typeof $workspace = $workspace;
  private _workspacesMenuItem: INavMenuItem;
  private _subscription: Subscription;

  public workspaces: IWorkspaceClient[];
  public selectedWorkspaceId: string | undefined;

  @Output() public workspaceChanged: EventEmitter<void> = new EventEmitter();

  constructor(
    private loadErrorHandler: LoadErrorHandler,
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private navService: NavService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
  ) {
    this._subscription = new Subscription();
  }

  public getWorkSpaces(): void {
    const onGetWorkspacesError = (error: HttpErrorResponse): Observable<IWorkspaceClient[]> => {
      this.loadErrorHandler.handleError('error_while_getting_workspaces', error, WorkspacesSelectComponent.name);
      return EMPTY;
    };

    const onAuthenticationChange = (status: boolean): void => {
      if (status) {
        this._subscription.add(
          this.workspaceService
            .getActiveWorkspacesV2()
            .pipe(catchError(onGetWorkspacesError.bind(this)))
            .subscribe((workspaces: IWorkspaceClient[]): void => {
              this.workspaces = workspaces;
            }),
        );
      }
    };

    this._subscription.add(this.userService.isAuthenticated$.subscribe(onAuthenticationChange.bind(this)));
  }

  public getSelectedWorkspaceId(): void {
    const urlSelectedWorkspace$: Observable<ILinkedPropertyData> = this.activatedRoute.queryParams.pipe(
      map(LinkedPropertyUtility.fromLinkedPropertyQueryParam),
    );

    this._subscription.add(
      urlSelectedWorkspace$
        .pipe(
          map((linkedPropertyData: ILinkedPropertyData): string | undefined => {
            return linkedPropertyData?.[Api.workspaceId] ?? undefined;
          }),
        )
        .subscribe((workspaceId: string | undefined): void => {
          this.selectedWorkspaceId = workspaceId;
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  public selectWorkspace(selectedWorkspaceId: string): void {
    this.selectedWorkspaceId = selectedWorkspaceId;

    const workspace: IWorkspaceClient = this.workspaces.find(
      (workspaceClient: IWorkspaceClient): boolean => workspaceClient[$workspace._id] === selectedWorkspaceId,
    );

    const extras: NavigationExtras = {
      queryParams: this.getLinkedPropertyDataQueryParam(workspace),
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    };

    this.router
      .navigate([this._workspacesMenuItem.routerLink], extras)
      .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

    this.workspaceChanged.emit();
  }

  public getLinkedPropertyDataQueryParam(workspace: IWorkspaceClient): Params {
    return LinkedPropertyUtility.getLinkedPropertyQueryParam(
      workspace[$workspace.digitalProperties]?.[0]?._id,
      workspace[$workspace._id],
    );
  }

  ngOnInit(): void {
    this._subscription.add(
      this.navService
        .getTopLevelMenuItems$([NavigationItem.workspace])
        .pipe(map((menuItems: INavMenuItem[]): INavMenuItem => menuItems?.[0]))
        .subscribe((workspaceMenuItem: INavMenuItem): void => {
          this._workspacesMenuItem = workspaceMenuItem;
        }),
    );

    this.getSelectedWorkspaceId();
    this.getWorkSpaces();
  }
}
