// TODO: REMOVE IT after implementation new ACL
export enum AclRoles {
  admin = 'admin',
  auditor = 'auditor',
  superAdmin = 'superAdmin',
  userAdmin = 'userAdmin',
  user = 'user',
}

export const adminRoles: Set<AclRoles> = new Set<AclRoles>([AclRoles.admin, AclRoles.superAdmin, AclRoles.userAdmin]);

export const AclUserActions: Readonly<{ [key: string]: string }> = Object.freeze({
  delete: 'Delete',
  approve: 'Approve',
  disapprove: 'DisApprove',
});

export enum AclScope {
  adminPanel = 'adminPanel',
  description = 'description',
  name = 'name',
  permissions = 'actions',
  publicSite = 'publicSite',
  workspacesRoles = 'workspacesRoles',
}

export enum formRequestAction {
  Create = 'Create',
  Remove = 'Remove',
  Update = 'Update',
}

/** @deprecated: old security */
export enum $userRole {
  createdAt = 'createdAt',
  description = 'description',
  entriesPerPage = 'entriesPerPage',
  isSelected = 'isSelected',
  name = 'name',
  permissions = 'permissions',
  roleOrigin = 'roleOrigin',
  section = 'section',
  updatedAt = 'updatedAt',
  workspace = 'workspace',
}

export enum AclSection {
  Admin = 'Admin',
  Documentation = 'Documentation',
  Documents = 'Documents',
  RunScan = 'RunScan',
  Scans = 'Scans',
  Settings = 'Settings',
  Support = 'Support',
}

export enum AclPermissions {
  Admin_ActiveScans_All = 'Admin_ActiveScans_All',
  Admin_DigitalProperties_All = 'Admin_DigitalProperties_All',
  Admin_ManualAudits_All = 'Admin_ManualAudits_All',
  Admin_Logs_All = 'Admin_Logs_All',
  Admin_Translations_All = 'Admin_Translations_All',
  Admin_UsersGroup_All = 'Admin_UsersGroup_All',
  Admin_Users_All = 'Admin_Users_All',
  Admin_Tenants_All = 'Admin_Tenants_All',
  Admin_Workspaces_All = 'Admin_Workspaces_All',
  Admin_Workspaces_Impersonate = 'Admin_Workspaces_Impersonate',
  Admin_WorkspaceUsers_Read = 'Admin_WorkspaceUsers_Read',
  Admin_WorkspaceUsers_Write = 'Admin_WorkspaceUsers_Write',
  Admin_Workspaces_Read = 'Admin_Workspaces_Read',
  Admin_Workspace_Add_Staff = 'Admin_Workspace_Add_Staff',
  Admin_DigitalPropertyTypes_Manage = 'Admin_DigitalPropertyTypes_Manage',
  /**
   * @deprecated Private workspaces were removed completely in EAP-5166
   */
  Admin_CanUsePrivateWorkspace = 'Admin_CanUsePrivateWorkspace',
  Admin_CanAssignAdminRoles = 'Admin_CanAssignAdminRoles',
  Admin_CanAssignSuperAdminRoles = 'Admin_CanAssignSuperAdminRoles',
  Admin_CanAssignUserAdminRoles = 'Admin_CanAssignUserAdminRoles',
  Admin_EditTrainingVideos = 'Admin_EditTrainingVideos',
  Admin_Export_Users = 'Admin_Export_Users',
  Admin_Scannable_Document_All = 'Admin_Scannable_Document_All',
  Audits_Read = 'Audits_Read',
  Audit_Rule_View = 'Audit_Rule_View',
  DigitalProperty_EditName = 'DigitalProperty_EditName',
  DigitalProperty_EditTool = 'DigitalProperty_EditTool',
  DigitalProperty_EditConformance = 'DigitalProperty_EditConformance',
  Document_Create = 'Document_Create',
  Document_Edit = 'Document_Edit',
  Document_View = 'Document_View',
  Documentation_All = 'Documentation_All',
  Manual_Audit_Issue_Create_Comment = 'Manual_Audit_Issue_Create_Comment',
  Manual_Audit_Issue_View_Comment = 'Manual_Audit_Issue_View_Comment',
  Manual_Audit_Issue_Delete_Comment = 'Manual_Audit_Issue_Delete_Comment',
  Manual_Audit_Issue_Create_Attachment = 'Manual_Audit_Issue_Create_Attachment',
  Manual_Audit_Issue_Delete_Attachment = 'Manual_Audit_Issue_Delete_Attachment',
  Master_Library_All = 'Master_Library_All',
  Master_Library_DeleteRule = 'Master_Library_DeleteRule',
  Master_Library_EditRule = 'Master_Library_EditRule',
  Match_Component_Create = 'Match_Component_Create',
  Match_Component_Edit = 'Match_Component_Edit',
  Match_Component_View = 'Match_Component_View',
  Match_Component_Archive = 'Match_Component_Archive',
  Match_Component_All = 'Match_Component_All',
  Monitoring_Write = 'Monitoring_Write',
  Monitoring_View = 'Monitoring_View',
  Monitoring_Scan_Delete = 'Monitoring_Scan_Delete',
  Issues_All = 'Issues_All',
  Project_All = 'Project_All',
  Issue_Tracking_All = 'Issue_Tracking_All',
  Issue_Tracking_Create_Connection = 'Issue_Tracking_Create_Connection',
  Issue_Tracking_Delete_Connection = 'Issue_Tracking_Delete_Connection',
  Issue_Tracking_Configure_Fields = 'Issue_Tracking_Configure_Fields',
  Issue_Tracking_Configure_JSON_Fields = 'Issue_Tracking_Configure_JSON_Fields',
  Project_Archive = 'Project_Archive',
  Project_Delete = 'Project_Delete',
  Scannable_Document_Create = 'Scannable_Document_Create',
  Scannable_Document_View = 'Scannable_Document_View',
  Scannable_Document_Run_Scan = 'Scannable_Document_Run_Scan',
  Scannable_Document_View_Scan = 'Scannable_Document_View_Scan',
  Scannable_Document_Set_Remediation = 'Scannable_Document_Set_Remediation',
  Scannable_Document_Request_Remediation = 'Scannable_Document_Request_Remediation',
  Scannable_Document_Upload_Remediation = 'Scannable_Document_Upload_Remediation',
  Scannable_Document_Remove_Remediation = 'Scannable_Document_Remove_Remediation',
  Scannable_Document_Revoke_Remediation = 'Scannable_Document_Revoke_Remediation',
  Scannable_Document_Remove = 'Scannable_Document_Remove',
  RunScan_AllOptions = 'RunScan_AllOptions',
  Scans_All = 'Scans_All',
  Settings_Users_All = 'Settings_Users_All',
  Settings_Account_All = 'Settings_Account_All',
  Settings_DigitalProperties_All = 'Settings_DigitalProperties_All',
  Settings_DigitalProperties_Read = 'Settings_DigitalProperties_Read',
  Settings_ManageRoles_All = 'Settings_ManageRoles_All',
  Settings_ManageRoles_Read = 'Settings_ManageRoles_Read',
  Settings_Profile_All = 'Settings_Profile_All',
  Settings_Workspaces_All = 'Settings_Workspaces_All',
  Support_Send = 'Support_Send',
  Scan_Tag_View = 'Scan_Tag_View',
  Scan_Tag_Create = 'Scan_Tag_Create',
  Scan_Tag_Delete = 'Scan_Tag_Delete',
  Scan_Tag_Update = 'Scan_Tag_Update',
  Property_Archive = 'Property_Archive',
  Task_All = 'Task_All',
  Task_Delete = 'Task_Delete',
  Task_View = 'Task_View',
  Task_Transitions_Admin = 'Task_Transitions_Admin',
  Task_Transitions_Staff = 'Task_Transitions_Staff',
  Tenants_View = 'Tenants_View',
  Toggle_Evaluation_Rule = 'Toggle_Evaluation_Rule',
  User_Flow_All = 'User_Flow_All',
  Manual_Audit_Issue_Status_Update = 'Manual_Audit_Issue_Status_Update',
  Pages_View = 'Pages_View',
  Pages_Edit = 'Pages_Edit',
  Manual_Audit_Issue_Dismissal = 'Manual_Audit_Issue_Dismissal',
  UserFlowStates_Create = 'UserFlowStates_Create',
  Manual_Evaluation_To_Review = 'Manual_Evaluation_To_Review',
  Design_Rule_All = 'Design_Rule_All',
  Design_Rule_View = 'Design_Rule_View',
  Design_Rule_Edit = 'Design_Rule_Edit',
  Design_Review_Project_All = 'Design_Review_Project_All',
  Design_Review_Project_View = 'Design_Review_Project_View',
  Design_Review_Project_Create = 'Design_Review_Project_Create',
  Design_Review_Project_Edit = 'Design_Review_Project_Edit',
  Design_Review_Page_All = 'Design_Review_Page_All',
  Design_Review_Page_View = 'Design_Review_Page_View',
  Design_Review_Page_Create = 'Design_Review_Page_Create',
  Design_Review_Page_Edit = 'Design_Review_Page_Edit',
  Design_Review_Finding_All = 'Design_Review_Finding_All',
  Design_Review_Finding_View = 'Design_Review_Finding_View',
  Design_Review_Finding_Create = 'Design_Review_Finding_Create',
  Design_Review_Finding_Edit = 'Design_Review_Finding_Edit',
  Design_Review_Finding_Comment_All = 'Design_Review_Finding_Comment_All',
  Design_Review_Finding_Comment_View = 'Design_Review_Finding_Comment_View',
  Design_Review_Finding_Comment_Create = 'Design_Review_Finding_Comment_Create',
  Design_Review_Finding_Comment_Edit = 'Design_Review_Finding_Comment_Edit',
  Manage_Users_Mfa_All = 'Manage_Users_Mfa_All',
  Manage_Users_Mfa_View = 'Manage_Users_Mfa_View',
  Manage_Users_Mfa_Disable = 'Manage_Users_Mfa_Disable',
  Admin_New_Security_All = 'Admin_New_Security_All',
  Accessibility_Policy_Create = 'Accessibility_Policy_Create',
  Accessibility_Policy_Edit = 'Accessibility_Policy_Edit',
  Accessibility_Policy_View = 'Accessibility_Policy_View',
  BrowserExtension_Manual_Evaluation_All = 'BrowserExtension_Manual_Evaluation_All',
  Webhook_Manage = 'Webhook_Manage',
  Webhook_View = 'Webhook_View',
  Validation_Testing_Create = 'Validation_Testing_Create',
  // do not actually assign this role to anybody
  New_Security_Only = 'New_Security_Only',
}

export const applicationPermissions: readonly AclPermissions[] = Object.freeze([
  AclPermissions.Admin_ActiveScans_All,
  AclPermissions.Admin_DigitalProperties_All,
  AclPermissions.Admin_ManualAudits_All,
  AclPermissions.Admin_DigitalPropertyTypes_Manage,
  AclPermissions.Admin_Logs_All,
  AclPermissions.Admin_Translations_All,
  AclPermissions.Admin_UsersGroup_All,
  AclPermissions.Admin_Users_All,
  AclPermissions.Admin_Export_Users,
  AclPermissions.Admin_Tenants_All,
  AclPermissions.Admin_Workspaces_All,
  AclPermissions.Admin_Workspaces_Impersonate,
  AclPermissions.Admin_WorkspaceUsers_Read,
  AclPermissions.Admin_WorkspaceUsers_Write,
  AclPermissions.Admin_Workspaces_Read,
  AclPermissions.Admin_Workspace_Add_Staff,
  AclPermissions.Admin_EditTrainingVideos,
  AclPermissions.Audits_Read,
  AclPermissions.Audit_Rule_View,
  AclPermissions.Document_Create,
  AclPermissions.Document_Edit,
  AclPermissions.Documentation_All,
  AclPermissions.Master_Library_All,
  AclPermissions.Master_Library_DeleteRule,
  AclPermissions.Master_Library_EditRule,
  AclPermissions.Support_Send,
  AclPermissions.Settings_Profile_All,
  AclPermissions.Settings_Account_All,
  AclPermissions.Settings_Workspaces_All,
  AclPermissions.Tenants_View,
  AclPermissions.Issue_Tracking_All,
  AclPermissions.Issue_Tracking_Create_Connection,
  AclPermissions.Issue_Tracking_Delete_Connection,
  AclPermissions.Issue_Tracking_Configure_JSON_Fields,
  AclPermissions.Design_Rule_All,
  AclPermissions.Design_Review_Project_All,
  AclPermissions.Design_Review_Page_All,
  AclPermissions.Design_Review_Finding_All,
  AclPermissions.Design_Review_Finding_Comment_All,
  AclPermissions.Admin_Scannable_Document_All,
  AclPermissions.Admin_New_Security_All,
  AclPermissions.BrowserExtension_Manual_Evaluation_All,
  AclPermissions.Webhook_Manage,
  AclPermissions.Webhook_View,
  AclPermissions.New_Security_Only,
]);

export const workspacePermissions: readonly AclPermissions[] = Object.freeze([
  AclPermissions.DigitalProperty_EditName,
  AclPermissions.Document_View,
  AclPermissions.Manual_Audit_Issue_Create_Comment,
  AclPermissions.Manual_Audit_Issue_View_Comment,
  AclPermissions.Manual_Audit_Issue_Delete_Comment,
  AclPermissions.Project_All,
  AclPermissions.Project_Archive,
  AclPermissions.Project_Delete,
  AclPermissions.Issue_Tracking_All,
  AclPermissions.Issue_Tracking_Create_Connection,
  AclPermissions.Issue_Tracking_Delete_Connection,
  AclPermissions.Issue_Tracking_Configure_Fields,
  AclPermissions.RunScan_AllOptions,
  AclPermissions.Scans_All,
  AclPermissions.Issues_All,
  AclPermissions.Settings_Users_All,
  AclPermissions.Settings_DigitalProperties_All,
  AclPermissions.Settings_DigitalProperties_Read,
  AclPermissions.Settings_ManageRoles_All,
  AclPermissions.Settings_ManageRoles_Read,
  AclPermissions.Monitoring_Write,
  AclPermissions.Monitoring_View,
  AclPermissions.Monitoring_Scan_Delete,
  AclPermissions.Scan_Tag_View,
  AclPermissions.Scan_Tag_Create,
  AclPermissions.Scan_Tag_Delete,
  AclPermissions.Scan_Tag_Update,

  AclPermissions.Scannable_Document_Create,
  AclPermissions.Scannable_Document_View,
  AclPermissions.Scannable_Document_Run_Scan,
  AclPermissions.Scannable_Document_View_Scan,
  AclPermissions.Scannable_Document_Set_Remediation,
  AclPermissions.Scannable_Document_Request_Remediation,
  AclPermissions.Scannable_Document_Upload_Remediation,
  AclPermissions.Scannable_Document_Remove_Remediation,
  AclPermissions.Scannable_Document_Revoke_Remediation,
  AclPermissions.Scannable_Document_Remove,

  AclPermissions.Task_All,
  AclPermissions.Task_Delete,
  AclPermissions.Task_View,
  AclPermissions.Property_Archive,
  AclPermissions.Manual_Audit_Issue_Status_Update,
  AclPermissions.Manual_Audit_Issue_Dismissal,
  AclPermissions.Manual_Audit_Issue_Delete_Attachment,
  AclPermissions.Manual_Audit_Issue_Create_Attachment,
  AclPermissions.Toggle_Evaluation_Rule,
  AclPermissions.Manual_Evaluation_To_Review,

  AclPermissions.Pages_Edit,
  AclPermissions.Pages_View,

  AclPermissions.User_Flow_All,

  AclPermissions.UserFlowStates_Create,
  AclPermissions.Match_Component_All,
  AclPermissions.Match_Component_Edit,
  AclPermissions.Match_Component_Create,
  AclPermissions.Match_Component_Archive,
  AclPermissions.Match_Component_View,

  AclPermissions.Design_Rule_All,
  AclPermissions.Design_Rule_View,
  AclPermissions.Design_Rule_Edit,

  AclPermissions.Design_Review_Project_All,
  AclPermissions.Design_Review_Project_View,
  AclPermissions.Design_Review_Project_Create,
  AclPermissions.Design_Review_Project_Edit,

  AclPermissions.Design_Review_Page_All,
  AclPermissions.Design_Review_Page_View,
  AclPermissions.Design_Review_Page_Create,
  AclPermissions.Design_Review_Page_Edit,

  AclPermissions.Design_Review_Finding_All,
  AclPermissions.Design_Review_Finding_View,
  AclPermissions.Design_Review_Finding_Create,
  AclPermissions.Design_Review_Finding_Edit,

  AclPermissions.Design_Review_Finding_Comment_All,
  AclPermissions.Design_Review_Finding_Comment_View,
  AclPermissions.Design_Review_Finding_Comment_Create,
  AclPermissions.Design_Review_Finding_Comment_Edit,

  AclPermissions.Manage_Users_Mfa_All,
  AclPermissions.Manage_Users_Mfa_View,
  AclPermissions.Manage_Users_Mfa_Disable,

  AclPermissions.Accessibility_Policy_Create,
  AclPermissions.Accessibility_Policy_Edit,
  AclPermissions.Accessibility_Policy_View,

  AclPermissions.BrowserExtension_Manual_Evaluation_All,

  AclPermissions.Webhook_Manage,
  AclPermissions.Webhook_View,

  AclPermissions.Validation_Testing_Create,
]);

export const sectionDescriptionPrefix: string = 'acl_section_';
export const permissionNamePrefix: string = 'acl_permission_name_';
export const permissionDescriptionPrefix: string = 'acl_permission_description_';
