<div class="common-select" [ngClass]="view" [formGroup]="form">
  <div *ngIf="context.label">
    <label for="{{ context.field }}" class="label mb-1 text-nowrap" [ngClass]="{ required: required }">
      {{ context.label | translate }}<span *ngIf="required" class="color-error"> *</span>
    </label>
    <app-icon
      *ngIf="context.tooltip"
      class="open-icon margin-left-sm mb-1"
      width="1rem"
      height="1rem"
      [appTooltip]="context.tooltip | translate"
      [attr.aria-label]="context.tooltip | translate"
      container="body"
      aria-hidden="true"
      icon="question-mark-2"
    >
    </app-icon>
  </div>

  <span *ngIf="context.description" class="mb-1">
    {{ context.description | translate }}
  </span>
  <select
    (change)="onSelectChange()"
    [attr.disabled]="disabled ? true : null"
    [attr.aria-required]="required"
    [class.w-100]="fullWidthInput"
    [compareWith]="comparator()"
    [formControlName]="context.field"
    id="{{ context.field }}"
  >
    <option [ngValue]="null" *ngIf="context.placeholderValue" disabled>
      {{ context.placeholderValue | translate }}
    </option>
    <ng-container *ngFor="let value of selectValues">
      <option [ngValue]="context.valueKey ? value[context.valueKey] : value">
        <ng-container *ngIf="context.translateName">
          {{ getOptionName(value) | translate }}
        </ng-container>
        <ng-container *ngIf="!context.translateName">
          {{ getOptionName(value) }}
        </ng-container>
      </option>
    </ng-container>
  </select>
  <div [id]="context.field + '-error'" class="error-container" *ngIf="form">
    <app-form-field-error
      [formValidationRequest$]="formValidationRequest$"
      [field]="form.get(context.field)"
      [name]="context.field"
      [label]="context.label"
      [customMessage]="context.customMessage"
    ></app-form-field-error>
  </div>
</div>
