import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, combineLatest, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import version from '../../../version.json';
import { config } from '../../../environments/config.shared';
import { CookieService } from '../../services/cookie.service';
import { UserService } from '../../services/user.service';
import { Api } from '../../../../shared/constants/api';
import { RouterLinkTarget } from '../../shared/constants';
import { RouteHelper } from '../../helpers/route.helper';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnChanges {
  private subscriptions: Subscription;

  public appVersion: { publishDate: string; version: string } = version;
  public footerTitle: string;
  public cookieMessageClosed: boolean;
  public isUserAuthenticated$: BehaviorSubject<boolean>;
  public useTextLight$: Observable<boolean>;
  public Api: typeof Api;
  public linkRel: string;

  @Input()
  public linkTarget: RouterLinkTarget = RouterLinkTarget.SELF;

  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private router: Router,
  ) {
    this.subscriptions = new Subscription();
    this.cookieMessageClosed = true;
    this.footerTitle = '';
    this.Api = Api;
    this.linkRel = '';

    this.isUserAuthenticated$ = new BehaviorSubject<boolean>(this.userService.isAuthenticated());
    this.useTextLight$ = combineLatest([
      this.isUserAuthenticated$,
      RouteHelper.isLoginPage$(this.router),
      RouteHelper.isSignupPage$(this.router),
    ]).pipe(
      map(
        ([isAuthenticated, isLoginPage, isSignupPage]: [boolean, boolean, boolean]): boolean =>
          !isAuthenticated && (isLoginPage || isSignupPage),
      ),
    );
  }

  private onAuthenticationChange(status: boolean): void {
    this.isUserAuthenticated$.next(status);
  }

  public removeCookiesUsageMessage(): void {
    this.cookieService.set('cookiesMessage', 'closed');
    this.cookieMessageClosed = true;
  }

  public ngOnInit(): void {
    if (this.cookieService.get('cookiesMessage') === null) {
      this.cookieMessageClosed = false;
    }

    const onAuthenticationChangeSubscription = this.userService.isAuthenticated$.subscribe(
      this.onAuthenticationChange.bind(this),
    );

    this.footerTitle = `${config.APP.COPYRIGHT_YEAR} - ${new Date().getFullYear()} ${config.company.name}`;

    this.subscriptions.add(onAuthenticationChangeSubscription);
  }

  public ngOnChanges(): void {
    if (this.linkTarget === RouterLinkTarget.BLANK) {
      this.linkRel = 'noreferrer noopener';
    }
  }
}
