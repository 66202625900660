import { Component, Input } from '@angular/core';
import { DsButtonVariants, Icons } from '@levelaccess/design-system';

export enum BannerType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

/**
 * TODO DS-186: get rid of this component once alternative is added to the
 * design system components
 */
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input()
  public visible: boolean = true;

  @Input()
  public message: string;

  @Input()
  public isHtml: boolean = false;

  public show(): void {
    this.visible = true;
  }

  public hide(): void {
    this.visible = false;
  }

  @Input()
  public type: BannerType;

  @Input()
  public closeable: boolean;

  constructor() {
    this.closeable = true;
    this.type = BannerType.success;
  }

  public closeBanner(): void {
    this.hide();
  }

  protected readonly BannerType: typeof BannerType = BannerType;
  protected readonly Icons: typeof Icons = Icons;
  protected readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
}
