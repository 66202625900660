export enum $severity {
  critical = 'critical',
  high = 'high',
  low = 'low',
  info = 'info',
}

export enum conformanceLevel {
  a = 'A',
  aa = 'AA',
  aaa = 'AAA',
}

export enum $accessibilityAudit {
  category = 'category',
  description = 'description',
  error = 'error',
  errorFalsePositive = 'errorFalsePositive',
  isMarkedAsFalsePositive = 'isMarkedAsFalsePositive',
  isSelectedForScanning = 'isSelectedForScanning',
  num = 'num',
  passed = 'passed',
  resources = 'resources',
  rule = 'rule',
  score = 'score',
  severity = 'severity',
  standards = 'standards',
  title = 'title',
  url = 'url',
  warning = 'warning',
}

export const wcagSeverityWeight: Record<$severity, number> = {
  [$severity.critical]: 10,
  [$severity.high]: 6,
  [$severity.low]: 2,
  [$severity.info]: 0,
};

export const severityToComparable: Record<$severity, string> = Object.freeze({
  [$severity.critical]: '400',
  [$severity.high]: '300',
  [$severity.low]: '200',
  [$severity.info]: '100',
});
