import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  $digitalProperty,
  $digitalPropertyMonitoring,
  $digitalPropertyMonitoringPagesLimitDefault,
  MonitoringDiscoveryMethod,
} from '../../../../../../../shared/constants/digital-properties';
import { $scan } from '../../../../../../../shared/constants/scan';
import { scanCreateRequest } from '../../../../../../../shared/constants/scan-create-request';
import { SCAN_URLS_SIZE_LIMIT_ERROR_KEY } from '../../../../../constants/scan.constants';
import { CustomValidators } from '../../../../../services/helpers/form-custom-validators';
import { TranslateService } from '../../../../../translate/translate.service';
import {
  IDigitalPropertyDetails,
  IDigitalPropertyMonitoringDetails,
  IDPMonitoringCrawlerDefinition,
  IDPMonitoringSitemapDefinition,
} from '../../../../../../../shared/interfaces/digital-property.interface';
import { ExcludePagesOperation, IScanHeader } from '../../../../../../../shared/interfaces/scan.interface';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';
import { AutomatedScanAdvancedService } from '../../../../../pages/public/automated-scan/advanced/automated-scan-advanced.service';

const DEFAULT_MIN_VALUE = 1;

@Component({
  selector: 'app-monitoring-scope-step',
  templateUrl: './monitoring-scope-step.component.html',
})
export class MonitoringScopeStepComponent implements AfterViewInit, AfterViewChecked {
  private availableDiscoveryMethods: MonitoringDiscoveryMethod[];
  public MonitoringDiscoveryMethod: typeof MonitoringDiscoveryMethod;
  private sitemapDomainExample: string;

  @Input()
  public formValidationRequest$: Subject<void>;
  @Input()
  public form: UntypedFormGroup;
  @Input()
  public stepNumber: number;
  @Input()
  public monitoring: IDigitalPropertyMonitoringDetails;
  @Input()
  public digitalProperty: IDigitalPropertyDetails;

  public scanCreateRequest: typeof scanCreateRequest;
  public $digitalPropertyMonitoring: typeof $digitalPropertyMonitoring;

  public get domains(): string[] {
    return this.digitalProperty?.[$digitalProperty.authorizedDomains] ?? [];
  }

  public get authorizedDomainsRegExp(): RegExp[] {
    return this.domains.map(SharedCommonUtility.urlPatternToRegexp);
  }

  public get isDpUrlPresent(): boolean {
    return SharedCommonUtility.notNullishOrEmpty(this.digitalProperty[$digitalProperty.url]);
  }

  public get sitemapExampleDomain(): string {
    return this.sitemapDomainExample ?? 'https://example.com';
  }

  public get maxNumberOfPages(): number {
    return this.digitalProperty?.[$digitalProperty.pagesLimit] ?? $digitalPropertyMonitoringPagesLimitDefault;
  }

  constructor(
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private advancedService: AutomatedScanAdvancedService,
  ) {
    this.MonitoringDiscoveryMethod = MonitoringDiscoveryMethod;
    this.scanCreateRequest = scanCreateRequest;
    this.$digitalPropertyMonitoring = $digitalPropertyMonitoring;
    this.availableDiscoveryMethods = Object.values(MonitoringDiscoveryMethod);
  }

  public get domainsTemplate(): string {
    return `<ul>${(this.domains ?? []).map((domain: string) => `<li>${domain}</li>`).join('')}</ul>`;
  }

  public get excludeOperations(): ExcludePagesOperation[] {
    return this.monitoring?.[$digitalPropertyMonitoring.crawler]?.[$scan.excludeUrlOperations] ?? [];
  }

  public get excludeUrls(): string[] {
    return this.monitoring?.[$digitalPropertyMonitoring.crawler]?.[$scan.excludeUrls] ?? [];
  }

  public get headers(): IScanHeader[] {
    return this.monitoring?.[$digitalPropertyMonitoring.headers];
  }

  public get discoveryMethodOptions(): { key: string; value: string }[] {
    return this.availableDiscoveryMethods.map((val: string): { key: string; value: string } => ({
      key: this.translateService.instant(`label_page_option_${val.toLowerCase()}`),
      value: val,
    }));
  }

  public onMonitoringDiscoveryMethodChange(discoveryMethod: MonitoringDiscoveryMethod): void {
    this.clearValidators();
    switch (discoveryMethod) {
      case MonitoringDiscoveryMethod.crawl:
        this.setCrawlValidators();
        break;
      case MonitoringDiscoveryMethod.sitemap:
        this.setSiteMapValidators();
        break;
      case MonitoringDiscoveryMethod.listOfPages:
        this.setListOfPagesValidators();
        break;
      default:
        throw new Error('Undefined discovery method');
    }
  }

  public clearValidators(): void {
    this.form.get(scanCreateRequest.url).clearValidators();
    this.form.get(scanCreateRequest.limitCrawlingPagesTo).clearValidators();
    this.form.get(scanCreateRequest.crawlDeepLevel).clearValidators();
    this.form.get($digitalPropertyMonitoring.sitemapUrl).clearValidators();
    this.form.get($digitalPropertyMonitoring.pagesToMonitor).clearValidators();
  }

  public setListOfPagesValidators(): void {
    this.form
      .get($digitalPropertyMonitoring.pagesToMonitor)
      .addValidators([
        CustomValidators.validateIsEmpty,
        CustomValidators.domainValidator,
        CustomValidators.limitMaxNumberOfLines(this.maxNumberOfPages, SCAN_URLS_SIZE_LIMIT_ERROR_KEY),
        CustomValidators.domainRestrictionsValidator(this.authorizedDomainsRegExp),
      ]);
  }

  public setSiteMapValidators(): void {
    this.form
      .get($digitalPropertyMonitoring.sitemapUrl)
      .addValidators([
        CustomValidators.validateIsEmpty,
        CustomValidators.required,
        CustomValidators.domainValidator,
        CustomValidators.sitemapValidator(() => this.authorizedDomainsRegExp),
      ]);
  }

  public setCrawlValidators(): void {
    this.form
      .get(scanCreateRequest.url)
      .setValidators([
        CustomValidators.validateIsEmpty,
        CustomValidators.domainRestrictionsValidator(this.authorizedDomainsRegExp),
      ]);
    this.form
      .get(scanCreateRequest.limitCrawlingPagesTo)
      .setValidators([
        CustomValidators.validateIsEmpty,
        CustomValidators.min(DEFAULT_MIN_VALUE),
        CustomValidators.max(this.maxNumberOfPages),
        CustomValidators.validateInteger,
      ]);
    this.form
      .get(scanCreateRequest.crawlDeepLevel)
      .setValidators([
        CustomValidators.validateIsEmpty,
        CustomValidators.min(DEFAULT_MIN_VALUE),
        CustomValidators.validateInteger,
      ]);
  }

  private updateAvailableDiscoveryMethods(): void {
    let methods: MonitoringDiscoveryMethod[] = Object.values(MonitoringDiscoveryMethod);
    if (!this.isDpUrlPresent || this.authorizedDomainsRegExp.length === 0) {
      methods = [MonitoringDiscoveryMethod.listOfPages];
    }

    this.availableDiscoveryMethods = methods;
  }

  private getMonitoringDiscoveryMethod(): MonitoringDiscoveryMethod {
    const discoveryMethod: MonitoringDiscoveryMethod =
      this.monitoring?.[$digitalPropertyMonitoring.crawler]?.discoveryMethod ?? MonitoringDiscoveryMethod.listOfPages;

    return this.availableDiscoveryMethods.includes(discoveryMethod) ? discoveryMethod : this.availableDiscoveryMethods[0];
  }

  private initializeForm(): void {
    this.form
      .get(scanCreateRequest.url)
      .setValue(
        this.monitoring?.[$digitalPropertyMonitoring.crawler]?.[$scan.url] || this.digitalProperty?.[$digitalProperty.url],
      );

    if (this.monitoring?.[$digitalPropertyMonitoring.pagesToMonitor]?.length > 0) {
      this.form
        .get($digitalPropertyMonitoring.pagesToMonitor)
        .setValue(this.monitoring[$digitalPropertyMonitoring.pagesToMonitor].join('\n'));
    } else if (this.digitalProperty?.[$digitalProperty.url]) {
      this.form.get($digitalPropertyMonitoring.pagesToMonitor).setValue(this.digitalProperty[$digitalProperty.url]);
    }

    const uniqueDomains: Set<string> = new Set(this.domains.filter((domain: string) => domain.indexOf('*') < 0));
    this.sitemapDomainExample = [...uniqueDomains][0] ?? 'https://example.com';
    if (!this.sitemapDomainExample.startsWith('http')) {
      this.sitemapDomainExample = `https://${this.sitemapDomainExample}`;
    }

    const crawler: IDPMonitoringCrawlerDefinition | IDPMonitoringSitemapDefinition =
      this.monitoring?.[$digitalPropertyMonitoring.crawler];

    if (crawler?.[$digitalPropertyMonitoring.discoveryMethod] === MonitoringDiscoveryMethod.sitemap) {
      this.form.get($digitalPropertyMonitoring.sitemapUrl).setValue(crawler[$digitalPropertyMonitoring.sitemapUrl]);
    } else if (crawler?.[$digitalPropertyMonitoring.discoveryMethod] === MonitoringDiscoveryMethod.crawl) {
      this.form.patchValue({
        [scanCreateRequest.limitCrawlingPagesTo]: crawler[$scan.limitCrawlingPagesTo],
        [scanCreateRequest.crawlDeepLevel]: crawler[$scan.crawlDeepLevel],
        [scanCreateRequest.skipHashes]: crawler[$scan.skipHashes],
        [scanCreateRequest.skipQueryParams]: crawler[$scan.skipQueryParams],
      });
    }

    this.form.get($digitalPropertyMonitoring.discoveryMethod).setValue(this.getMonitoringDiscoveryMethod());

    const handlePopupsSelector: string = this.monitoring?.[$digitalPropertyMonitoring.handlePopupsSelector];
    this.form.get($digitalPropertyMonitoring.handlePopupsSelector).setValue(handlePopupsSelector);
    this.advancedService.patchScanOptions({
      handlePopupsSelector: handlePopupsSelector ?? null,
    });
  }

  public ngAfterViewInit(): void {
    this.updateAvailableDiscoveryMethods();
    this.initializeForm();
    this.onMonitoringDiscoveryMethodChange(this.getMonitoringDiscoveryMethod());
  }

  public ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
}
