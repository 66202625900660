import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IGetUserTasks,
  ITask,
  ITaskComment,
  ITaskUserComment,
  ITaskWithLinkedFindingsAndAttachments,
  ITaskWithProject,
  ITasksSummaryByDp,
} from '../../../../shared/interfaces/task.interface';
import { RestBuilder } from '../helpers/rest.builder';
import {
  IDeleteProjectValidationResult,
  IProject,
  IProjectKeyExistsResponse,
  IProjectWithOwner,
} from '../../../../shared/interfaces/project.interface';
import { ApiHeaderOption } from '../../../../shared/constants/api';
import {
  IDownloadProjectTasksFilterRequest,
  IGetProjectTasks,
  ITaskCreateRequest,
  ITaskFilterRequest,
  ITaskUserCommentCreateRequest,
} from '../../../../shared/interfaces/task.requests.interface';
import { IProjectCreateRequest, IProjectUpdateRequest } from '../../../../shared/interfaces/project.requests.interface';
import { ResourceUtility } from '../../utility/resource.utility';

@Injectable({
  providedIn: 'root',
})
export class ProjectsRestAPI {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // POST /workspaces/:workspaceId/projects
  public createProject(workspaceId: string, project: IProjectCreateRequest): Observable<IProject> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects().getApiUrl();
    return this.httpClient.post<IProject>(url, project);
  }

  // POST /workspaces/:workspaceId/projects/:projectId/tasks
  public createTask(workspaceId: string, projectId: string, task: FormData): Observable<ITask> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).tasks().getApiUrl();

    return this.httpClient.post<ITask>(url, task);
  }

  // POST /workspaces/:workspaceId/projects/:projectId/tasks/bulk
  public createTasks(workspaceId: string, projectId: string, tasks: ITaskCreateRequest[]): Observable<ITask[]> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).tasks().bulk().getApiUrl();

    return this.httpClient.post<ITask[]>(url, tasks);
  }

  // PUT /workspaces/:workspaceId/projects/:projectId/tasks/:taskId
  public editTask(workspaceId: string, projectId: string, taskId: string, task: FormData): Observable<ITask> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).tasks(taskId).getApiUrl();
    return this.httpClient.put<ITask>(url, task);
  }

  // POST /workspaces/:workspaceId/projects/:projectId
  public updateProject(workspaceId: string, projectId: string, project: IProjectUpdateRequest): Observable<IProject> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).getApiUrl();
    return this.httpClient.post<IProject>(url, project);
  }

  // GET /workspaces/:workspaceId/projects/:projectId/tasks
  public getProjectTasks(
    workspaceId: string,
    projectId: string,
    filters: ITaskFilterRequest,
    skipLoader?: boolean,
  ): Observable<IGetProjectTasks> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).tasks().getApiUrl();

    let params: HttpParams = new HttpParams();
    for (const [field, value] of Object.entries(filters)) {
      params = params.set(field, value);
    }

    return this.httpClient.get<IGetProjectTasks>(url, {
      params: params,
      headers: {
        [ApiHeaderOption.skipLoader]: skipLoader === true ? 'true' : 'false',
      },
    });
  }

  // GET /workspaces/:workspaceId/projects/tasks
  public getAllProjectTasks(workspaceId: string, filters: ITaskFilterRequest): Observable<ITaskWithProject[]> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects().tasks().getApiUrl();

    let params: HttpParams = new HttpParams();
    for (const [field, value] of Object.entries(filters)) {
      params = params.set(field, value);
    }

    return this.httpClient.get<ITaskWithProject[]>(url, { params });
  }

  // GET /workspaces/:workspaceId/projects/validate-tasks/digital-properties/summary
  public getTasksSummaryByDp(workspaceId: string, filters: ITaskFilterRequest): Observable<ITasksSummaryByDp[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .projects()
      .validateTasks()
      .digitalProperties()
      .summary()
      .getApiUrl();

    let params: HttpParams = new HttpParams();
    for (const [field, value] of Object.entries(filters)) {
      params = params.set(field, value);
    }

    return this.httpClient.get<ITasksSummaryByDp[]>(url, { params });
  }

  // GET /workspaces/:workspaceId/projects
  public getProjects(workspaceId: string): Observable<IProject[]> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects().getApiUrl();
    return this.httpClient.get<IProject[]>(url);
  }

  // GET /workspaces/:workspaceId/projects/:projectId/tasks/:taskId
  public getProjectTask(
    workspaceId: string,
    projectId: string,
    taskId: string,
  ): Observable<ITaskWithLinkedFindingsAndAttachments> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).tasks(taskId).getApiUrl();

    return this.httpClient.get<ITaskWithLinkedFindingsAndAttachments>(url);
  }

  // DELETE /workspaces/:workspaceId/projects/:projectId/tasks/:taskId
  public deleteProjectTask(workspaceId: string, projectId: string, taskId: string): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).tasks(taskId).getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // DELETE /workspaces/:workspaceId/projects/:projectId/tasks
  public deleteProjectTasks(workspaceId: string, projectId: string, taskIds: string[]): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { taskIds: taskIds },
    };
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).tasks().getApiUrl();

    return this.httpClient.delete<void>(url, httpOptions);
  }

  // GET /workspaces/:workspaceId/projects/:projectId/tasks/:taskId/comments
  public getTaskComments(workspaceId: string, projectId: string, taskId: string): Observable<ITaskComment[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .projects(projectId)
      .tasks(taskId)
      .comments()
      .getApiUrl();

    return this.httpClient.get<ITaskUserComment[]>(url);
  }

  // POST /workspaces/:workspaceId/projects/:projectId/tasks/:taskId/comments
  public createTaskComment(
    workspaceId: string,
    projectId: string,
    taskId: string,
    comment: ITaskUserCommentCreateRequest,
  ): Observable<ITaskUserComment> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .projects(projectId)
      .tasks(taskId)
      .comments()
      .getApiUrl();

    return this.httpClient.post<ITaskUserComment>(url, comment);
  }

  // GET /workspaces/:workspaceId/projects/:projectId
  public getProject(workspaceId: string, projectId: string): Observable<IProjectWithOwner> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).getApiUrl();
    return this.httpClient.get<IProjectWithOwner>(url);
  }

  // GET /workspaces/:workspaceId/projects/keys/:keyId
  public projectKeyExists(workspaceId: string, projectKey: string): Observable<IProjectKeyExistsResponse> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects().key(projectKey).getApiUrl();
    return this.httpClient.get<IProjectKeyExistsResponse>(url);
  }

  // DELETE /workspaces/:workspaceId/projects/:projectId
  public deleteProject(workspaceId: string, projectId: string): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // PUT /workspaces/:workspaceId/projects/:projectId/archive
  public archiveProject(workspaceId: string, projectId: string): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).archive().getApiUrl();

    return this.httpClient.put<void>(url, {});
  }

  // PUT /workspaces/:workspaceId/projects/:projectId/restore
  public restoreProject(workspaceId: string, projectId: string): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).restore().getApiUrl();

    return this.httpClient.put<void>(url, {});
  }

  // GET /workspaces/:workspaceId/project/:projectId/archivable
  public isProjectArchivable(workspaceId: string, projectId: string): Observable<boolean> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).archivable().getApiUrl();

    return this.httpClient.get<boolean>(url);
  }

  // GET /workspaces/:workspaceId/project/:projectId/deletable
  public validateProjectDeletion(workspaceId: string, projectId: string): Observable<IDeleteProjectValidationResult> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).deletable().getApiUrl();

    return this.httpClient.get<IDeleteProjectValidationResult>(url);
  }

  // POST /workspaces/:workspaceId/projects/:projectId/tasks/download
  public downloadProjectTasks(
    workspaceId: string,
    projectId: string,
    filters: IDownloadProjectTasksFilterRequest = {},
  ): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).tasks().download().getApiUrl();
    return this.httpClient
      .post(url, filters, { observe: 'response', responseType: 'arraybuffer' })
      .pipe(map(ResourceUtility.downloadResponse));
  }

  // GET /workspaces/:workspaceId/my-tasks
  public getMyTasks(workspaceId: string, filters: ITaskFilterRequest): Observable<IGetUserTasks> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).myTasks().getApiUrl();

    let params: HttpParams = new HttpParams();
    for (const [field, value] of Object.entries(filters)) {
      params = params.set(field, value);
    }

    return this.httpClient.get<IGetUserTasks>(url, { params: params });
  }

  // GET /workspaces/:workspaceId/projects/:projectId/issue-integration-statuses
  public getUsedIssueTrackingStatuses(workspaceId: string, projectId: string): Observable<string[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .projects(projectId)
      .issueTrackingIssueStatuses()
      .getApiUrl();

    return this.httpClient.get<string[]>(url);
  }

  // POST /workspaces/:workspaceId/projects/validate-tasks
  public validateTasks(workspaceId: string, formData: FormData): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects().validateTasks().getApiUrl();
    return this.httpClient.post<void>(url, formData);
  }

  public getTaskProjectId(workspaceId: string, projectId: string): Observable<{ taskProjectId: string }> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).projects(projectId).taskProjectId().getApiUrl();
    return this.httpClient.post<{ taskProjectId: string }>(url, {});
  }
}
