import { AclPermissions } from './acl';
import { SIZE_60_MB } from './uploads';

export enum $issueTrackingConnectionConfig {
  applicationLink = 'applicationLink',
  applicationName = 'applicationName',
  applicationType = 'applicationType',
  serviceProviderName = 'serviceProviderName',
  consumerKey = 'consumerKey',
  sharedSecret = 'sharedSecret',
  requestTokenURL = 'requestTokenURL',
  accessTokenURL = 'accessTokenURL',
  authorizeURL = 'authorizeURL',
  consumerName = 'consumerName',
  publicKey = 'publicKey',
}

export enum $issueIntegrationType {
  asana = 'asana',
  azure = 'azure',
  jira = 'jira',
}

export enum $authorizationType {
  oauth1 = 'oauth1',
  oauth2 = 'oauth2',
}

export enum $taskTransitionType {
  allIssueTypes = 'allIssueTypes',
  specificIssueType = 'specificIssueType',
}

export enum JobOperationType {
  createTask = 'createTask',
  updateTask = 'updateTask',
  syncTask = 'syncTask',
  addAttachment = 'addAttachment',
  addComment = 'addComment',
  deleteComment = 'deleteComment',
  restartSyncForAllTasks = 'restartSyncForAllTasks',
  deleteTask = 'deleteTask',
  pauseSync = 'pauseSync',
  restartSync = 'restartSync',
  changeStatus = 'changeStatus',
  hookEvent = 'hookEvent',
  linkTask = 'linkTask',
}

export const configureFieldsPermissions: AclPermissions[] = [
  AclPermissions.Admin_Workspaces_Impersonate,
  AclPermissions.Issue_Tracking_Configure_Fields,
];

export const AZURE_ATTACHMENT_MAX_FILESIZE: number = SIZE_60_MB;

export const configureJsonPermissions: AclPermissions[] = [AclPermissions.Issue_Tracking_Configure_JSON_Fields];

export const OAuth2CallbackResultOK = 'ok';
export const AuthorizationResultOK = 'ok';
