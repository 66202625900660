import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, throwError, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UserService } from '../../user.service';
import { ErrorHandlerService } from '../../error-handler.service';
import { $user } from '../../../../../shared/constants/user';
import { Api } from '../../../../../shared/constants/api';
import { IUserServerResponse } from '../../../../../shared/interfaces/user.interface';
import { WorkspaceAclRoles } from '../../../../../shared/constants/workspace';
import { IWorkspaceRole } from '../../../../../shared/interfaces/workspace-role.interface';
import { $workspaceRole } from '../../../../../shared/constants/workspace-role.constants';
import { UserAclService } from '../../user-acl.service';

@Injectable()
export class WorkspaceAdminGuard {
  constructor(
    private userService: UserService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private userAclService: UserAclService,
  ) {}

  /**
   * @returns true if current user is either workspace admin or workspace staff, OR if it is admin and
   * 'isRouteAvailableForApplicationAdmin' is true. otherwise returns false.
   */
  public isCurrentUserWorkspaceAdminOrStaffOrSystemAdmin(
    userData: IUserServerResponse,
    isAdmin: boolean,
    isRouteAvailableForApplicationAdmin: boolean = false,
  ): boolean {
    const requiredRoles: string[] = [WorkspaceAclRoles.workspaceAdmin, WorkspaceAclRoles.workspaceStaff];
    if (typeof userData[$user.workspaceRoles] === 'undefined') {
      return false;
    }

    const userHasRequiredRoles: boolean = userData[$user.workspaceRoles].some((role: IWorkspaceRole): boolean =>
      requiredRoles.includes(role[$workspaceRole.name]),
    );

    const hasUserAccessToAdminSection: boolean = isRouteAvailableForApplicationAdmin ? isAdmin : false;

    return (hasUserAccessToAdminSection || userHasRequiredRoles) && this.userService.isAuthenticated();
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userAclService.isNewSecurityEnabled().pipe(
      switchMap((isNewSecurityEnabled: boolean): Observable<boolean> => {
        if (isNewSecurityEnabled) {
          return of(true);
        }

        return this.checkWithLegacyPermissions(next, state);
      }),
    );
  }

  private checkWithLegacyPermissions(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const onUserDataSuccess = ([userData, isAdmin]: [IUserServerResponse, boolean]): boolean => {
      if (this.isCurrentUserWorkspaceAdminOrStaffOrSystemAdmin(userData, isAdmin, next.data.isAvailableForApplicationAdmin)) {
        return true;
      }

      this.router
        .navigate([`/${Api.forbidden}`], { queryParams: { url: state.url, title: next.data.title } })
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

      return false;
    };

    const onUserDataError = (val: any): Observable<boolean> => {
      this.router.navigate([`/${Api.login}`]).catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

      return throwError(false);
    };

    return combineLatest([this.userService.getAuthenticatedUserProfile(), this.userService.isAdmin()]).pipe(
      map(onUserDataSuccess),
      catchError(onUserDataError),
    );
  }
}
