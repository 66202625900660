<div class="code-block-label-container d-flex flex-row align-items-center">
  <ng-content select="[app-code-block-label]"></ng-content>
  <button
    ds-button
    (click)="copyCodeToClipboard()"
    [variant]="microButtonVariant"
    [microActionIcon]="copyIcon"
    [attr.aria-label]="copyButtonAriaLabel"
  >
    {{ 'copy' | translate }}
  </button>
</div>
<pre><code class="bg-transparent">{{code}}</code></pre>
