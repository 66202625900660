import { Router, NavigationEnd, Event } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { Api } from '../../../shared/constants/api';
import { AngularUtility } from '../utility/angular.utility';

export class RouteHelper {
  private static isRouteStartsWithPath$(router: Router, path: string): Observable<boolean> {
    return router.events.pipe(
      filter((routerEvent: Event): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd),
      map((routerEvent: NavigationEnd) => routerEvent.url.startsWith(path)),
      startWith(router.routerState.snapshot.url.startsWith(path)),
      AngularUtility.shareRef(),
    );
  }

  public static isLoginPage$(router: Router): Observable<boolean> {
    return this.isRouteStartsWithPath$(router, `/${Api.login}`);
  }

  public static isSignupPage$(router: Router): Observable<boolean> {
    return this.isRouteStartsWithPath$(router, `/${Api.signup}`);
  }
}
