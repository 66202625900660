import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { $project } from '../../../shared/constants/project';

import {
  IDeleteProjectValidationResult,
  IProject,
  IProjectKeyExistsResponse,
  IProjectWithOwner,
} from '../../../shared/interfaces/project.interface';
import { IProjectCreateRequest, IProjectUpdateRequest } from '../../../shared/interfaces/project.requests.interface';
import {
  IGetUserTasks,
  ITask,
  ITaskComment,
  ITasksSummaryByDp,
  ITaskUserComment,
  ITaskWithLinkedFindingsAndAttachments,
  ITaskWithProject,
  ITaskWithTargetStatesAndFindings,
  TaskSearchFields,
} from '../../../shared/interfaces/task.interface';
import {
  IDownloadProjectTasksFilterRequest,
  IGetProjectTasks,
  ITaskCreateRequest,
  ITaskFilterRequest,
  ITaskUserCommentCreateRequest,
} from '../../../shared/interfaces/task.requests.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { ITaskCounts } from '../interfaces/task.interface';
import { ProjectsRestAPI } from './rest/projects.api';
import { TaskUtility } from '../utility/task.utility';
import { $taskWithProject, TaskSource, TaskStatus } from '../../../shared/constants/task';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private projectsApi: ProjectsRestAPI) {}

  public createProject(workspaceId: string, project: IProjectCreateRequest): Observable<IProject> {
    return this.projectsApi.createProject(workspaceId, {
      ...project,
      [$project.startDate]: project.startDate,
      [$project.endDate]: project.endDate,
    });
  }

  public createTask(workspaceId: string, projectId: string, task: FormData): Observable<ITask> {
    return this.projectsApi.createTask(workspaceId, projectId, task);
  }

  public createTasks(workspaceId: string, projectId: string, tasks: ITaskCreateRequest[]): Observable<ITask[]> {
    return this.projectsApi.createTasks(workspaceId, projectId, tasks);
  }

  public editTask(workspaceId: string, projectId: string, taskId: string, task: FormData): Observable<ITask> {
    return this.projectsApi.editTask(workspaceId, projectId, taskId, task);
  }

  public updateProject(workspaceId: string, projectId: string, project: IProjectUpdateRequest): Observable<IProject> {
    return this.projectsApi.updateProject(workspaceId, projectId, {
      ...project,
      [$project.startDate]: new Date(project.startDate),
      [$project.endDate]: SharedCommonUtility.isNullish(project.endDate) ? undefined : new Date(project.endDate),
    });
  }

  public restoreProject(workspaceId: string, projectId: string): Observable<void> {
    return this.projectsApi.restoreProject(workspaceId, projectId);
  }

  public archiveProject(workspaceId: string, projectId: string): Observable<void> {
    return this.projectsApi.archiveProject(workspaceId, projectId);
  }

  public deleteProject(workspaceId: string, projectId: string): Observable<void> {
    return this.projectsApi.deleteProject(workspaceId, projectId);
  }

  public getTasks(
    workspaceId: string,
    projectId: string,
    filters: ITaskFilterRequest,
    skipLoader: boolean = false,
  ): Observable<IGetProjectTasks> {
    return this.projectsApi.getProjectTasks(workspaceId, projectId, filters, skipLoader);
  }

  public getValidateTasks(workspaceId: string, filter: ITaskFilterRequest): Observable<ITaskWithProject[]> {
    return this.projectsApi.getAllProjectTasks(workspaceId, {
      ...filter,
      [TaskSearchFields.progress]: TaskStatus.requiresReview,
      source: TaskSource.manual,
    });
  }

  public getValidateProjectTasks(
    workspaceId: string,
    projectId: string,
    filter: ITaskFilterRequest,
  ): Observable<ITaskWithProject[]> {
    return this.projectsApi
      .getProjectTasks(workspaceId, projectId, {
        ...filter,
        [TaskSearchFields.progress]: TaskStatus.requiresReview,
        source: TaskSource.manual,
      })
      .pipe(map((response: IGetProjectTasks) => response.tasks.map(toTaskWithProject(response))));

    function toTaskWithProject(response: IGetProjectTasks): (task: ITaskWithTargetStatesAndFindings) => ITaskWithProject {
      return (task: ITaskWithTargetStatesAndFindings): ITaskWithProject => ({
        ...task,
        [$taskWithProject.project]: response.project,
      });
    }
  }

  public hasValidateProjectTasks(workspaceId: string, projectId: string): Observable<boolean> {
    return this.projectsApi
      .getProjectTasks(workspaceId, projectId, {
        [TaskSearchFields.progress]: TaskStatus.requiresReview,
        source: TaskSource.manual,
      })
      .pipe(map((response: IGetProjectTasks): boolean => response.tasks.length > 0));
  }

  public getTasksSummaryByDp(workspaceId: string, filter: ITaskFilterRequest): Observable<ITasksSummaryByDp[]> {
    return this.projectsApi.getTasksSummaryByDp(workspaceId, filter);
  }

  public getTaskCounts(workspaceId: string, projectId: string): Observable<ITaskCounts> {
    return this.getTasks(workspaceId, projectId, {}).pipe(
      map((response: IGetProjectTasks): ITaskCounts => {
        return TaskUtility.reduceTargetStatesAndFindingsToTaskCounts(response.tasks);
      }),
    );
  }

  public getIntegratedTaskCounts(workspaceId: string, projectId: string): Observable<ITaskCounts> {
    return this.getTasks(workspaceId, projectId, { [TaskSearchFields.taskIssueIntegrated]: true }).pipe(
      map((response: IGetProjectTasks): ITaskCounts => {
        return TaskUtility.reduceTargetStatesAndFindingsToTaskCounts(response.tasks);
      }),
    );
  }

  public getTask(workspaceId: string, projectId: string, taskId: string): Observable<ITaskWithLinkedFindingsAndAttachments> {
    return this.projectsApi.getProjectTask(workspaceId, projectId, taskId);
  }

  public deleteTask(workspaceId: string, projectId: string, taskId: string): Observable<void> {
    return this.projectsApi.deleteProjectTask(workspaceId, projectId, taskId);
  }

  public deleteTasks(workspaceId: string, projectId: string, taskIds: string[]): Observable<void> {
    return this.projectsApi.deleteProjectTasks(workspaceId, projectId, taskIds);
  }

  public getTaskComments(workspaceId: string, projectId: string, taskId: string): Observable<ITaskComment[]> {
    return this.projectsApi.getTaskComments(workspaceId, projectId, taskId);
  }

  public createTaskComment(
    workspaceId: string,
    projectId: string,
    taskId: string,
    comment: ITaskUserCommentCreateRequest,
  ): Observable<ITaskUserComment> {
    return this.projectsApi.createTaskComment(workspaceId, projectId, taskId, comment);
  }

  public getProjects(workspaceId: string): Observable<IProject[]> {
    return this.projectsApi.getProjects(workspaceId);
  }

  public getProject(workspaceId: string, projectId: string): Observable<IProjectWithOwner> {
    return this.projectsApi.getProject(workspaceId, projectId);
  }

  public projectKeyExists(workspaceId: string, projectKey: string): Observable<boolean> {
    return this.projectsApi
      .projectKeyExists(workspaceId, projectKey)
      .pipe(map((response: IProjectKeyExistsResponse) => response.projectKeyExists));
  }

  public isProjectArchivable(workspaceId: string, projectId: string): Observable<boolean> {
    return this.projectsApi.isProjectArchivable(workspaceId, projectId);
  }

  public validateProjectDeletion(workspaceId: string, projectId: string): Observable<IDeleteProjectValidationResult> {
    return this.projectsApi.validateProjectDeletion(workspaceId, projectId);
  }

  public downloadTasksCSVReport(
    workspaceId: string,
    projectId: string,
    filters?: IDownloadProjectTasksFilterRequest,
  ): Observable<void> {
    return this.projectsApi.downloadProjectTasks(workspaceId, projectId, filters);
  }

  public getMyTasks(workspaceId: string, filters: ITaskFilterRequest): Observable<IGetUserTasks> {
    return this.projectsApi.getMyTasks(workspaceId, filters);
  }

  public getUsedIssueTrackingStatuses(workspaceId: string, projectId: string): Observable<string[]> {
    return this.projectsApi.getUsedIssueTrackingStatuses(workspaceId, projectId);
  }

  public validateTasks(workspaceId: string, formData: FormData): Observable<void> {
    return this.projectsApi.validateTasks(workspaceId, formData);
  }

  public getTaskProjectId(workspaceId: string, projectId: string): Observable<string> {
    return this.projectsApi
      .getTaskProjectId(workspaceId, projectId)
      .pipe(map((value: { taskProjectId: string }): string => value.taskProjectId));
  }
}
