<form [formGroup]="form">
  <h4>{{ stepNumber + 1 }} - {{ 'scope' | translate }}</h4>
  <app-common-info-note [noteText]="'the_available_domains_are' | translate: domainsTemplate"> </app-common-info-note>
  <div class="row">
    <div class="col">
      <label class="d-block label margin-top margin-bottom-xs text-nowrap required" for="{{ 'discoveryMethod' }}">
        {{ 'page_discovery_method' | translate }}
      </label>
      <div class="margin-bottom-xs">
        {{ 'page_discovery_method_description' | translate }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <app-common-select
        class="d-block margin-bottom"
        [form]="form"
        [values]="discoveryMethodOptions"
        [formValidationRequest$]="formValidationRequest$"
        [context]="{
          field: 'discoveryMethod',
          nameKey: 'key',
          valueKey: 'value',
        }"
        (valueChanged$)="onMonitoringDiscoveryMethodChange($event)"
      >
      </app-common-select>
    </div>
  </div>
  <div class="modal-body-content" [ngSwitch]="form.get('discoveryMethod').value">
    <ng-container *ngSwitchCase="MonitoringDiscoveryMethod.crawl">
      <div class="row">
        <div class="col">
          <app-common-input
            class="d-block margin-bottom margin-top"
            [formValidationRequest$]="formValidationRequest$"
            [form]="form"
            [context]="{
              label: 'start_page',
              field: scanCreateRequest.url,
              overrideErrors: {
                isDomainPartOfSubscription: 'monitoring_start_url_domain_error' | translate,
              },
            }"
          ></app-common-input>
        </div>
        <div class="col">
          <app-common-input
            class="d-block margin-bottom margin-top"
            [formValidationRequest$]="formValidationRequest$"
            [form]="form"
            [context]="{
              label: 'label_maximum_number_of_pages',
              field: scanCreateRequest.limitCrawlingPagesTo,
              type: 'number',
            }"
          ></app-common-input>
        </div>
        <div class="col">
          <app-common-input
            class="d-block margin-bottom margin-top"
            [formValidationRequest$]="formValidationRequest$"
            [form]="form"
            [context]="{
              label: 'label_crawl_depth',
              field: scanCreateRequest.crawlDeepLevel,
              tooltip: 'label_crawl_depth_description',
              type: 'number',
            }"
          ></app-common-input>
        </div>
      </div>
      <app-common-checkbox class="d-block margin-bottom" [formControlName]="scanCreateRequest.skipHashes">
        <span class="checkbox-label">{{ 'label_skip_hashes' | translate }}</span>
        <span class="checkbox-description">{{ 'skip_hashes_description' | translate }}</span>
      </app-common-checkbox>

      <app-common-checkbox class="d-block margin-bottom" [formControlName]="scanCreateRequest.skipQueryParams">
        <span class="checkbox-label">{{ 'label_skip_query_params' | translate }}</span>
        <span class="checkbox-description">{{ 'skip_query_params_description' | translate }}</span>
      </app-common-checkbox>
      <ng-container *ngTemplateOutlet="excludePages"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoringDiscoveryMethod.sitemap">
      <app-common-input
        class="d-block margin-bottom margin-top"
        [formValidationRequest$]="formValidationRequest$"
        [form]="form"
        [context]="{
          label: 'label_dp_sitemap_url',
          field: $digitalPropertyMonitoring.sitemapUrl,
          description: 'description_dp_sitemap_url' | translate: sitemapExampleDomain,
          overrideErrors: {
            isDomainPartOfSubscription: 'monitoring_start_url_domain_error' | translate,
          },
        }"
      ></app-common-input>
      <ng-container *ngTemplateOutlet="excludePages"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="MonitoringDiscoveryMethod.listOfPages">
      <app-common-textarea
        class="d-block margin-top"
        [formValidationRequest$]="formValidationRequest$"
        [form]="form"
        [context]="{
          label: 'label_list_of_pages',
          field: $digitalPropertyMonitoring.pagesToMonitor,
          placeholder: 'specific_pages_placeholder',
          required: true,
          lineLimit: maxNumberOfPages,
          limitLabel: 'label_pages',
        }"
      ></app-common-textarea>
    </ng-container>
  </div>
  <ng-template #excludePages>
    <app-exclude-pages
      [form]="form"
      [formValidationRequest$]="formValidationRequest$"
      [excludeOperations]="excludeOperations"
      [excludeUrls]="excludeUrls"
    >
    </app-exclude-pages>
  </ng-template>
  <div class="margin-bottom d-flex">
    <div class="row w-100">
      <app-scan-authentication-details
        class="col-12"
        [form]="form"
        [formValidationRequest$]="formValidationRequest$"
        [monitoring]="monitoring"
      ></app-scan-authentication-details>
    </div>
  </div>

  <div class="margin-bottom d-flex">
    <div class="row w-100">
      <app-scan-extra-headers
        class="col-12"
        [form]="form"
        [headersControlName]="'headers'"
        [formValidationRequest$]="formValidationRequest$"
        [headers]="headers"
      ></app-scan-extra-headers>
    </div>
  </div>
  <div class="margin-bottom d-flex">
    <div class="row w-100">
      <app-advanced-scan-handle-popups
        [form]="form"
        [formValidationRequest$]="formValidationRequest$"
      ></app-advanced-scan-handle-popups>
    </div>
  </div>
</form>
