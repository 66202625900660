import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class A11yService {
  private message: Subject<string>;
  public message$: Observable<string>;

  private alertMessage: Subject<string>;
  public alertMessage$: Observable<string>;

  constructor() {
    this.message = new Subject<string>();
    this.message$ = this.message.asObservable();

    this.alertMessage = new Subject<string>();
    this.alertMessage$ = this.alertMessage.asObservable();
  }

  public setMessage(message: string): void {
    this.message.next(message);
  }

  public setAlertMessage(message: string): void {
    this.alertMessage.next(message);
  }
}
