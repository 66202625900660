import { AclPermissions } from '../constants/acl';
import { FunctionalActions } from '../interfaces/security-role.interface';

export interface IAclSecurityAdapterCheckOptions {
  readonly requireFunctionalActions?: boolean;
  readonly throwIfFunctionalActionsUndefined?: boolean;
}

export abstract class AclSecurityAdapterBase {
  protected options: IAclSecurityAdapterCheckOptions;
  protected newSecurityOnly?: boolean;
  protected skipNewSecurity?: boolean;
  protected permissions?: AclPermissions[];

  protected checkEntityLevel?: boolean;
  protected workspaceId?: string;
  protected digitalPropertyId?: string;
  protected functionalActions?: FunctionalActions;

  protected constructor() {
    this.options = {};
  }

  public useOptions(options: IAclSecurityAdapterCheckOptions): this {
    this.options = options;
    return this;
  }

  public useForNewSecurityOnly(): this {
    this.newSecurityOnly = true;
    return this;
  }

  public skipNewSecurityCheck(): this {
    this.skipNewSecurity = true;
    return this;
  }

  public useEntityLevel(): this {
    this.checkEntityLevel = true;
    return this;
  }

  public useAdmin(): this {
    this.checkEntityLevel = true;
    this.workspaceId = undefined;
    this.digitalPropertyId = undefined;
    return this;
  }

  public useWorkspaceId(workspaceId: string): this {
    this.checkEntityLevel = true;
    this.workspaceId = workspaceId;
    return this;
  }

  public useDigitalPropertyId(digitalPropertyId: string): this {
    this.checkEntityLevel = true;
    this.digitalPropertyId = digitalPropertyId;
    return this;
  }

  public usePermissions(permissions: AclPermissions[]): this {
    this.permissions = permissions;
    return this;
  }

  public useFunctionalActions(functionalActions: FunctionalActions): this {
    this.functionalActions = functionalActions;
    return this;
  }

  abstract check(): boolean | Promise<boolean>;
}
