import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';
import {
  IJiraIntegrationConsumerKeyResponse,
  IJiraIntegrationResponse,
} from '../../../../shared/interfaces/issue-tracking.interface';
import {
  IIssueIntegrationConnection,
  IIssueTrackingIssueStatus,
  IIssueTrackingIssueType,
  IJiraUser,
  IPopulatedIssueIntegrationConnection,
  IRequiredField,
  IStatusMapping,
} from '../../../../shared/interfaces/issue-integration-connection.interface';
import { $issueIntegrationConnection } from '../../../../shared/constants/issue-tracking-connection';
import {
  IConfigureIssueTrackingFields,
  ICreateIssueTrackingIssueRequest,
  IIssueTrackingConnectionCreateRequest,
  IIssueTrackingConnectionReauthorizeRequest,
  IIssueTrackingConnectionRegenerateAuthUrlRequest,
  IIssueTrackingConnectionUpdateWorkspacesRequest,
  IJiraVerifyTokenRequest,
  ILinkIssueTrackingIssueRequest,
  IssueTrackingFieldsConfig,
  IUpdateStatusMappingsRequest,
} from '../../../../shared/interfaces/issue-tracking.requests.interface';
import { IIssueTrackingConnectionRegenerateAuthUrlResponse } from '../../../../shared/interfaces/issue-tracking.responses.interface';
import { IIssueTrackingProject } from '../../../../shared/interfaces/project.interface';
import { Api, ApiQueryOption } from '../../../../shared/constants/api';
import { $taskTransitionType } from '../../../../shared/constants/issue-tracking';
import { IIssueIntegrationStatusMapping } from '../../../../shared/interfaces/issue-integration-status-mapping.interface';
import { IFailedTask } from '../../../../shared/interfaces/task.interface';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

@Injectable({
  providedIn: 'root',
})
export class IssueTrackingRestAPI {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /issue-tracking/issue-integration-connection-config
  public getTenantIssueTrackingConnectionConfig(): Observable<IJiraIntegrationResponse> {
    const url: string = this.restBuilder.create().issueTracking().issueTrackingConnectionConfig().getApiUrl();

    return this.httpClient.get<IJiraIntegrationResponse>(url);
  }

  // GET /issue-tracking/issue-integration-connection/:connectionId/consumer-key
  public getTenantIssueTrackingConnectionConsumerKey(connectionId: string): Observable<IJiraIntegrationConsumerKeyResponse> {
    const url: string = this.restBuilder.create().issueTracking().issueTrackingConnection(connectionId).consumerKey().getApiUrl();

    return this.httpClient.get<IJiraIntegrationConsumerKeyResponse>(url);
  }

  // POST /issue-tracking/issue-integration-connection
  public createIssueTrackingConnection(
    issueTrackingConnectionCreateRequest: IIssueTrackingConnectionCreateRequest,
  ): Observable<IIssueIntegrationConnection> {
    const url: string = this.restBuilder.create().issueTracking().issueTrackingConnection().getApiUrl();

    return this.httpClient.post<IIssueIntegrationConnection>(url, issueTrackingConnectionCreateRequest);
  }

  // POST /issue-tracking/issue-integration-connection/:connectionId/regenerate-auth-url
  public regenerateIssueTrackingAuthorizeUrl(
    connectionId: string,
    issueTrackingConnectionRegenerateAuthUrlRequest: IIssueTrackingConnectionRegenerateAuthUrlRequest,
  ): Observable<IIssueTrackingConnectionRegenerateAuthUrlResponse> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(connectionId)
      .regenerateAuthUrl()
      .getApiUrl();

    return this.httpClient.post<IIssueTrackingConnectionRegenerateAuthUrlResponse>(
      url,
      issueTrackingConnectionRegenerateAuthUrlRequest,
    );
  }

  // POST /issue-tracking/issue-integration-connection/:issueIntegrationConnectionId/verify-token
  public verifyTenantJiraToken(issueTrackingConnectionId: string, request: IJiraVerifyTokenRequest): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(issueTrackingConnectionId)
      .verifyJiraToken()
      .getApiUrl();

    return this.httpClient.post<void>(url, request);
  }

  // GET /issue-tracking/issue-integration-connection
  public getUserIssueTrackingConnections(workspaceId?: string): Observable<IPopulatedIssueIntegrationConnection[]> {
    const url: string = this.restBuilder.create().issueTracking().issueTrackingConnection().getApiUrl();

    let params: HttpParams = new HttpParams();
    if (SharedCommonUtility.notNullishOrEmpty(workspaceId)) {
      params = params.set(ApiQueryOption.workspaceId, workspaceId);
    }

    return this.httpClient.get<IPopulatedIssueIntegrationConnection[]>(url, { params });
  }

  // GET /issue-tracking/issue-integration-connection/:issueIntegrationConnectionId/issue-integration-projects
  public getIssueTrackingProjects(issueTrackingConnectionId: string): Observable<IIssueTrackingProject[]> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(issueTrackingConnectionId)
      .issueTrackingProjects()
      .getApiUrl();

    return this.httpClient.get<IIssueTrackingProject[]>(url);
  }

  // GET /issue-tracking/issue-integration-connection/:connectionId/issue-integration-projects/:projectId/issueTypes
  public getIssueTrackingIssueTypes(connectionId: string, issueTrackingProjectId: string): Observable<IIssueTrackingIssueType[]> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(connectionId)
      .issueTrackingProjects(issueTrackingProjectId)
      .issueTypes()
      .getApiUrl();

    return this.httpClient.get<IIssueTrackingIssueType[]>(url);
  }

  // GET /issue-tracking/issue-integration-connection/:connectionId/issue-integration-projects/:projectId/issueTypes/:issueTypeId/required-fields
  public getRequiredFields(
    connectionId: string,
    issueTrackingProjectId: string,
    issueTrackingIssueTypeId: string,
  ): Observable<IRequiredField[]> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(connectionId)
      .issueTrackingProjects(issueTrackingProjectId)
      .issueTypes(issueTrackingIssueTypeId)
      .requiredFields()
      .getApiUrl();

    return this.httpClient.get<IRequiredField[]>(url);
  }

  // GET /issue-tracking/issue-integration-connection/:connectionId/issue-integration-projects/:projectId/issueTypes/:issueTypeId/configurable-fields
  public getConfigurableFields(
    connectionId: string,
    issueTrackingProjectId: string,
    issueTrackingIssueTypeId: string,
  ): Observable<IRequiredField[]> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(connectionId)
      .issueTrackingProjects(issueTrackingProjectId)
      .issueTypes(issueTrackingIssueTypeId)
      .configurableFields()
      .getApiUrl();

    return this.httpClient.get<IRequiredField[]>(url);
  }

  // GET /issue-tracking/issue-integration-connection/:connectionId/issue-integration-projects/:projectId/user/assignable
  public getJiraUsersByProject(connectionId: string, issueIntegrationProjectKey: string): Observable<IJiraUser[]> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(connectionId)
      .issueTrackingProjects(issueIntegrationProjectKey)
      .user()
      .assignable()
      .getApiUrl();

    return this.httpClient.get<IJiraUser[]>(url);
  }

  // POST /workspaces/:workspaceId/projects/:projectId/issue-tracking/issue-integration-status-mapping
  public updateEapProjectStatusMapping(
    workspaceId: string,
    projectId: string,
    statusMappingFromIssueTracking: IStatusMapping[],
    statusMappingToIssueTracking: IStatusMapping[] = [],
    configurationType: $taskTransitionType,
  ): Observable<IIssueIntegrationStatusMapping> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .projects(projectId)
      .issueTracking()
      .issueTrackingStatusMapping()
      .getApiUrl();

    const request: IUpdateStatusMappingsRequest = {
      [$issueIntegrationConnection.statusMapping]: statusMappingFromIssueTracking,
      [$issueIntegrationConnection.statusMappingToIssueTracking]: statusMappingToIssueTracking,
      [$issueIntegrationConnection.taskTransitionRulesConfigurationType]: configurationType,
    };

    return this.httpClient.post<IIssueIntegrationStatusMapping>(url, request);
  }

  // GET /issue-tracking/issue-integration-connection/:connectionId/issue-integration-projects/:projectId/issue-integration-statuses
  public getIssueTrackingIssueStatusesByProject(
    issueIntegrationProjectId: string,
    issueTrackingConnectionId: string,
  ): Observable<IIssueTrackingIssueStatus[]> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(issueTrackingConnectionId)
      .issueTrackingProjects(issueIntegrationProjectId)
      .issueTrackingIssueStatuses()
      .getApiUrl();

    return this.httpClient.get<IIssueTrackingIssueStatus[]>(url);
  }

  // GET /issue-tracking/issue-integration-connection/:connectionId/issue-integration-projects/:projectId/issueTypes/:issueTypeId/issue-integration-statuses
  public getIssueTrackingIssueStatusesByIssueType(
    issueTrackingConnectionId: string,
    projectId: string,
    issueTypeId: string,
  ): Observable<IIssueTrackingIssueStatus[]> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(issueTrackingConnectionId)
      .issueTrackingProjects(projectId)
      .issueTypes(issueTypeId)
      .issueTrackingIssueStatuses()
      .getApiUrl();

    return this.httpClient.get<IIssueTrackingIssueStatus[]>(url);
  }

  // POST /issue-tracking/issue-integration-connection/:connectionId/issueIntegration/issues?projectId=:projectId
  public createJiraIssue(
    connectionId: string,
    projectId: string,
    createJiraIssueRequest: ICreateIssueTrackingIssueRequest[],
  ): Observable<void> {
    const url: string = this.restBuilder.create().issueTracking().issueTrackingConnection(connectionId).issues().getApiUrl();

    const params: HttpParams = new HttpParams().set(Api.projectId, projectId);

    return this.httpClient.post<void>(url, createJiraIssueRequest, { params: params });
  }

  // PUT /issue-tracking/issue-integration-connection/:connectionId/issueIntegration/issues?projectId=:projectId
  public linkIssue(
    connectionId: string,
    projectId: string,
    linkIssueRequest: ILinkIssueTrackingIssueRequest[],
  ): Observable<void> {
    const url: string = this.restBuilder.create().issueTracking().issueTrackingConnection(connectionId).issues().getApiUrl();
    const params: HttpParams = new HttpParams().set(Api.projectId, projectId);
    return this.httpClient.put<void>(url, linkIssueRequest, { params: params });
  }

  // GET /issue-tracking/issue-integration-connection/:issueIntegrationConnectionId
  public getIssueTrackingConnectionInfo(issueTrackingConnectionId: string): Observable<IPopulatedIssueIntegrationConnection> {
    const url: string = this.restBuilder.create().issueTracking().issueTrackingConnection(issueTrackingConnectionId).getApiUrl();

    return this.httpClient.get<IPopulatedIssueIntegrationConnection>(url);
  }

  // DELETE /issue-tracking/issue-integration-connection/:issueIntegrationConnectionId
  public removeTenantIssueTrackingConnection(issueTrackingConnectionId: string): Observable<void> {
    const url: string = this.restBuilder.create().issueTracking().issueTrackingConnection(issueTrackingConnectionId).getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // POST /issue-tracking/issue-integration-connection/:issueIntegrationConnectionId/resync
  public resyncIssueTrackingConnection(issueTrackingConnectionId: string): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(issueTrackingConnectionId)
      .resync()
      .getApiUrl();

    return this.httpClient.post<void>(url, {});
  }

  // GET /workspaces/:workspaceId/projects/:projectId/issueTracking/issueTypes/:issueTrackingIssueTypeId/configFields
  public getConfiguredFieldsForProject(
    workspaceId: string,
    projectId: string,
    issueTrackingIssueTypeId: string,
  ): Observable<IssueTrackingFieldsConfig> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .projects(projectId)
      .issueTracking()
      .issueTypes(issueTrackingIssueTypeId)
      .configureFields()
      .getApiUrl();

    return this.httpClient.get<IssueTrackingFieldsConfig>(url);
  }

  // PUT /issue-tracking/issue-integration-connection/:connectionId/workspaces
  public updateIssueTrackingConnectionWorkspaces(
    issueTrackingConnectionId: string,
    issueTrackingConnectionUpdateWorkspacesRequest: IIssueTrackingConnectionUpdateWorkspacesRequest,
  ): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(issueTrackingConnectionId)
      .workspaces()
      .getApiUrl();

    return this.httpClient.put<void>(url, issueTrackingConnectionUpdateWorkspacesRequest);
  }

  // PUT /workspaces/:workspaceId/projects/:projectId/issueTracking/configureFields
  public configureFieldsForProject(
    workspaceId: string,
    projectId: string,
    newFields: IConfigureIssueTrackingFields,
  ): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .projects(projectId)
      .issueTracking()
      .configureFields()
      .getApiUrl();

    return this.httpClient.put<void>(url, newFields);
  }

  // PUT /issue-tracking/issue-integration-connection/:issueIntegrationConnectionId/reauthorize-connection
  public reauthorizeIssueTrackingConnection(
    issueTrackingConnectionId: string,
    issueTrackingConnectionReauthorizeRequest: IIssueTrackingConnectionReauthorizeRequest,
  ): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(issueTrackingConnectionId)
      .reauthorizeConnection()
      .getApiUrl();

    return this.httpClient.put<void>(url, issueTrackingConnectionReauthorizeRequest);
  }

  public getLabels(issueTrackingConnectionId: string, query: string, fieldName: string): Observable<string[]> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(issueTrackingConnectionId)
      .labels()
      .getApiUrl();

    return this.httpClient.get<string[]>(url, {
      params: {
        [ApiQueryOption.query]: query,
        [ApiQueryOption.fieldName]: fieldName,
      },
    });
  }

  public getFailedTasks(issueTrackingConnectionId: string): Observable<IFailedTask[]> {
    const url: string = this.restBuilder
      .create()
      .issueTracking()
      .issueTrackingConnection(issueTrackingConnectionId)
      .failedTasks()
      .getApiUrl();

    return this.httpClient.get<IFailedTask[]>(url);
  }
}
