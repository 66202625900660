import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { DsInputStates } from '@levelaccess/design-system';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Api } from '../../../../../shared/constants/api';
import { $digitalProperty } from '../../../../../shared/constants/digital-properties';
import { FunctionalArea, SecureAction } from '../../../../../shared/constants/security';
import { $workspace } from '../../../../../shared/constants/workspace';
import {
  IDigitalPropertiesListResponse,
  IDigitalPropertyListItem,
} from '../../../../../shared/interfaces/digital-property.interface';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../../shared/utils/linked-property.utility';
import { AclSecurityAdapter } from '../../../services/acl.service';
import { BusMessageChannels, BusMessageService } from '../../../services/bus-message.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { INavMenuItem, NavigationItem } from '../../../services/navigation/nav-structure';
import { NavService } from '../../../services/navigation/nav.service';
import { UserAclService } from '../../../services/user-acl.service';
import { UserPropertyService } from '../../../services/user-property.service';

@Component({
  selector: 'app-websites-apps-select',
  templateUrl: 'websites-apps-select.component.html',
})
export class WebsitesAppsSelectComponent implements OnInit, OnDestroy {
  protected readonly DsInputStates: typeof DsInputStates = DsInputStates;

  private _subscriptions: Subscription;
  private _selectedDigitalPropertyId: string;
  private _workspaceChanged: boolean;
  private _selectedWorkspaceId: string;

  public selectedWebApp: string;
  public readonly portfolioValue: string = 'website_app_portfolio_option';
  public selectWebAppTooltip: string;
  public $digitalProperty: typeof $digitalProperty = $digitalProperty;
  public digitalProperties$: Observable<IDigitalPropertyListItem[]>;
  public userHasDashboardAccess: boolean;

  @ViewChild('webAppSelect', { static: false }) webAppSelect: ElementRef;

  @Input() public label: string;
  @Input() public state: DsInputStates;

  @Output() public webAppChanged: EventEmitter<void> = new EventEmitter();

  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private activatedRoute: ActivatedRoute,
    private busMessageService: BusMessageService,
    private userPropertyService: UserPropertyService,
    private navService: NavService,
    private userAclService: UserAclService,
  ) {
    this._subscriptions = new Subscription();
    this.userHasDashboardAccess = false;
  }

  private getInitialRouteForUser(): string[] {
    return this.userHasDashboardAccess ? [`/${Api.dashboard}`] : [`/${Api.manual_evaluations}`];
  }

  public selectWebAppChanged(value: string): void {
    if (value === this.portfolioValue) {
      this.selectedWebApp = this.portfolioValue;
      this.selectWebAppTooltip = null;

      this.router
        .navigate([`/${Api.portfolio}`])
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

      this.webAppChanged.emit();
    } else {
      const handleRouteChange = (digitalProperty: IDigitalPropertyListItem): void => {
        if (SharedCommonUtility.isNullish(digitalProperty)) {
          return;
        }

        // Ellipsis begin at the 26th character
        this.selectWebAppTooltip =
          digitalProperty[$digitalProperty.name].length > 25 ? digitalProperty[$digitalProperty.name] : null;

        const routeForUser: string[] = this.getInitialRouteForUser();

        const extras: NavigationExtras = {
          queryParams: LinkedPropertyUtility.getLinkedPropertyQueryParam(
            digitalProperty[$digitalProperty._id],
            digitalProperty[$digitalProperty.workspace]._id,
          ),
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          skipLocationChange: false,
        };
        this.router
          .navigate(routeForUser, extras)
          .then(() => this.busMessageService.to(BusMessageChannels.userSwitchedToDigitalProperty).next())
          .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

        this.webAppChanged.emit();
      };

      this._subscriptions.add(
        this.digitalProperties$
          .pipe(
            map((digitalProperties: IDigitalPropertyListItem[]): IDigitalPropertyListItem => {
              return digitalProperties.find((dp: IDigitalPropertyListItem): boolean => dp[$digitalProperty._id] === value);
            }),
          )
          .subscribe(handleRouteChange.bind(this)),
      );
    }
  }

  private _initializeSelectWebApp(): void {
    const setSelectedWebApp = (navMenuItem: INavMenuItem): void => {
      const portfolioItem: INavMenuItem = navMenuItem?.children?.find(
        (child: INavMenuItem): boolean => child.id === NavigationItem.websites_and_apps_portfolio,
      );
      const isPortfolioViewActive: boolean = portfolioItem?.isActive;
      this.selectedWebApp = isPortfolioViewActive ? this.portfolioValue : this._selectedDigitalPropertyId;
    };

    this._subscriptions.add(this.navService.activeSecondLevelMenuItem$().subscribe(setSelectedWebApp.bind(this)));
  }

  private _handleRouteChange(event: Event): void {
    if (event instanceof NavigationEnd) {
      this._initializeSelectWebApp();

      if (this._workspaceChanged) {
        this._loadDigitalProperties();
      }
    }
  }

  private _loadDigitalProperties(): void {
    this.digitalProperties$ = this.userPropertyService.getAvailableDigitalProperties().pipe(
      map((response: IDigitalPropertiesListResponse): IDigitalPropertyListItem[] => {
        return response.items.filter((dp: IDigitalPropertyListItem) => {
          return dp[$digitalProperty.workspace][$workspace._id] === this._selectedWorkspaceId;
        });
      }),
      map((digitalProperties: IDigitalPropertyListItem[]): IDigitalPropertyListItem[] => {
        return digitalProperties.sort((a: IDigitalPropertyListItem, b: IDigitalPropertyListItem) =>
          a[$digitalProperty.name].localeCompare(b[$digitalProperty.name]),
        );
      }),
    );
  }

  ngOnInit(): void {
    this._subscriptions.add(this.router.events.subscribe(this._handleRouteChange.bind(this)));
    this._subscriptions.add(
      this.activatedRoute.queryParams
        .pipe(
          map(LinkedPropertyUtility.fromLinkedPropertyQueryParam),
          map((linkedPropertyData: ILinkedPropertyData): void => {
            this._selectedDigitalPropertyId = linkedPropertyData[Api.digitalPropertyId];
            this._selectedWorkspaceId = linkedPropertyData[Api.workspaceId];
            this._selectedDigitalPropertyId = linkedPropertyData[Api.digitalPropertyId];
          }),
        )
        .subscribe(this._initializeSelectWebApp.bind(this)),
    );

    this._loadDigitalProperties();
    this._subscriptions.add(
      this.busMessageService.from(BusMessageChannels.digitalPropertyChange).subscribe({
        next: this._loadDigitalProperties.bind(this),
      }),
    );

    this._subscriptions.add(
      this.userAclService
        .createCheckAccessForCurrentUser()
        .pipe(
          map((adapter: AclSecurityAdapter): boolean =>
            adapter
              .useWorkspaceFromUser()
              .useDigitalPropertyFromUser()
              .useFunctionalActions({
                [FunctionalArea.gr_dashboard]: [SecureAction.read],
              })
              .check(),
          ),
        )
        .subscribe((permission: boolean) => {
          this.userHasDashboardAccess = permission;
        }),
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
