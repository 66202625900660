import { Directive, HostListener } from '@angular/core';

import { CommonUtility } from '../../utility/common.utility';

const DEFAULT_DISABLED_STATE_TIMEOUT = 500; // in ms

@Directive({
  selector: '[appNoDoubleActivate]',
})
export class NoDoubleActivateDirective {
  @HostListener('click', ['$event'])
  public clickEvent(event: Event): void {
    const eventTarget: HTMLElement = event.target || (event.srcElement as any);

    const setDisabledState = (): void => {
      eventTarget.setAttribute('disabled', 'disabled');
    };

    const removeDisabledState = (): void => {
      eventTarget.removeAttribute('disabled');
      CommonUtility.setFocusToElement(null, eventTarget);
    };

    window.setTimeout(setDisabledState);
    window.setTimeout(removeDisabledState, DEFAULT_DISABLED_STATE_TIMEOUT);
  }
}
