import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IUserClient } from '../../../interfaces/user.interface';
import { UserService } from '../../user.service';
import { AclService } from '../../acl.service';
import { AclPermissions, AclScope } from '../../../../../shared/constants/acl';
import { ErrorHandlerService } from '../../error-handler.service';
import { Api } from '../../../../../shared/constants/api';
import { AclModel } from '../../../models/acl.model';

@Injectable()
export class AdminGuard {
  constructor(
    private userService: UserService,
    private router: Router,
    private aclService: AclService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const onUserDataSuccess = (userData: IUserClient): boolean => {
      return this.checkUserAccessToAdminSection(userData, next, state);
    };

    const onUserDataError = (err: any, caught: Observable<boolean>): Observable<boolean> => {
      this.router.navigate([`/${Api.login}`]).catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

      return throwError(false);
    };

    return this.userService.getAuthenticatedUserProfile().pipe(map(onUserDataSuccess), catchError(onUserDataError));
  }

  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const onUserDataSuccess = (userData: IUserClient): boolean => {
      if (userData === null) {
        this.router.navigate([`/${Api.login}`]).catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

        return false;
      }

      return this.checkUserAccessToAdminSection(userData, next, state);
    };

    const onUserDataError = (err: any, caught: Observable<boolean>): Observable<boolean> => {
      this.router.navigate([`/${Api.login}`]).catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

      return of(false);
    };

    return this.userService.getMeProfile().pipe(map(onUserDataSuccess), catchError(onUserDataError));
  }

  private checkUserAccessToAdminSection(
    userData: IUserClient,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const hasUserAccessToAdminSection: boolean = this.hasUserAccessToAdminSection(userData);

    if (hasUserAccessToAdminSection && this.userService.isAuthenticated()) {
      return true;
    }

    this.router
      .navigate([`/${Api.forbidden}`], { queryParams: { url: state.url, title: next.data.title } })
      .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

    return false;
  }

  private hasUserAccessToAdminSection(userData: IUserClient): boolean {
    return this.aclService
      .createAccessCheck(userData)
      .usePermissions(AclModel[AclScope.adminPanel] as AclPermissions[])
      .useOptions({ requireFunctionalActions: false })
      .check();
  }
}
