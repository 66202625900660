import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';

import { EN_US } from './lang-en';
import { config } from '../../environments/config.shared';
import { RestService } from '../services/rest.service';
import { UserService } from '../services/user.service';
import { IUserClient } from '../interfaces/user.interface';
import { StatusService } from '../services/status.service';
import { $user } from '../../../shared/constants/user';
import { CommonUtility } from '../utility/common.utility';
import { CookieService } from '../services/cookie.service';
import { ICountries } from '../../../shared/interfaces/countries.interface';
import { ITranslationClientConfig, ITranslationsClient } from '../../../shared/interfaces/translations.interface';
import { SharedTextUtility } from '../../../shared/utils/text.utility';
import { ILanguageItem } from '../interfaces/translate.interface';
import { IUserCountryResponse } from '../../../shared/interfaces/user.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

const TRANSLATIONS_VALUE_PLACEHOLDER: string = '%';

type StringOrNumber = string | number;

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private currentLanguage: BehaviorSubject<string>;
  private translations: { [key: string]: ITranslationsClient };
  private availableTranslations: Map<string | 'default', ITranslationClientConfig>; // Map of locale=>config

  public currentLanguage$: Observable<string>;
  public localeCookie: string;

  constructor(
    private cookieService: CookieService,
    private domSanitizer: DomSanitizer,
    private restService: RestService,
    private userService: UserService,
    private statusService: StatusService,
  ) {
    this.availableTranslations = new Map<string | 'default', ITranslationClientConfig>();
    this.localeCookie = 'locale';
    this.currentLanguage = new BehaviorSubject<string>(this.defaultLanguage);
    this.currentLanguage$ = this.currentLanguage.asObservable();
    this.translations = {
      [EN_US.ID]: EN_US.STRINGS,
    };
  }

  private translate(key: string, language: string = this.currentLang): string {
    if (key === '') {
      return key;
    }

    if (this.translations[language] && this.translations[language][key]) {
      return this.translations[language][key];
    }

    if (this.translations[this.defaultLanguage] && this.translations[this.defaultLanguage][key]) {
      console.warn(`Key not translated in ${language}: ${key}`);
      return this.translations[this.defaultLanguage][key];
    }

    console.warn(`Key not translated at all: ${key}`);
    return key;
  }

  private replaceHtml(translation: string, words: string | string[] = ''): SafeHtml {
    const _translation: string = TranslateService.replace(translation, words);
    return this.domSanitizer.bypassSecurityTrustHtml(_translation);
  }

  private convertToStrings = (value: number | string): string => {
    return String(value);
  };

  private use(lang: string): void {
    this.currentLanguage.next(lang);
  }

  private async setTranslations(
    languageRegion: string,
    _config: ITranslationClientConfig,
    translations: { [key: string]: any },
  ): Promise<void> {
    try {
      if (typeof translations === 'undefined' || (translations && typeof _config === 'undefined')) {
        console.error(
          '[TranslateService.initializeTranslations.resolveTranslations.setTranslations]',
          languageRegion,
          translations,
        );
        this.use(this.defaultLanguage);
      } else {
        this.setupTranslations(_config, translations);
        this.use(_config.locale);
      }
    } catch (err) {
      this.use(this.defaultLanguage);
    }
    this.statusService.setAreTranslationsReady(true);
  }

  private setupTranslations(_config: ITranslationClientConfig, translations: Pick<ITranslationsClient, string>): void {
    this.translations[_config.locale] = { _config };

    const setTranslationKey = (key: string): void => {
      if (typeof translations[key] === 'string') {
        this.translations[_config.locale][key] = translations[key];
        return;
      }
      this.translations[_config.locale][key] = translations[key].value;
      return;
    };
    Object.keys(translations).forEach(setTranslationKey);
  }

  private async getUserLanguageRegion(): Promise<string> {
    const user: IUserClient = await this.userService
      .getMeProfile()
      .toPromise()
      .catch(() => null);
    let languageRegion: string;
    if (SharedCommonUtility.isNullish(user)) {
      languageRegion = this.cookieService.get(this.localeCookie)
        ? this.cookieService.get(this.localeCookie)
        : CommonUtility.getPreferredAvailableLanguageAndRegion();
    } else {
      languageRegion =
        typeof user[$user.userLanguage] === 'string' && user[$user.userLanguage].length > 0
          ? user[$user.userLanguage]
          : config.locale.default;
    }

    languageRegion = this.getSupportedLanguage(languageRegion);

    if (SharedCommonUtility.isNullish(languageRegion)) {
      languageRegion = this.defaultLanguage;
    }

    return languageRegion;
  }

  private async loadAvailableTranslations(): Promise<void> {
    const translationsList: ITranslationClientConfig[] = await this.restService.getListAvailableTranslations().toPromise();
    const setAvailableTranslations = (conf: ITranslationClientConfig): void => {
      this.availableTranslations.set(conf.locale, conf);
      if (conf.isDefault) {
        this.availableTranslations.set('default', conf);
      }
    };
    translationsList.forEach(setAvailableTranslations);
  }

  public static replace(translation: string, words: string | string[] = ''): string {
    const values: string[] = Array.isArray(words) ? words : [words];
    return SharedTextUtility.replacePlaceholder(translation, values, TRANSLATIONS_VALUE_PLACEHOLDER);
  }

  public get currentLang(): string {
    return this.currentLanguage.getValue();
  }

  public get defaultLanguage(): string {
    if (this.availableTranslations.has('default')) {
      return this.availableTranslations.get('default').locale;
    }
    return EN_US.ID;
  }

  public isTranslationAvailableForKey(key: string): boolean {
    if (this.translations[this.currentLang] && this.translations[this.currentLang][key]) {
      return true;
    }

    return false;
  }

  public getSupportedLanguage(userLanguage: string): string | null {
    if (typeof userLanguage === 'string' && userLanguage.length > 0) {
      const lang: string = userLanguage.toLowerCase();
      if (this.availableTranslations.has(lang)) {
        return this.availableTranslations.get(lang).locale;
      }
      const [region] = lang.split('-');
      const startsWithLang = (langKey: string): boolean => langKey.startsWith(region);
      const firstRegion: string = Array.from(this.availableTranslations.keys()).find(startsWithLang);
      return typeof firstRegion === 'undefined' ? null : this.availableTranslations.get(firstRegion).locale;
    }
    return undefined;
  }

  public async initializeTranslations(): Promise<void> {
    await this.loadAvailableTranslations();

    let languageRegion: string = await this.getUserLanguageRegion();
    const { _config, ...translations } = await this.translationsData(languageRegion).toPromise();

    try {
      await this.setTranslations(languageRegion, _config, translations);
    } catch (error) {
      console.error('[TranslateService.initializeTranslations.onUserProfileError]', error);
      languageRegion = this.getSupportedLanguage(CommonUtility.getPreferredAvailableLanguageAndRegion().toLowerCase());
      await this.setTranslations(languageRegion, _config, translations);
    }
  }

  public instant(key: string, words?: StringOrNumber | StringOrNumber[], language?: string): string {
    const translation: string = this.translate(key, language);
    let _words: string | string[] = '';

    if (Array.isArray(words)) {
      _words = (words as Array<any>).map(this.convertToStrings.bind(this));
    } else if (typeof words === 'number') {
      _words = String(words);
    } else if (typeof words === 'string') {
      _words = words;
    }

    if (_words.length === 0) {
      return translation;
    }

    return TranslateService.replace(translation, _words);
  }

  public instantHtml(key: string, words?: string | string[] | number | number[]): SafeHtml {
    const translation: string = this.translate(key);
    let _words: string | string[] = '';

    if (Array.isArray(words)) {
      _words = (words as Array<any>).map(this.convertToStrings.bind(this));
    } else if (typeof words === 'number') {
      _words = String(words);
    } else if (typeof words === 'string') {
      _words = words;
    }

    if (_words.length === 0) {
      return translation;
    }

    return this.replaceHtml(translation, _words);
  }

  public getTranslations(locale: string): Observable<ITranslationsClient> {
    return this.restService.getTranslations(locale);
  }

  public translationsData(locale: string): Observable<ITranslationsClient> {
    return this.restService.translationsData(locale);
  }

  public saveAll(locale: string, data: ITranslationsClient): Observable<any> {
    return this.restService.saveAllTranslations(locale, data);
  }

  public getListAvailableTranslations(): Observable<ITranslationClientConfig[]> {
    return this.restService.getListAvailableTranslations();
  }

  public getCountriesListForClient(): Observable<IUserCountryResponse> {
    return this.restService.getCountriesListForClient();
  }

  public async getCountriesList(): Promise<ICountries> {
    const action = (resolve: any, reject: any): void => {
      const processLanguages = (data: any): void => {
        const languagesData: ICountries = data.default;
        resolve(languagesData);
      };

      import('countries-list').then(processLanguages).catch(reject);
    };

    return new Promise(action);
  }

  public getAllLanguages(countriesList: ICountries): ILanguageItem[] {
    const languagesAll: { [key: string]: { name: string; native: string; rtl?: number } } = countriesList.languagesAll;

    const alpabetically: (languageA: any, languageB: any) => number = (languageA: any, languageB: any): number => {
      const nA: string = languagesAll[languageA].name;
      const nB: string = languagesAll[languageB].name;

      if (nA < nB) {
        return -1;
      } else if (nA > nB) {
        return 1;
      }
      return 0;
    };

    const createLanguageItem: any = (langCode: string): ILanguageItem => {
      const langData: ILanguageItem = {
        code: langCode,
        name: languagesAll[langCode].name,
        nativeName: languagesAll[langCode].native,
      };

      return langData;
    };

    const languages: ILanguageItem[] = Object.keys(languagesAll).sort(alpabetically).map(createLanguageItem);

    return languages;
  }

  public getLanguageCode(event: any, countriesList: ICountries): string {
    const languagesAll: { [key: string]: { name: string; native: string; rtl?: number } } = countriesList.languagesAll;
    const target: HTMLInputElement = event.target;
    const languageName: string = target.value.split(/\s\(.*\)/)[0];

    const findLanguageCode: any = (_langCode: string): boolean => {
      return languagesAll[_langCode].name === languageName;
    };

    const langCode: string = Object.keys(languagesAll).find(findLanguageCode);

    return langCode;
  }

  public getLanguageDetails(langCode: string, countriesList: ICountries): string {
    const languagesAll: { [key: string]: { name: string; native: string; rtl?: number } } = countriesList.languagesAll;
    const language: string[] = langCode.split('-');

    if (language[0].length === 0) {
      console.warn(
        '[CommonUtility.getLanguageDetails] Passed langCode as an empty string. This means that the user does not have defined language (userLanguage).',
      );
      return '';
    }

    return `${languagesAll[language[0]].name} (${languagesAll[language[0]].native})`;
  }
}
