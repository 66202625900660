import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { RestService } from './rest.service';
import { $digitalPropertyType, DigitalPropertyTypeNames } from '../../../shared/constants/digital-properties';
import {
  IDigitalPropertyMonitoring,
  ICreateDigitalPropertyRequest,
  IDigitalPropertiesMonitoringResponse,
  IDigitalPropertiesPopulatedResponse,
  IDigitalProperty,
  IDigitalPropertyDetails,
  IDigitalPropertyLatestScanResults,
  IDigitalPropertyMonitoringResponse,
  IDigitalPropertyOverview,
  IDigitalPropertyType,
  IQuickMonitoringSetupRequest,
  IValidateBulkUpload,
} from '../../../shared/interfaces/digital-property.interface';
import { WorkspacesRestAPI } from './rest/workspaces.api';
import { AdminRestAPI } from './rest/admin.api';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { ErrorMessageService } from './error-message.service';
import { TranslateService } from '../translate/translate.service';
import { A11yService } from './a11y.service';
import { IDigitalPropertyMetrics } from '../../../shared/interfaces/digital-property-metrics.interface';

@Injectable({
  providedIn: 'root',
})
export class DigitalPropertyService {
  constructor(
    private restService: RestService,
    private workspaceRestAPI: WorkspacesRestAPI,
    private adminRestAPI: AdminRestAPI,
    private errorMessageService: ErrorMessageService,
    private translateService: TranslateService,
    private a11yService: A11yService,
  ) {}

  private handleError(message: string, response: HttpErrorResponse): void {
    const errorMessage: string = this.errorMessageService.getGlobalErrorResponse(response);
    const appMessage: string = this.translateService.instant(message);

    this.a11yService.setMessage(appMessage + ': ' + errorMessage);

    console.error('[EditDigitalPropertyComponent.handleError: ' + appMessage + ']', response, errorMessage);
  }

  private onGetDigitalPropertyError(response: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.handleError('get_digital_property_error', response);
    return throwError(response);
  }

  public getAllDigitalProperties(limit: number, skip: number): Observable<IDigitalPropertiesPopulatedResponse> {
    return this.adminRestAPI.getAllDigitalProperties(limit, skip);
  }

  public getWorkspaceDigitalProperties(
    workspaceId: string,
    limit: number = 0,
    skip: number = 0,
    populateRemediationProgress: boolean = true,
    populateMonitoring: boolean = true,
  ): Observable<IDigitalPropertiesMonitoringResponse> {
    return this.workspaceRestAPI.getWorkspaceDigitalProperties(
      workspaceId,
      limit,
      skip,
      populateRemediationProgress,
      populateMonitoring,
    );
  }

  public getAllDigitalPropertiesByWorkspaceId(workspaceId: string): Observable<IDigitalProperty[]> {
    return this.workspaceRestAPI.getAllDigitalPropertiesByWorkspaceId(workspaceId);
  }

  public getDigitalProperty(workspaceId: string, digitalPropertyId: string): Observable<IDigitalPropertyDetails> {
    return this.workspaceRestAPI
      .getDigitalProperty(workspaceId, digitalPropertyId)
      .pipe(catchError(this.onGetDigitalPropertyError.bind(this)));
  }

  public getDigitalPropertyMonitoring(
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IDigitalPropertyMonitoringResponse> {
    return this.workspaceRestAPI
      .getDigitalPropertyMonitoring(workspaceId, digitalPropertyId)
      .pipe(catchError(this.onGetDigitalPropertyError.bind(this)));
  }

  public getMetrics(workspaceId: string, digitalPropertyId: string): Observable<IDigitalPropertyMetrics> {
    return this.workspaceRestAPI.getMetricsForDigitalProperty(workspaceId, digitalPropertyId);
  }

  public getDigitalPropertyTypes(): Observable<IDigitalPropertyType[]> {
    return this.restService.getDigitalPropertyTypes();
  }

  public createDigitalProperty(workspaceId: string, formData: ICreateDigitalPropertyRequest): Observable<IDigitalProperty> {
    return this.workspaceRestAPI.createWorkspaceDigitalProperty(workspaceId, formData);
  }

  public validateDigitalPropertyBulkUpload(workspaceId: string, fileForm: FormData): Observable<IValidateBulkUpload> {
    return this.workspaceRestAPI.validateDigitalPropertyBulkUpload(workspaceId, fileForm);
  }

  public downloadDigitalPropertyTemplate(workspaceId: string): Observable<void> {
    return this.workspaceRestAPI.downloadDigitalPropertyTemplate(workspaceId);
  }

  public createBulkDigitalProperty(workspaceId: string, fileForm: FormData): Observable<number> {
    return this.workspaceRestAPI.createBulkDigitalProperty(workspaceId, fileForm);
  }

  public setQuickMonitoring(
    workspaceId: string,
    digitalPropertyId: string,
    quickSetup: IQuickMonitoringSetupRequest,
  ): Observable<void> {
    return this.workspaceRestAPI.setDigitalPropertyQuickMonitoring(workspaceId, digitalPropertyId, quickSetup);
  }

  public updateMonitoring(
    workspaceId: string,
    digitalPropertyId: string,
    monitoring: IDigitalPropertyMonitoring,
  ): Observable<void> {
    return this.workspaceRestAPI.updateDigitalPropertyMonitoring(workspaceId, digitalPropertyId, monitoring);
  }

  public removeMonitoring(workspaceId: string, digitalPropertyId: string): Observable<void> {
    return this.workspaceRestAPI.removeDigitalPropertyMonitoring(workspaceId, digitalPropertyId);
  }

  public getDigitalPropertyLatestAndPreviousMonitoringScanResults(
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IDigitalPropertyLatestScanResults> {
    return this.workspaceRestAPI
      .getDigitalPropertyLatestAndPreviousMonitoringScanResults(workspaceId, digitalPropertyId)
      .pipe(catchError(this.onGetDigitalPropertyError.bind(this)));
  }

  public updateDigitalProperty(formData: IDigitalProperty, id: string): Observable<IDigitalPropertyDetails> {
    return this.workspaceRestAPI.updateDigitalProperty(formData, id);
  }

  public removeDigitalProperty(id: string, workspaceId: string): Observable<void> {
    return this.workspaceRestAPI.removeDigitalProperty(id, workspaceId);
  }

  public getDomainRestrictions(restrictions: string[]): RegExp[] {
    if (Array.isArray(restrictions)) {
      return restrictions.map(SharedCommonUtility.urlPatternToRegexp);
    }

    return [];
  }

  public isWebsiteType(propertyType: IDigitalPropertyType): boolean {
    return propertyType[$digitalPropertyType.canRestrictDomain] === true;
  }

  public isMobileType(propertyType: IDigitalPropertyType): boolean {
    return ([DigitalPropertyTypeNames.ios, DigitalPropertyTypeNames.android] as string[]).includes(
      propertyType[$digitalPropertyType.name],
    );
  }

  public archive(workspaceId: string, digitalProperty: string): Observable<void> {
    return this.workspaceRestAPI.archiveProperty(workspaceId, digitalProperty);
  }

  public restore(workspaceId: string, digitalProperty: string): Observable<void> {
    return this.workspaceRestAPI.restoreProperty(workspaceId, digitalProperty);
  }

  public existingKeyValues(workspaceId: string, key: keyof IDigitalProperty, propertyId?: string): Observable<string[]> {
    return this.workspaceRestAPI.existingKeyValues(workspaceId, key, propertyId);
  }

  public getDigitalPropertyOverview(workspaceId: string, dpId: string): Observable<IDigitalPropertyOverview> {
    return this.workspaceRestAPI.getDigitalPropertyOverview(workspaceId, dpId);
  }
}
