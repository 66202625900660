import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map as mapRx, startWith, tap } from 'rxjs/operators';
import { chain, orderBy } from 'lodash';

import { PermissionsTableComponent } from '../permissions-table.component';
import { ITableColumn, ITableRow, SortEvent } from '../../../table/ngb-table/utilities/ngb-table.interface';
import { TranslateService } from '../../../../translate/translate.service';
import {
  ISecurityGroup,
  ISecurityGroupsResponse,
  ISecurityGroupWithEntityNames,
} from '../../../../../../shared/interfaces/security-group.interface';
import {
  $securityEntity,
  $securityGroup,
  SecurityEntityLevel,
  SecurityGroupOrigin,
  SecurityGroupTemplateNames,
} from '../../../../../../shared/constants/security-group';
import { SecurityGroupService } from '../../../../services/security-group.service';
import { NgbTableUtilities } from '../../../table/ngb-table/utilities/ngb-table.utilities';
import { $sortingOrder } from '../../../../../../shared/constants/sort';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { AngularUtility } from '../../../../utility/angular.utility';
import { SharedTextUtility } from '../../../../../../shared/utils/text.utility';

interface IWorkspaceOption {
  workspaceId: string;
  workspaceName: string;
}

interface ISearchAndFilterForm {
  [SearchAndFilterFormFields.workspaceFilter]: FormControl<string>;
  [SearchAndFilterFormFields.digitalPropertySearch]: FormControl<string>;
}

export enum TableColumns {
  websiteApp = 'website-app',
  workspace = 'workspace',
  isDigitalPropertyUser = 'is-digital-property-user',
}

export enum SearchAndFilterFormFields {
  workspaceFilter = 'workspace-filter',
  digitalPropertySearch = 'digital-property-search',
}

@Component({
  selector: 'app-digital-property-permissions-table',
  templateUrl: './digital-property-permissions-table.component.html',
  styleUrls: ['./digital-property-permissions-table.component.scss'],
})
export class DigitalPropertyPermissionsTableComponent extends PermissionsTableComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  public searchAndFilterForm: FormGroup<ISearchAndFilterForm>;
  public workspaceFilterOptions: IWorkspaceOption[];

  public SearchAndFilterFormFields: typeof SearchAndFilterFormFields = SearchAndFilterFormFields;

  constructor(
    formBuilder: FormBuilder,
    private translateService: TranslateService,
    private securityGroupService: SecurityGroupService,
  ) {
    super(formBuilder);

    this.subscriptions = new Subscription();

    this.searchAndFilterForm = new FormGroup<ISearchAndFilterForm>({
      [SearchAndFilterFormFields.workspaceFilter]: new FormControl<string>(''),
      [SearchAndFilterFormFields.digitalPropertySearch]: new FormControl<string>(''),
    });

    this.workspaceFilterOptions = [];

    const columns: Record<string, ITableColumn> = {
      [TableColumns.websiteApp]: {
        translationKey: 'website_app',
        sortingEnabled: true,
        styles: {
          width: '15rem',
        },
      },
      [TableColumns.workspace]: {
        translationKey: 'workspace',
        sortingEnabled: true,
        styles: {
          width: '18rem',
        },
      },
      [TableColumns.isDigitalPropertyUser]: {
        translationKey: 'website_app_user',
      },
    };

    this.tableConfig = {
      columns,
      caption: this.translateService.instant('table_caption_digital_properties_permissions'),
    };

    this.sortOption$.next({
      column: TableColumns.workspace,
      direction: $sortingOrder.asc,
    });
  }

  protected get workspaceFilter(): FormControl<string> {
    return this.searchAndFilterForm.get(SearchAndFilterFormFields.workspaceFilter) as FormControl<string>;
  }

  protected get websiteAppSearch(): FormControl<string> {
    return this.searchAndFilterForm.get(SearchAndFilterFormFields.digitalPropertySearch) as FormControl<string>;
  }

  protected buildObservables(): void {
    super.buildObservables();

    const filterDigitalPropertyUserGroups = (group: ISecurityGroup): boolean =>
      group[$securityGroup.name] === SecurityGroupTemplateNames.website_app_user;

    const digitalPropertyGroups$: Observable<ISecurityGroupWithEntityNames[]> = this.securityGroupService
      .groupsWithEntityNames({
        skip: 0,
        limit: 9999,
        includeStaffGroups: false,
        entityLevel: SecurityEntityLevel.digitalProperty,
        origin: SecurityGroupOrigin.predefined,
        skipOrganizationAdmin: true,
      })
      .pipe(
        mapRx(({ groups }: ISecurityGroupsResponse<ISecurityGroupWithEntityNames>): ISecurityGroupWithEntityNames[] =>
          groups.filter(filterDigitalPropertyUserGroups),
        ),
        tap((groups: ISecurityGroupWithEntityNames[]): void => this.onPermissionsLoaded.emit(groups)),
        AngularUtility.shareRef(),
      );

    const workspaceFilterOptions$: Observable<IWorkspaceOption[]> = digitalPropertyGroups$.pipe(
      mapRx((groups: ISecurityGroupWithEntityNames[]): IWorkspaceOption[] =>
        chain(groups)
          .map(
            (group: ISecurityGroupWithEntityNames): IWorkspaceOption => ({
              workspaceId: group[$securityGroup.entities][0][$securityEntity.workspaceId],
              workspaceName: group.workspaceNames.join(),
            }),
          )
          .uniqBy((workspaceOption: IWorkspaceOption): string => workspaceOption.workspaceId)
          .value(),
      ),
    );

    this.subscriptions.add(
      workspaceFilterOptions$.subscribe((options: IWorkspaceOption[]): void => {
        this.workspaceFilterOptions = options;
      }),
    );

    const workspaceFilter$: Observable<string> = this.workspaceFilter.valueChanges.pipe(
      startWith(this.workspaceFilter.value),
      distinctUntilChanged(),
      tap(() => this.page$.next(1)),
    );

    const filteredGroups$: Observable<ISecurityGroupWithEntityNames[]> = combineLatest([
      digitalPropertyGroups$,
      workspaceFilter$,
    ]).pipe(
      mapRx(([groups, workspaceId]: [ISecurityGroupWithEntityNames[], string]): ISecurityGroupWithEntityNames[] => {
        if (SharedCommonUtility.isNullishOrEmpty(workspaceId)) {
          return groups;
        }

        return groups.filter(
          (group: ISecurityGroupWithEntityNames): boolean =>
            group[$securityGroup.entities][0][$securityEntity.workspaceId] === workspaceId,
        );
      }),
    );

    const websiteAppSearch$: Observable<string> = this.websiteAppSearch.valueChanges.pipe(
      startWith(this.websiteAppSearch.value),
      distinctUntilChanged(),
      tap(() => this.page$.next(1)),
    );

    const searchedGroups$: Observable<ISecurityGroupWithEntityNames[]> = combineLatest([filteredGroups$, websiteAppSearch$]).pipe(
      mapRx(([groups, websiteAppName]: [ISecurityGroupWithEntityNames[], string]): ISecurityGroupWithEntityNames[] => {
        if (SharedCommonUtility.isNullishOrEmpty(websiteAppName?.trim())) {
          return groups;
        }

        return groups.filter((group: ISecurityGroupWithEntityNames): boolean =>
          group.digitalPropertyNames.join().toLowerCase().includes(websiteAppName.trim().toLowerCase()),
        );
      }),
      AngularUtility.shareRef(),
    );

    this.total$ = searchedGroups$.pipe(
      tap((groups: ISecurityGroupWithEntityNames[]): void => {
        if (groups.length !== 0) {
          return;
        }

        if (SharedCommonUtility.notNullishOrEmpty(this.workspaceFilter.value)) {
          const selectedWorkspaceFilter: IWorkspaceOption = this.workspaceFilterOptions.find(
            (option: IWorkspaceOption): boolean => option.workspaceId === this.workspaceFilter.value,
          );
          this.tableConfig.emptyState = {
            iconId: 'magnifying-glass-2',
            titleHtml: this.translateService.instantHtml('there_are_no_websites_apps_in_workspace', [
              SharedTextUtility.escape(selectedWorkspaceFilter.workspaceName),
            ]),
            subtitle: this.translateService.instant('adjust_filter_or_try_different_name'),
          };
        } else {
          this.tableConfig.emptyState = {
            iconId: 'magnifying-glass-2',
            title: this.translateService.instant('there_are_no_websites_apps_with_that_name'),
            subtitle: this.translateService.instant('try_searching_different_name'),
          };
        }
      }),
      mapRx((groups: ISecurityGroupWithEntityNames[]): number => groups.length),
    );

    const orderedGroups$: Observable<ISecurityGroupWithEntityNames[]> = combineLatest([searchedGroups$, this.sortOption$]).pipe(
      mapRx(([groups, sortOption]: [ISecurityGroupWithEntityNames[], SortEvent]): ISecurityGroupWithEntityNames[] =>
        this.sortGroups(groups, sortOption),
      ),
    );

    const paginatedGroups$: Observable<ISecurityGroupWithEntityNames[]> = combineLatest([orderedGroups$, this.page$]).pipe(
      mapRx(([groups, page]: [ISecurityGroupWithEntityNames[], number]): ISecurityGroupWithEntityNames[] => {
        return groups.slice((page - 1) * this.pageSize, page * this.pageSize);
      }),
    );

    const userGroupIds$: Observable<string[]> = this.formGroupIds.valueChanges.pipe(startWith([...this.formGroupIds.value]));

    this.tableData$ = combineLatest([paginatedGroups$, userGroupIds$]).pipe(
      mapRx(([groups, userGroupIds]: [ISecurityGroupWithEntityNames[], string[]]): ITableRow[] =>
        groups.map(
          (group: ISecurityGroupWithEntityNames): ITableRow => ({
            data: {
              [TableColumns.websiteApp]: NgbTableUtilities.textCell({ text: group.digitalPropertyNames.join() }),
              [TableColumns.workspace]: NgbTableUtilities.textCell({ text: group.workspaceNames.join() }),
              [TableColumns.isDigitalPropertyUser]: NgbTableUtilities.checkboxCell({
                checked: userGroupIds.includes(group[$securityGroup._id]),
                styles: {
                  marginTop: '5px',
                },
                attributes: {
                  'aria-label': this.translateService.instant('adds_user_to_digital_property_user_group'),
                },
                listeners: {
                  click: ($event: MouseEvent): void => {
                    $event.stopPropagation();
                    if (($event.target as HTMLInputElement).checked) {
                      this.updateFormGroupIds([...this.formGroupIds.value, group[$securityGroup._id]]);
                    } else {
                      const filterUncheckedGroupIds = (groupId: string): boolean => groupId !== group[$securityGroup._id];
                      this.updateFormGroupIds(this.formGroupIds.value.filter(filterUncheckedGroupIds));
                    }
                  },
                },
              }),
            },
          }),
        ),
      ),
    );
  }

  private sortGroups(groups: ISecurityGroupWithEntityNames[], sortOption: SortEvent): ISecurityGroupWithEntityNames[] {
    if (SharedCommonUtility.isNullish(sortOption) || sortOption.direction === $sortingOrder.all) {
      return groups;
    }

    switch (sortOption.column) {
      case TableColumns.websiteApp:
        return orderBy(
          groups,
          (group: ISecurityGroupWithEntityNames) => group.digitalPropertyNames.join().toLowerCase(),
          sortOption.direction,
        );
      case TableColumns.workspace:
        return orderBy(
          groups,
          (group: ISecurityGroupWithEntityNames) => group.workspaceNames.join().toLowerCase(),
          sortOption.direction,
        );
      // no default
    }

    return groups;
  }

  public onWorkspaceFilterEnter(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public ngOnInit(): void {
    this.buildObservables();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
