import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { map, tap } from 'rxjs/operators';
import { UserAclService } from '../../user-acl.service';
import { AclSecurityAdapter } from '../../acl.service';

@Injectable()
export class PermissionsGuard {
  constructor(private userAclService: UserAclService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userAclService.createCheckAccessForCurrentUser().pipe(
      tap((adapter: AclSecurityAdapter): void => {
        adapter.usePermissions(next.data.requiredPermissions).useOptions({ requireFunctionalActions: false });
      }),
      map((adapter: AclSecurityAdapter): boolean => adapter.check()),
    );
  }
}
